import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { VaultsContext } from '../../context/VaultsContext';

// Styles
import { AlignCenter, Checkbox, LabelTextSmall } from '../../style';

const Index = ({ onClick }: any) => {
	const { t } = useTranslation();
	const { filters } = useContext(VaultsContext);

	return (
		<AlignCenter>
			<Checkbox
				type='checkbox'
				id='zero-balance'
				name='zero-balance'
				onChange={onClick}
				checked={filters?.zero}
			/>
			<LabelTextSmall htmlFor='zero-balance'>
				{t('zaro_balance', 'Hide zero balance')}
			</LabelTextSmall>
		</AlignCenter>
	);
};

export default Index;
