import React from 'react';
import { useTranslation } from 'react-i18next';

// Helpers
import { formatPriceNumber } from '../../../../shared/utils/helpers';

// Components
import ModalC from '../../../../shared/components/Modal';
import Deposit from './Deposit';
import Withdraw from './Withdraw';

// Helpers
import { useHarvest } from '../../../../ethers/hooks';
import { useMobleDetect } from '../../../../shared/utils/hooks/useMobileDetect';

// Interfaces
import { IVault, IVaults } from '../../../../@types/interfaces';

interface IDeposit {
	data: IVaults;
	dData: IVault;
}

const Details = ({ data, dData }: IDeposit) => {
	const { t } = useTranslation();
	const { isMobile } = useMobleDetect();

	const {
		poolWallet,
		wantName,
		want_address,
		pool_id,
		token_price,
		fee_impact,
		fee_withdrawal,
		fee_deposit,
		info_show: infoShow,
		error_show: errorShow,
		protocol,
	} = dData;

	const { harvestHandler } = useHarvest();

	return (
		<>
			<div className='personal'>
				<p className='title'>{t('personal_data', 'Personal Data')}</p>
				<div>
					<div className='wrapper'>
						<div className='item'>
							<p>{t('deposited', 'Deposited')}</p>
							<p className='token'>
								{poolWallet
									? `${formatPriceNumber(poolWallet.deposited)} ${wantName}`
									: '-'}
							</p>
						</div>
						<p className='price'>
							{poolWallet
								? `$ ${formatPriceNumber(
										Number(poolWallet.deposited) * Number(token_price)
								  )}`
								: '-'}
						</p>
					</div>
					<div className='wrapper'>
						<div className='item'>
							<p>{t('earned', 'Earned')}</p>
							<p className='token'>
								{poolWallet
									? `${formatPriceNumber(poolWallet.siEarnings)} SIT`
									: '-'}
							</p>
						</div>
						<p className='price'>
							{poolWallet
								? `$ ${formatPriceNumber(
										Number(poolWallet.siEarnings) * Number(token_price)
								  )}`
								: '-'}
						</p>
					</div>
					<div className='wrapper'>
						<div className='item'>
							<p>{t('balance', 'Balance')}</p>
							<p className='token'>
								{poolWallet
									? `${formatPriceNumber(poolWallet.balance)} ${wantName}`
									: '-'}
							</p>
						</div>
						<p className='price'>
							{poolWallet
								? `$ ${formatPriceNumber(
										Number(poolWallet.balance) * Number(token_price)
								  )}`
								: '-'}
						</p>
					</div>
					{isMobile && (
						<div className='wrapper'>
							<div className='item'>
								<p>{t('protocol', 'Protocol')}</p>
								<p className='token protocol'>{protocol}</p>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='fees'>
				<p className='title'>{t('fees', 'Fees')}</p>
				<div>
					<div className='wrapper'>
						<p>Social Impact ({t('on_profits', 'on profits')})</p>
						<p className='impact'>{fee_impact.toFixed(2)}%</p>
					</div>
					<div className='wrapper'>
						<p>{t('on_withdrawals', 'On Withdrawals')}</p>
						<p className='impact'>{fee_withdrawal.toFixed(2)}%</p>
					</div>
					<div className='wrapper'>
						<p>{t('on_deposits', 'On Deposits')}</p>
						<p className='impact'>{fee_deposit.toFixed(2)}%</p>
					</div>
				</div>
			</div>
			<div className='d-actions'>
				{!poolWallet && (
					<div className='top-wrap'>
						<p>
							{t(
								'connect_wallet',
								'Connect your wallet to enable vault usage.'
							)}
						</p>
					</div>
				)}
				<div className={`btn-wrap ${!poolWallet && 'disabled'}`}>
					<div className={`wrapper ${(infoShow || errorShow) && 'disabled'}`}>
						<p>{t('deposit', 'Deposit')}</p>
						<ModalC
							disabled={
								!poolWallet ||
								(infoShow && true) ||
								parseFloat(formatPriceNumber(poolWallet.balance)) === 0
							}
							btnChild='+'
							modalBody={
								<Deposit
									data={data}
									dData={{ want_address, pool_id, wantName }}
								/>
							}
							className={isMobile ? 'm-modal' : ''}
						/>
					</div>
					<div className='wrapper'>
						<p>{t('withdraw', 'Withdraw')}</p>
						<ModalC
							btnChild='-'
							disabled={
								!poolWallet ||
								parseFloat(formatPriceNumber(poolWallet.deposited)) === 0
							}
							modalBody={
								<Withdraw
									data={data}
									dData={{ want_address, pool_id, wantName }}
								/>
							}
							className={isMobile ? 'm-modal' : ''}
						/>
					</div>
					<button
						disabled={
							(!poolWallet && true) ||
							parseFloat(formatPriceNumber(poolWallet.siEarnings)) === 0
						}
						type='button'
						className='c-button'
						onClick={() => harvestHandler(pool_id)}
					>
						{t('claim', 'Claim')}
					</button>
				</div>
			</div>
		</>
	);
};

export default Details;
