/* eslint no-shadow: 0, no-unused-vars: "off" */

export enum NetworkID {
	// change selected network, after changing contract addresses
	Selected = 3,
	Mainnet = 1,
	Ropsten = 3,
	Rinkeby = 4,
	Goerli = 5,
	Kovan = 42,
	BinanceTestNet = 97,
}

export enum NetworkNames {
	Mainnet = 'Ethereum Mainnet',
	Ropsten = 'Ethereum Ropsten',
	Rinkeby = 'Ethereum Rinkeby',
	Goerli = 'Ethereum Goerli',
	Kovan = 'Ethereum Kovan',
	BinanceTestNet = 'Binance Smart Chain Test Net',
	Localhost = 'localhost',
	Unknown = 'Unknown Network',
}

export enum ContractAddress {
	SIToken = '0xda55e525F565DB626738834C2626F523A32b3c29',
	MasterChef = '0xF21Efb40F25a219BdF5f9dCCD140fDA9abeE5527',
}
