import * as React from 'react';

function SvgTelegram(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={21}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M23.937 1.912l-3.623 17.82c-.273 1.258-.986 1.571-1.999.978l-5.52-4.243-2.66 2.67a1.4 1.4 0 01-.49.419 1.338 1.338 0 01-.618.146l.396-5.864 10.227-9.64c.445-.413-.097-.643-.691-.229L6.312 12.276.867 10.498c-1.184-.385-1.205-1.235.246-1.828L22.406.112c.986-.386 1.85.229 1.524 1.8h.007z'
				fill='#9493A1'
			/>
		</svg>
	);
}

export default SvgTelegram;
