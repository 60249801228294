import * as React from 'react';

function SvgCtaSmallArrow(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={16}
			height={11}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				stroke='#E8B400'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M1 5.5h13M10.688 9.5l4-4-4-4'
			/>
		</svg>
	);
}

export default SvgCtaSmallArrow;
