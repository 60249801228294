import styled from 'styled-components';
import { Col } from '../../../shared/styles/GeneralStyles';
import { font, device } from '../../../shared/styles/variables';

export const Card = styled(Col)`
	flex: 0 0 50%;
	width: 50%;
	display: flex;

	&:nth-child(even) {
		margin: 0;
	}

	h2 {
		margin-bottom: 20px;
	}

	@media ${device.tabletL} {
		flex: 0 0 100%;
		width: 100%;
		margin-bottom: 16px;
	}
`;

export const Wrap = styled.div`
	flex: 1;
	padding: 25px 36px;
	border-radius: 10px;
	background-color: var(--white);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

	@media ${device.mobileL} {
		padding: 32px 20px 24px 20px;
	}
`;

export const Container = styled.div`
	display: flex;

	@media ${device.mobileL} {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

// Statistics
export const ChartWrap = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 225px;
	margin-top: -20px;

	svg text {
		pointer-events: none;
	}

	.logo {
		position: absolute;
		left: 50%;
		top: 38%;
		transform: translateX(-50%);
		/* opacity: 0.3; */
	}

	a {
		color: var(--primary2);
		font-weight: 600;
	}

	@media ${device.tabletL} {
		max-width: 50%;
	}

	@media ${device.mobileL} {
		max-width: 270px;
		margin-top: 20px;

		.chart {
			margin-top: 50px;
			margin-bottom: 30px;
		}
	}
`;

// Wallet
export const Items = styled.div`
	flex: 1;
`;

export const Item = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
`;

export const NoWalletWrap = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	max-width: 377px;
	margin: 0 auto;
	text-align: center;

	p {
		color: var(--ash);
	}
`;

// Vaults
export const BeFlex = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 6px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;

export const SLeft = styled.div`
	width: 70%;

	@media ${device.tabletL} {
		width: 50%;
	}

	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const BWrap = styled(Wrap)`
	margin: 14px 0;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
`;

export const MWrap = styled(Wrap)`
	margin: 14px 0 0 0;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
`;

export const Vaults = styled(Col)`
	h2 {
		margin-bottom: 20px;
	}
`;

// Social Impact Vaults
export const VContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.top {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 50px;

		h2 {
			margin: 0;
		}
	}

	.link,
	.link-sec {
		width: 170px;
		background-color: transparent;
		border: 2px solid var(--secondary1);
		color: var(--darkDirtGray);
		padding: 5px 15px;
	}

	.link-sec {
		display: none;
	}

	@media ${device.mobileL} {
		justify-content: center;

		.top {
			margin-bottom: 30px;

			h2 {
				line-height: 29px;
			}
		}

		.link {
			display: none;
		}

		.link-sec {
			display: block;
			margin-top: 35px;
			align-self: center;
		}
	}

	a {
		background-color: var(--secondary1);
		display: flex;
		justify-content: center;
		align-items: center;
		align-self: center;
		border-radius: 13px;
	}
`;

export const CardWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 33.3%;
	margin-bottom: 25px;

	&:nth-of-type(2) {
		padding-right: 20px;
	}

	&:nth-of-type(3) {
		padding: 0 20px;
	}

	&:nth-of-type(4) {
		padding-left: 20px;
	}

	a {
		padding: 9px 17px;
		background-color: var(--white);
		color: var(--darkDirtGray);
		border: 2px solid var(--secondary1);

		.small-cta {
			display: none;
		}

		&.a-sv span {
			margin-left: 3px;
			text-transform: uppercase;
		}
	}

	@media ${device.tabletL} {
		width: 100%;
		margin-bottom: 48px;

		&:nth-of-type(2),
		&:nth-of-type(3),
		&:nth-of-type(4) {
			padding-right: 0;
			padding-left: 0;
		}

		&:last-of-type {
			margin-bottom: 7px;
		}
	}

	@media ${device.mobileL} {
		border: 1px solid var(--darkMuteGray);
		box-sizing: border-box;
		border-radius: 10px;
		padding: 18px 12px !important;
		margin-bottom: 16px;

		a {
			padding: 0;
			border: 0;
			align-self: flex-end;

			.small-cta {
				display: block;
				margin-left: 5px;
			}
		}
	}
`;

export const Title = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.title {
		display: flex;
		align-items: center;

		svg {
			margin-right: 5px;
		}

		p {
			font-size: 18px;
			font-weight: 600;
			color: var(--text);
			text-transform: uppercase;
		}
	}

	p {
		font-size: 12px;
		color: var(--coal);
	}

	.protocol {
		display: flex;
		align-items: center;

		img {
			margin-right: 5px;
		}

		p {
			color: var(--text);
		}
	}

	@media ${device.mobileS} {
		.title {
			p {
				font-size: 14px;
			}
		}
	}
`;

export const Numbers = styled.p`
	color: var(--coal);
	width: 90px;

	&.sv {
		width: auto;
	}

	&.bold {
		font-weight: bold;
		color: var(--text);
	}
`;

export const SInfo = styled.div`
	margin-bottom: 4px;

	@media ${device.mobileL} {
		margin-bottom: 0;
	}
`;

// Best funds
export const Sunny = styled(Col)`
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		a {
			padding: 5px 42px;
			color: var(--darkDirtGray);
			border: 2px solid var(--secondary1);
			border-radius: 10px;
		}
	}

	.text {
		margin-bottom: 37px;
		max-width: 800px;
		color: var(--coal);
	}

	.info-wrap {
		display: flex;
		padding-bottom: 24px;

		.image {
			width: 55%;

			.wrap {
				display: flex;
				position: relative;
				width: fit-content;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			p {
				position: absolute;
				width: 100%;
				text-align: center;
				bottom: 50px;
				font-size: 22px;
				font-weight: 600;
				color: #fefefe;
				z-index: 1;
			}

			.wrap::after {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: linear-gradient(
					360deg,
					#000000 0%,
					#000000 0.01%,
					rgba(0, 0, 0, 0.78) 33.46%,
					rgba(0, 0, 0, 0) 59.5%
				);
				opacity: 0.5;
			}
		}

		.info {
			width: 45%;
			margin-top: 10px;
			background-color: transparent;

			.item {
				display: flex;
				justify-content: space-between;
				margin-bottom: 6px;

				p:nth-of-type(2) {
					${font.boldNotoSans};
					color: var(--text);
					width: 90px;
				}

				p.size,
				p.num {
					${font.regularNotoSans};
					color: var(--coal);
				}

				p.m-super {
					color: var(--primary2);
					font-weight: 600;
				}

				.size {
					width: 72px;
				}
			}

			.calculator {
				margin-top: 60px;
				display: flex;

				button {
					color: var(--primary2);
					font-size: 14px;
					${font.medium};
				}

				svg {
					margin-right: 10px;
				}
			}
		}
	}

	.bot {
		display: flex;
		justify-content: center;

		button {
			width: 150px;

			&:nth-of-type(1) {
				margin-right: 13px;
			}
		}
	}
	@media (max-width: 1290px) {
		.info-wrap {
			.image {
				.wrap {
					max-width: 420px;
					width: 100%;
				}
			}
		}
	}

	@media ${device.tabletL} {
		.info-wrap .image {
			.wrap {
				width: 100%;
			}

			p {
				font-size: 18px;
				bottom: 40px;
			}
		}
	}

	@media ${device.tablet} {
		.info-wrap .image {
			.wrap {
				max-width: 320px;
			}
		}
	}

	@media ${device.mobileL} {
		.text {
			margin-bottom: 30px;
		}

		.title a {
			padding: 3px 42px;
		}

		.info-wrap {
			flex-wrap: wrap;
			padding-bottom: 0;

			.image {
				width: 100%;
				order: 1;

				.wrap {
					max-width: 100%;
				}
			}

			.info {
				width: 100%;
				margin-bottom: 24px;

				.calculator {
					margin-top: 24px;
				}
			}
		}

		.bot {
			justify-content: space-between;

			button {
				min-width: 38vw;
				width: 38vw;
			}
		}
	}

	@media ${device.mobileS} {
		.bot {
			flex-direction: column;

			button {
				min-width: 41vw;

				&:nth-of-type(1) {
					margin-right: 0;
					margin-bottom: 16px;
				}
			}
		}
	}
`;

// Governance
export const Gov = styled(Col)`
	.wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		h2 {
			margin-bottom: 23px;
		}

		.left {
			width: 60%;

			p {
				max-width: 454px;
				color: var(--coal);
			}
		}

		.right {
			margin-top: 60px;
			width: 40%;
		}

		@media ${device.tabletL} {
			.left,
			.right {
				width: 50%;
			}

			.left {
				padding-right: 32px;
			}
		}

		@media ${device.mobileL} {
			.left,
			.right {
				width: 100%;
			}

			.left {
				padding-right: 0;
			}

			.right {
				margin-top: 24px;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 18px 12px;
			}
		}
	}
`;
