import React from 'react';
import { useTranslation } from 'react-i18next';

// Helpers
import { formatPriceNumber } from '../../../shared/utils/helpers';

// Components
import Divider from '../../../shared/components/Divider';
import Link from '../../../shared/components/Link';
import Button from '../../../shared/components/Button';
import BestPoolImg from '../../../assets/images/best-pool.jpg';

// Icons
import { ApiCalculatorIcon } from '../../../shared/components/Icons';

// Interfaces
import { ISiPools } from '../../../@types/interfaces';

interface ISunny {
	data: ISiPools;
}

const SunnyPool = ({ data }: ISunny) => {
	const { t } = useTranslation();

	const pool = data[0];
	const {
		general: { co2_reduced, energy_generated, pool_size, apy },
		projects,
	} = pool;

	return (
		<>
			<div className='title'>
				<h2>{t('sunny_pool', 'Sunny Pool')}</h2>
				<Link className='disabled' to='/'>
					{t('more_info', 'More Info')}
				</Link>
			</div>
			<p className='text'>
				{t(
					'sunny_text',
					'With Sunny Pool, you support the reduction of CO2 emissions in emerging countries. Sunny Pool provides soft loans for solar projects that produce clean energy and reduce CO2. All generated impact is measured, tracked, and stored in a blockchain.'
				)}
			</p>

			<div className='info-wrap'>
				<div className='image'>
					<div className='wrap'>
						<img src={BestPoolImg} alt='Best Performing Impact Pool' />
						<p>
							{t('best_performing_impact_pool', 'Best Performing Impact Pool')}
						</p>
					</div>
				</div>
				<div className='info'>
					<div className='item'>
						<p>{t('co2_reduced', 'CO₂ Reduced')}</p>
						<p>{(co2_reduced / 1000).toFixed(2)} kg</p>
					</div>
					<div className='item'>
						<p>{t('energy_generated', 'Energy Generated')}</p>
						<p>{(energy_generated / 1000).toFixed(2)} kWh</p>
					</div>
					<div className='item'>
						<p>{t('apy', 'APY')}</p>
						<p>{apy}%</p>
					</div>
					<div className='item'>
						<p>{t('pool_size', 'Pool Size')}</p>
						<p className='size'>${formatPriceNumber(pool_size)}</p>
					</div>
					<div className='item'>
						<p>{t('number_of_projects', 'Number of Projects')}</p>
						<p className='num'>{projects.length}</p>
					</div>
					<div className='item'>
						<p>{t('main_supervisor', 'Main Supervisor')}</p>
						<p className='m-super'>UNDP</p>
					</div>
					<div className='calculator disabled'>
						<ApiCalculatorIcon />
						<button type='button' disabled>
							{t('calculate_your_impact', 'Calculate your impact')}
						</button>
					</div>
				</div>
			</div>
			<Divider />
			<div className='bot'>
				<Button disabled>{t('deposit', 'Deposit')}</Button>
				<Button disabled secondary>
					{t('all_projects', 'All Projects')}
				</Button>
			</div>
		</>
	);
};

export default SunnyPool;
