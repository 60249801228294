import styled from 'styled-components';
import { Col } from '../../../shared/styles/GeneralStyles';
import { device, font } from '../../../shared/styles/variables';

// Assets
import Checked from '../../../assets/icons/checked.svg';
import BottomArrow from '../../../assets/icons/arrow-bottom.svg';
import SearchIcon from '../../../assets/icons/search.svg';

export const VCol = styled(Col)`
	padding: 24px;
	display: flex;
	flex-direction: column;
	background-color: var(--white);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
	border-radius: 10px;

	table {
		border-spacing: 0;
		border-bottom: 1px solid var(--darkMuteGray);

		tr {
			&:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		thead .t-header:first-of-type {
			display: none;
		}

		th,
		td {
			margin: 0;
			padding: 0.5rem;
			border-bottom: 1px solid var(--darkMuteGray);

			&:last-child {
				border-right: 0;
			}
		}

		th {
			font-size: 14px;
			line-height: 20px;
			color: var(--darkDirtGray);
			${font.regularPoppins};
		}

		thead {
			background-color: var(--lightMuteGray);

			th {
				border-bottom: none;

				&:first-child {
					border-radius: 4px 0 0 4px;
				}

				&:last-child {
					border-radius: 0 4px 4px 0;
				}
			}
		}
	}

	.wrapper {
		/* text-align: center; */
	}

	.c-border {
		border-left: 6px solid var(--secondary1);
	}
	.r-border {
		border-left: 6px solid transparent;
	}

	.h-spacer {
		width: 24px;
		margin-right: 10px;
	}

	.spacer {
		width: 36px;
		margin-right: 14px;
	}

	.load-more {
		margin-top: 30px;
	}

	@media ${device.tabletL} {
		padding: 0;
		box-shadow: none;
		border-radius: 0;
	}
`;

export const TopWrap = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;

	@media ${device.tabletL} {
		position: absolute;
		left: 0;
		top: 48px;
		width: 100%;
		z-index: 1;
		padding: 8px 20px 16px 20px;
		background-color: var(--white);
		flex-direction: column;
		align-items: flex-start;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);

		.clear {
			width: 100%;
			text-align: right;
			color: #693e80;
			font-weight: 600;
			font-size: 14px;
		}

		.cat-filter {
			margin-top: 12px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			button {
				margin-bottom: 12px;
				position: relative;
				padding-left: 36px;

				&:last-of-type {
					margin-bottom: 0;
				}

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 22px;
					height: 22px;
					border-radius: 100%;
					border: 2px solid var(--darkMuteGray);
					box-sizing: border-box;
				}

				&.active:after {
					content: '';
					position: absolute;
					left: 4px;
					top: 50%;
					transform: translateY(-50%);
					width: 14px;
					height: 14px;
					border-radius: 100%;
					background-color: var(--primary1);
					box-sizing: border-box;
				}
			}
		}
	}

	@media ${device.mobileL} {
		padding-bottom: 24px;
	}
`;

export const TLeft = styled.div`
	display: flex;

	div,
	span {
		margin-right: 35px;
	}

	span {
		&:last-of-type {
			margin-right: 0;
		}
	}

	@media ${device.tabletL} {
		flex-wrap: wrap;
		justify-content: space-between;
		/* order: 1; */
		width: 300px;
		margin-top: 12px;

		div,
		span {
			width: 300px;
			max-width: 300px;
			margin-right: 0;
			margin-bottom: 12px;
		}

		span {
			width: 120px;

			&:first-of-type {
				width: 158px;
			}
		}
	}

	@media ${device.mobileL} {
		width: 100%;
		margin-top: 0;

		div {
			width: 100%;
			max-width: 100%;
		}
	}
`;

export const MiddleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;

	.cat-filter {
		button {
			padding: 8px 17px;
			${font.regularPoppins};
			min-width: 100px;
			border: 2px solid var(--lightMuteGray);
			border-left: 0;
			text-transform: uppercase;

			&:first-of-type {
				border-left: 2px solid var(--lightMuteGray);
			}

			&.active {
				${font.semibold}
				border-bottom: 2px solid var(--primary1)
			}

			&:hover {
				opacity: 1;
			}
		}
	}

	@media ${device.tabletL} {
		align-items: center;
		margin-bottom: 0;
	}

	@media ${device.mobileL} {
		background-color: var(--primary5);
	}
`;

export const BannerWrap = styled.div`
	display: flex;
	align-items: center;
	padding: 12px;
	background-color: var(--lightOrange);
	border: 1px solid var(--orange);
	border-radius: 4px;

	p {
		font-size: 14px;
		margin-right: 12px;
		color: var(--lightCoal);
	}

	img {
		margin-right: 9px;
		height: 34px;
	}

	.img-link {
		display: flex;
	}

	.link {
		padding: 3px 13px;
		border: 2px solid var(--secondary1);
		border-radius: 10px;
		color: var(--darkDirtGray);
		background-color: var(--white);
	}

	@media (max-width: 1411px) {
		p {
			display: none;
		}
	}

	@media ${device.tabletL} {
		background-color: transparent;
		border: 0;
		padding: 7px 12px 7px 7px;
	}

	@media ${device.mobileL} {
		p,
		a {
			display: none;
		}
	}
`;

export const MFilter = styled.div`
	display: flex;
	align-items: center;

	p {
		font-size: 14px;
		line-height: 21px;
		color: var(--text);
		margin-right: 5px;
		${font.regularNotoSans};
	}

	.icon-wrap {
		display: flex;
		align-items: center;
		padding: 5px 11px;
		border: 2px solid var(--secondary1);
		border-radius: 10px;
		margin-right: 12px;

		&.true {
			background-color: var(--primary1);
			border-color: var(--primary1);

			svg path {
				fill: var(--white);

				@media ${device.tabletL} {
					fill: var(--primary1);
				}
			}
		}

		@media ${device.tabletL} {
			svg path {
				fill: var(--primary1);
			}

			&.true {
				svg path {
					fill: var(--white);
				}
			}
		}
	}
`;

export const BotWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 10px;

	/* input {
		appearance: auto;
	} */
`;

export const LabelTextSmall = styled.label`
	font-size: 14px;
	line-height: 20px;
	color: var(--darkDirtGray);
	${font.regularPoppins};
`;

export const AlignCenter = styled.div`
	display: flex;
	align-items: center;
`;

export const Checkbox = styled.input`
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	width: 22px;
	height: 22px;
	border-radius: 5px;
	border: 2px solid var(--darkMuteGray);
	margin-right: 12px;
	cursor: pointer;
	position: relative;

	&:checked {
		background-color: var(--primary1);

		&:before {
			content: '';
			width: 12px;
			height: 9px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: url(${Checked}) no-repeat center center;
		}
	}
`;

export const SelectSpan = styled.span`
	min-width: 120px;
	position: relative;

	&:before {
		content: '';
		width: 12px;
		height: 8px;
		position: absolute;
		top: 15px;
		right: 12px;
		background: url(${BottomArrow}) no-repeat center center;
		cursor: pointer;
	}
`;

export const SelectOption = styled.select`
	width: 100%;
	padding: 6px 25px 6px 14px;
	background-color: transparent;
	border: 2px solid var(--darkMuteGray);
	border-radius: 10px;
	${font.regularPoppins};
	font-size: 14px;
	line-height: 20px;
	color: var(--text);
`;

export const SearchSpan = styled.span`
	max-width: 165px;
	padding: 3px 14px;
	background-color: var(--white);
	border: 2px solid var(--darkMuteGray);
	border-radius: 10px;
	position: relative;

	&:before {
		content: '';
		width: 15px;
		height: 15px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
		background: url(${SearchIcon}) no-repeat center center;
	}

	@media ${device.tabletL} {
		max-width: 300px;
		width: 100%;
	}

	@media ${device.mobileL} {
		max-width: 100%;
	}
`;

export const SearchInput = styled.input`
	display: inline-block;
	width: 85%;
	${font.regularPoppins};
	font-size: 14px;
	line-height: 20px;
	color: var(--text);
	background-color: transparent;

	&::placeholder {
		color: var(--dirtGray);
	}
`;

export const DHeader = styled.div`
	display: flex;
	padding: 14px 0;
	justify-content: space-between;
	background-color: var(--primary5);
	border-radius: 4px;

	&:first-of-type {
		display: none;
	}

	.row {
		padding: 0 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;

		span {
			margin-left: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 10px;

			.reverse {
				transform: rotate(180deg);
			}
		}
	}

	.row:nth-of-type(1) {
		width: 25%;
		margin-right: 60px;

		@media (max-width: 1290px) {
			width: 28%;
			margin-right: 30px;
		}
	}

	.row:nth-of-type(2) {
		justify-content: flex-start;
		width: 19%;
	}

	.row:nth-of-type(3) {
		justify-content: flex-start;
		padding-left: 25px;
		width: 13%;
	}

	.row:nth-of-type(4) {
		justify-content: flex-start;
		width: 15%;
	}

	.row:nth-of-type(5) {
		width: 16%;
	}

	.row:nth-of-type(6) {
		width: 10%;

		span {
			display: none;
		}
	}

	.row:nth-of-type(7) {
		width: 5%;
		padding-right: 20px;
	}

	@media ${device.tabletL} {
		.row:nth-of-type(1) {
			width: 32%;
		}

		.row:nth-of-type(2) {
			width: 23%;
		}

		.row:nth-of-type(3) {
			width: 17%;
		}

		.row:nth-of-type(4) {
			width: 12%;
		}

		.row:nth-of-type(5) {
			justify-content: center;
			width: 11%;

			span {
				display: none;
			}
		}

		.row:nth-of-type(6) {
			width: 5%;
		}
	}

	@media ${device.mobileL} {
		&:nth-of-type(2) {
			display: none;
		}
	}
`;

export const TableWrap = styled.div`
	.row {
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--darkMuteGray);

		&.row-false {
			display: none;
		}

		.cell {
			padding: 10px 10px;
			display: flex;
			height: 100%;
		}

		.cell:nth-of-type(1) {
			width: 25%;
			margin-right: 60px;

			@media (max-width: 1290px) {
				width: 28%;
				margin-right: 30px;
			}
		}

		.cell:nth-of-type(2) {
			width: 19%;
		}

		.cell:nth-of-type(3) {
			/* justify-content: center; */
			width: 13%;
		}

		.cell:nth-of-type(4) {
			width: 15%;
		}

		.cell:nth-of-type(5) {
			width: 16%;
			justify-content: center;
		}

		.cell:nth-of-type(6) {
			width: 10%;
			justify-content: center;
		}

		.cell:nth-of-type(7) {
			width: 5%;
			text-align: center;
		}

		.assets {
			display: flex;
			align-items: center;
			text-transform: uppercase;

			p,
			a {
				text-transform: capitalize;
			}

			a {
				text-decoration: underline;
				color: var(--primary2);
			}
		}

		.apy {
			margin-right: 8px;
		}

		.apy,
		.deposited,
		.tvl {
			p {
				color: var(--text);
				${font.regularNotoSans}
				font-size:13px;

				&:first-of-type {
					${font.boldNotoSans}
					text-transform:uppercase;
				}
			}
		}

		.dis,
		.error {
			margin-top: 3px;
			display: flex;

			p {
				font-size: 14px;
			}
		}

		.dis p {
			color: var(--orange);
		}

		.error p {
			color: #ff331f;
		}

		@media ${device.tabletL} {
			.cell {
				padding: 20px 12px;

				.assets {
					.reveal {
						left: 0 !important;
						transform: unset !important;
						bottom: -130% !important;
					}
				}
			}

			.cell:nth-of-type(1) {
				width: 32%;
			}

			.cell:nth-of-type(2) {
				width: 23%;
			}

			.cell:nth-of-type(3) {
				width: 17%;
			}

			.cell:nth-of-type(4) {
				width: 12%;
			}

			.cell:nth-of-type(5) {
				width: 11%;
			}

			.cell:nth-of-type(6) {
				width: 5%;
			}
		}

		@media ${device.mobileL} {
			flex-wrap: wrap;

			.cell {
				padding: 10px 12px;
			}

			.cell:nth-of-type(1) {
				width: 100%;
				margin-right: 0;

				&.c-border + div + div {
					border-left: 6px solid var(--secondary1);
				}
			}

			.cell:nth-of-type(2),
			.cell:nth-of-type(6) {
				display: none;
			}

			.cell:nth-of-type(3) {
				width: 55%;
			}

			.cell:nth-of-type(4) {
				width: 32%;
			}

			.cell:nth-of-type(5) {
				flex: 1;
			}

			.cell {
				.assets {
					width: 100%;

					.a-wrap {
						display: flex;
						flex: 1;
						align-items: center;
						justify-content: space-between;

						.reveal {
							left: -150% !important;
							transform: unset !important;
							bottom: -130% !important;
						}
					}
				}

				.wrapper {
					width: 100%;
					display: flex;
					justify-content: center;

					.apy {
						display: flex;
						width: 100%;

						.title {
							font-size: 14px;
							line-height: 20px;
							color: var(--superLightDirt);
							${font.regularPoppins};
							text-align: right;
						}

						.a-value,
						.d-value {
							font-size: 16px;
							line-height: 22px;
						}

						.a-value {
							font-weight: 700;
						}

						div {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
						}

						div:nth-of-type(1),
						div:nth-of-type(3) {
							width: 40%;
						}

						div:nth-of-type(2) {
							width: 20%;
						}
					}

					.tvl {
						p {
							font-size: 16px;
							font-weight: 700;
						}

						.title {
							font-size: 14px;
							line-height: 20px;
							color: var(--superLightDirt);
							${font.regularPoppins};
							text-align: right;
						}
					}
				}
			}
		}
	}
`;

export const DetailsWrap = styled.div`
	width: 100%;
	display: none;
	padding: 23px 40px 23px 40px;
	background-color: var(--primary5);

	p {
		margin-bottom: 8px;
	}

	&.open {
		display: flex;
	}

	.personal {
		width: 35%;
	}

	.fees {
		width: 27%;
	}

	.personal,
	.fees {
		padding-right: 30px;
		color: var(--lightDirtGray);
		font-size: 14px;

		.title {
			color: var(--darkDirtGray);
		}

		p {
			font: inherit;
			color: inherit;

			&.impact {
				${font.regularNotoSans};
			}
		}

		.wrapper {
			display: flex;
			justify-content: space-between;

			.item {
				display: flex;

				p {
					margin-right: 12px;
					text-transform: uppercase;

					&:first-of-type {
						min-width: 75px;
						text-transform: capitalize;
					}
				}

				.token {
					${font.boldNotoSans}
					color:var(--dirtGray)
				}
			}
			.price {
				${font.regularNotoSans}
				color:var(--darkDirtGray);
				width: 20%;
			}

			.token,
			.price,
			.impact {
				font-size: 13px;
				color: var(--darkDirtGray);
			}
		}
	}

	.d-actions {
		width: 38%;
		padding-left: 5px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		font-size: 14px;

		p {
			font: inherit;
		}

		.top-wrap {
			width: 100%;

			p {
				font-size: 13px;
				text-align: center;
				padding: 8px 47px;
				border: 1px solid var(--danger);
				color: var(--coal);
				border-radius: 10px;
			}
		}

		.btn-wrap {
			display: flex;
			align-items: center;
			justify-content: center;

			&.disabled {
				opacity: 0.5;
			}

			.wrapper {
				display: flex;
				margin-right: 20px;
				align-items: center;

				p {
					margin-bottom: 0;
					margin-right: 8px;
					color: var(--lightDirtGray);
				}

				&.disabled {
					opacity: 0.5;
				}
			}

			button {
				font: inherit;
				width: 38px;
				height: 38px;
				border-radius: 10px;
				background-color: var(--orange);
				color: var(--white);

				&.c-button {
					background-color: var(--primary1);
					color: var(--white);
					width: 103px;
					font-weight: 500;
				}
			}
		}
	}

	@media ${device.tabletL} {
		flex-wrap: wrap;

		.personal,
		.fees {
			width: 50%;
		}

		.d-actions {
			margin-top: 27px;
			width: 100%;

			.top-wrap {
				width: auto;
				margin-bottom: 10px;
			}
		}
	}

	@media ${device.mobileL} {
		padding: 23px 32px 23px 32px;

		.personal,
		.fees {
			width: 100%;
			padding-right: 0;
			border-bottom: 1px solid var(--darkMuteGray);
			padding-bottom: 8px;
			margin-bottom: 16px;
		}

		.personal {
			.title {
				display: none;
			}

			.wrapper {
				.item {
					flex-direction: column;

					p:first-of-type {
						margin-bottom: 2px;
					}

					.token {
						color: var(--text);
						text-transform: uppercase;
						margin-bottom: 10px;

						&.protocol {
							font-weight: 400;
							text-transform: unset;
						}
					}
				}

				.price {
					align-self: flex-end;
				}
			}
		}

		.d-actions {
			margin-top: 0;
			padding-left: 0;

			.btn-wrap {
				flex-wrap: wrap;

				.wrapper {
					width: 50%;
					margin-right: 0;
					margin-bottom: 20px;

					&:nth-of-type(2) {
						justify-content: flex-end;
					}
				}

				button.c-button {
					width: 200px;
				}
			}

			.top-wrap {
				p {
					padding: 8px 20px;
				}
			}
		}
	}

	@media ${device.mobileS} {
		.d-actions .btn-wrap {
			button.c-button {
				width: 100%;
			}
		}
	}
`;

export const ApyWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	p.title {
		font-size: 22px;
		${font.semibold};
		color: var(--coal);
		margin-bottom: 25px;
	}

	.item-wrap {
		display: flex;
		margin-bottom: 36px;

		.item {
			&:first-of-type {
				.header {
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}
			}

			&:last-of-type {
				.header {
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
			}

			p {
				padding: 15px 22px;
				margin: 0;
				color: var(--coal);
				${font.regularNotoSans}
			}

			.header {
				font-size: 14px;
				background-color: var(--primary5);
				color: var(--darkDirtGray);
				${font.regularPoppins}
			}
		}
	}

	p.text {
		max-width: 405px;
		text-align: center;
		color: var(--ash);
		margin-bottom: 50px;
	}

	@media ${device.mobileL} {
		.item-wrap {
			width: 100%;

			.item {
				.header {
					min-height: 72px;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
				}

				p {
					text-align: center;
				}
			}
		}
	}
`;

export const HeartWrap = styled.div`
	background-color: var(--white);
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	color: var(--text);
	padding: 30px 20px;
	z-index: 1;
	width: 270px;
	font-size: 12px;
	text-align: center;
`;
