import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Helpers
import { formatPriceNumber } from '../../../shared/utils/helpers';

// Components
import Divider from '../../../shared/components/Divider';

// Icons
import {
	CtaSmallArrow,
	LpGreyIcon,
	VGreyIcon,
} from '../../../shared/components/Icons';
import PancakeImg from '../../../assets/images/pancake-logo.png';
import SiImg from '../../../assets/images/si-network-logo.png';

// Styles
import { BeFlex, VContainer, CardWrap, Title, SInfo, Numbers } from './style';

// Interfaces
import { IVaults } from '../../../@types/interfaces';

interface ISVaults {
	data: IVaults;
}

interface IInfo {
	data: IVaults;
	index: number;
}

const Card = ({ data, index }: IInfo) => {
	const { t } = useTranslation();
	const { vaults: info } = data;

	return (
		<CardWrap>
			{info[index] && (
				<>
					<Title>
						<div className='title'>
							{!info[index].single_asset ? <LpGreyIcon /> : <VGreyIcon />}
							<p>{info[index].wantName}</p>
						</div>
						<div className='protocol'>
							{(() => {
								if (info[index].protocol === 'PancakeSwap') {
									return <img src={PancakeImg} alt='' />;
								}

								if (info[index].protocol === 'Social Impact Network') {
									return <img src={SiImg} alt='' />;
								}
								return null;
							})()}
							<p>{info[index].protocol}</p>
						</div>
					</Title>
					<Divider small />
					<SInfo>
						<BeFlex>
							<p>{t('apy', 'APY')}</p>
							<Numbers className='sv'>
								{info[index].apy_total.toFixed(2)}%
							</Numbers>
						</BeFlex>
						<BeFlex>
							<p>{t('tvl', 'TVL')}</p>
							<Numbers className='sv'>
								${formatPriceNumber(info[index].tvl)}
							</Numbers>
						</BeFlex>
						<BeFlex>
							<p>{t('supporting_sdg', 'Supporting SDG ')}</p>
							<Numbers className='sv'>
								{t('clean_energy', 'Clean Energy')}
							</Numbers>
						</BeFlex>
					</SInfo>
					<Divider small />
					<Link
						to='/'
						className='a-sv disabled'
						// to={`/deposit/${data.pools[index].wantAddress}`}
					>
						{t('stake', 'Stake')} <span>{info[index].wantName}</span>
						<CtaSmallArrow className='small-cta' />
					</Link>
				</>
			)}
		</CardWrap>
	);
};

const SVaults = ({ data }: ISVaults) => {
	const { t } = useTranslation();

	return (
		<VContainer>
			<BeFlex>
				<div className='top'>
					<h2>{t('siv', 'Social Impact Vaults')}</h2>
					<Link className='link' to='/social-impact-vaults/'>
						{t('stake_other_assets', 'Stake Other Assets')}
					</Link>
				</div>
			</BeFlex>
			<Card data={data} index={0} />
			<Card data={data} index={1} />
			<Card data={data} index={3} />
			<Link className='link-sec' to='/social-impact-vaults/'>
				{t('stake_other_assets', 'Stake Other Assets')}
			</Link>
		</VContainer>
	);
};

export default SVaults;
