export const fetchMainData = async () => {
	const res = await fetch('https://social-impact-api.herokuapp.com/');
	return res.json();
};

export const fetchTokenPrices = async () => {
	const res = await fetch(
		'https://social-impact-api.herokuapp.com/token_prices/'
	);
	return res.json();
};
