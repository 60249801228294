import * as React from 'react';

function SvgGovernanceIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={18}
			height={18}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M16.811 5.688a1.056 1.056 0 00-1.412-.072c-.06.048-.013.005-.925.917L16.841 8.9l.85-.84a1.056 1.056 0 000-1.492l-.88-.88zM9.621 11.431L8.23 14.414a.527.527 0 00.701.701l2.983-1.392c.118-.055-.24.28 4.177-4.082L13.73 7.279c-4.38 4.379-4.052 4.032-4.108 4.152z'
				fill='#9493A1'
			/>
			<path
				d='M12.802 14.37c-.13.128-.278.232-.443.309L9.376 16.07a1.58 1.58 0 01-1.788-.315 1.587 1.587 0 01-.315-1.788l1.392-2.983c.079-.168.185-.319.315-.45l4.485-4.484V1.582C13.465.71 12.755 0 11.883 0h-10.3C.71 0 0 .71 0 1.582v14.836C0 17.29.71 18 1.582 18h10.301c.872 0 1.582-.71 1.582-1.582v-2.702l-.663.655zM2.812 3.41h7.876a.527.527 0 010 1.055H2.813a.527.527 0 010-1.055zm0 2.813H9a.527.527 0 010 1.054H2.813a.527.527 0 010-1.054zm-.527 3.34c0-.292.237-.528.528-.528h4.5a.527.527 0 010 1.055h-4.5a.527.527 0 01-.528-.527z'
				fill='#9493A1'
			/>
		</svg>
	);
}

export default SvgGovernanceIcon;
