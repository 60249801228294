import * as React from 'react';

function SvgDInfoIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle cx={12} cy={12} r={11} stroke='#430E60' strokeWidth={2} />
			<path
				d='M12.011 5.08c.374 0 .697.09.97.272.271.17.407.493.407.969 0 .465-.136.788-.408.969a1.706 1.706 0 01-.969.272c-.385 0-.714-.09-.986-.272-.26-.181-.39-.504-.39-.969 0-.476.13-.799.39-.969.272-.181.6-.272.986-.272zm1.258 3.638V18h-2.533V8.718h2.533z'
				fill='#430E60'
			/>
		</svg>
	);
}

export default SvgDInfoIcon;
