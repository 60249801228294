import styled from 'styled-components';

export const Wrap = styled.div`
	display: flex;
	position: relative;
	cursor: pointer;

	.pointer,
	.pointer-bot {
		width: 20px;
		height: 20px;
		border-radius: 5px;
		transform: rotate(45deg);
		display: none;
		position: absolute;
		top: 25px;
		right: 0;
		background-color: var(--white);
	}

	.pointer-bot {
		left: 50%;
		transform: translateX(-70%) translateY(-221%) rotate(45deg);
		background-color: var(--white);
		/* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); */
		z-index: 1;
	}

	.reveal {
		display: none;
		position: absolute;
		z-index: 1;
		border: 1px solid var(--border);
		border-radius: 4px;
		overflow: hidden;
	}

	&:hover {
		.reveal,
		.pointer,
		.pointer-bot {
			display: flex;
		}
	}
`;
