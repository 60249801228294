import * as React from 'react';

function SvgDLinkedin(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={20}
			height={20}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M4.494 20H.353V6.648h4.141V20zM2.421 4.825A2.408 2.408 0 01.188 3.346 2.415 2.415 0 011.928.05a2.407 2.407 0 012.48 1.014c.266.396.41.862.411 1.339a2.423 2.423 0 01-2.398 2.423zM19.994 20H15.86v-6.5c0-1.549-.031-3.536-2.156-3.536-2.156 0-2.483 1.684-2.483 3.425V20H7.088V6.648h3.972v1.821h.06A4.353 4.353 0 0115.04 6.31c4.19 0 4.961 2.764 4.961 6.353V20h-.006z'
				fill='#474954'
			/>
		</svg>
	);
}

export default SvgDLinkedin;
