import * as React from 'react';

function SvgListDotIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={6}
			height={6}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect width={6} height={6} rx={3} fill='#9493A1' />
		</svg>
	);
}

export default SvgListDotIcon;
