import * as React from 'react';

function SvgInfoIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={20}
			height={20}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle cx={10} cy={10} r={8} fill='#430E60' />
			<path
				d='M9.027 11.451c0-.433.091-.797.273-1.092.182-.295.494-.602.936-.923.39-.277.668-.516.832-.715.174-.208.26-.45.26-.728 0-.277-.103-.485-.311-.624-.2-.147-.481-.221-.845-.221a3.55 3.55 0 00-1.08.169c-.355.113-.719.264-1.092.455l-.689-1.391a6.977 6.977 0 011.378-.572 5.668 5.668 0 011.626-.221c.9 0 1.599.217 2.093.65.502.433.754.984.754 1.651 0 .355-.057.663-.17.923-.112.26-.281.503-.507.728-.225.217-.506.45-.845.702a6.18 6.18 0 00-.585.468c-.138.13-.233.256-.285.377a1.365 1.365 0 00-.066.455v.377H9.027v-.468zM8.82 14.09c0-.399.109-.676.325-.832.217-.165.481-.247.793-.247.304 0 .564.082.78.247.217.156.325.433.325.832 0 .381-.108.659-.325.832a1.252 1.252 0 01-.78.247c-.312 0-.576-.082-.793-.247-.216-.173-.325-.45-.325-.832z'
				fill='#EFF2F3'
			/>
		</svg>
	);
}

export default SvgInfoIcon;
