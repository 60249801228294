export const setTheme = (themeName: string) => {
	localStorage.setItem('theme', themeName);
	document.body.dataset.theme = themeName;
};

export const keepTheme = () => {
	if (localStorage.getItem('theme')) {
		// @ts-ignore
		setTheme(localStorage.getItem('theme'));
	} else {
		setTheme('light');
	}
};
