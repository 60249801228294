import styled from 'styled-components';
import { device, font } from '../../styles/variables';

export const Wrap = styled.button`
	align-self: center;
	background-color: var(--primary1);
	border-radius: 10px;
	${font.medium};
	font-size: 14px;
	line-height: 21px;
	color: #fefefe;
	padding: 10px 25px;
	transition: all 0.25s;

	&:hover {
		background-color: var(--primary2);
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
		opacity: 1;
		color: var(--linkColor);
	}

	&:active {
		background-color: var(--primary0);
		color: var(--linkColor);
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	@media ${device.mobileL} {
		padding: 5px 25px;
		min-width: 38vw;
	}

	@media ${device.mobileS} {
		min-width: 41vw;
	}
`;

export const WrapSecond = styled.button`
	align-self: center;
	background-color: var(--white);
	border-radius: 10px;
	border: 2px solid var(--secondary1);
	${font.medium};
	font-size: 14px;
	line-height: 21px;
	color: var(--darkDirtGray);
	padding: 8px 25px;
	transition: all 0.25s;

	&:hover {
		background-color: var(--secondary2);
		border: 2px solid transparent;
		opacity: 1;
		color: var(--linkColor);
	}

	&:active {
		background-color: var(--primary0);
		color: var(--white);
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	@media ${device.mobileL} {
		padding: 3px 25px;
		min-width: 38vw;
	}

	@media ${device.mobileS} {
		min-width: 41vw;
	}
`;
