import { useContext } from 'react';

// Context
import { VaultsContext } from '../../context/VaultsContext';

// Filter function used in table/columns.tsx
export const categoryFilter = (rows: any, id: number, filterValue: string) =>
	rows.filter((row: any) => {
		let filter;
		switch (filterValue) {
			case 'lending':
				filter = row.original.single_asset === false;
				break;
			case 'farming':
				filter = row.original.single_asset === true;
				break;
			case 'staking':
				filter = row.original.core === true;
				break;
			default:
				filter = row.original;
				break;
		}
		return filter;
	});

// Filter hook used in Table.tsx
export const useCategory = () => {
	const { filters, setFilters } = useContext(VaultsContext);
	const active = filters?.tabs;

	const handleCatFilter = (setFilter: any, catName: string) => {
		setFilters({
			...filters,
			tabs: catName,
		});
		setFilter('categoryName', catName);
	};

	return {
		handleCatFilter,
		active,
	};
};
