import * as React from 'react';

function SvgSunnyIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={18}
			height={15}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M9 7.483c1.701 0 3.08-1.675 3.08-3.742C12.08 1.675 11.627 0 9 0 6.372 0 5.92 1.675 5.92 3.741c0 2.067 1.379 3.742 3.08 3.742zM14.811 13.044c-.057-3.6-.527-4.625-4.124-5.274 0 0-.507.645-1.687.645-1.18 0-1.687-.645-1.687-.645-3.558.642-4.057 1.653-4.122 5.158-.006.286-.008.3-.009.267v.379S4.04 15.3 9 15.3c4.961 0 5.818-1.726 5.818-1.726v-.28c-.001.02-.003-.02-.007-.25zM13.275 6.815c1.381 0 2.502-1.36 2.502-3.039 0-1.678-.368-3.039-2.502-3.039-.36 0-.668.039-.934.111.493.909.56 2.012.56 2.893 0 .998-.27 1.95-.768 2.739.343.214.73.335 1.142.335z'
				fill='#9493A1'
			/>
			<path
				d='M17.994 11.332c-.046-2.924-.428-3.757-3.35-4.284 0 0-.41.524-1.37.524a2.55 2.55 0 01-.115-.003c.609.275 1.163.657 1.571 1.217.705.967.868 2.266.901 4.175 1.966-.388 2.369-1.2 2.369-1.2v-.227c0 .017-.003-.014-.006-.202zM4.725 6.815c.411 0 .8-.122 1.142-.335a5.13 5.13 0 01-.769-2.739c0-.88.068-1.984.56-2.893a3.566 3.566 0 00-.933-.11c-2.134 0-2.502 1.36-2.502 3.038 0 1.678 1.12 3.039 2.502 3.039zM4.841 7.569a2.546 2.546 0 01-.116.003c-.959 0-1.37-.524-1.37-.524-2.922.527-3.303 1.36-3.35 4.284-.003.188-.004.22-.005.202v.228s.403.81 2.369 1.2c.033-1.91.195-3.21.9-4.176.409-.56.963-.942 1.572-1.217z'
				fill='#9493A1'
			/>
		</svg>
	);
}

export default SvgSunnyIcon;
