import React from 'react';
import { useTranslation } from 'react-i18next';

// Helpers
// import { formatPriceNumber } from '../../../shared/utils/helpers';

// Components
import Divider from '../../../shared/components/Divider';
import Button from '../../../shared/components/Button';

// Icons
import { VGreyIcon } from '../../../shared/components/Icons';
import SiImg from '../../../assets/images/si-network-logo.png';

// Styles
import { BeFlex, Title, SInfo, Numbers } from './style';

// Interfaces
import { IGovernance } from '../../../@types/interfaces';

interface IGovData {
	data: IGovernance;
}

const Governance = ({ data }: IGovData) => {
	const { t } = useTranslation();
	const {
		general: { apy },
	} = data;

	return (
		<div className='wrap'>
			<div className='left'>
				<h2>{t('gov_staking', 'Governance Staking')}</h2>
				<p>
					{t(
						'staking_si',
						'	By staking SI Token, you can participate in the decision making of SI Pools. You can vote for a project to be approved for funding and receive SI Tokens for your effort at the same time.'
					)}
				</p>
			</div>
			<div className='right'>
				<Title>
					<div className='title'>
						<VGreyIcon />
						<p>{t('sit', 'SIT')}</p>
					</div>
					<div className='protocol'>
						<img src={SiImg} alt='' />
						<p>{t('si_network', 'Social Impact Network')}</p>
					</div>
				</Title>
				<Divider small />
				<SInfo>
					<BeFlex>
						<p>{t('apy', 'APY')}</p>
						<Numbers>{apy.toFixed(2)}%</Numbers>
					</BeFlex>
					<BeFlex>
						<p>{t('tvl', 'TVL')}</p>
						<Numbers>{/* {formatPriceNumber(Number('-'))} */}-</Numbers>
					</BeFlex>
				</SInfo>
			</div>

			<Divider />
			<Button disabled secondary>
				{t('governance', 'Governance')}
			</Button>
		</div>
	);
};

export default Governance;
