import React, { createContext, useState } from 'react';

// Interfaces
interface ContextProps {
	wallet: any;
	setWallet: any;
	walletAddress: any;
	setWalletAddress: any;
	network: any;
	setNetwork: any;
	balance: any;
	setBalance: any;
	onboard: any;
	setOnboard: any;
	provider: any;
	setProvider: any;
	signer: any;
	setSigner: any;
	onboardConnected: any;
	setOnboardConnected: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}
export const OnboardContext = createContext<Partial<ContextProps>>({});

const OnboardContextProvider = ({ children }: ProviderProps) => {
	const [onboardConnected, setOnboardConnected] = useState<any>();
	const [wallet, setWallet] = useState<any>();
	const [walletAddress, setWalletAddress] = useState<any>();
	const [network, setNetwork] = useState<number>();
	const [balance, setBalance] = useState<number>();

	// Onboard state
	const [onboard, setOnboard] = useState<any>();

	// Web3 provider
	const [provider, setProvider] = useState<any>();
	const [signer, setSigner] = useState<any>();

	return (
		<OnboardContext.Provider
			value={{
				wallet,
				setWallet,
				walletAddress,
				setWalletAddress,
				network,
				setNetwork,
				balance,
				setBalance,
				onboard,
				setOnboard,
				provider,
				setProvider,
				signer,
				setSigner,
				onboardConnected,
				setOnboardConnected,
			}}
		>
			{children}
		</OnboardContext.Provider>
	);
};

export default OnboardContextProvider;
