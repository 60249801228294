import * as React from 'react';

function SvgVGreyIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={18}
			height={18}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle cx={9} cy={9} r={9} fill='#FDC500' />
		</svg>
	);
}

export default SvgVGreyIcon;
