import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Link from '../../../../shared/components/Link';
import BannerImage from '../../../../assets/images/sdg.png';

// Styles
import { BannerWrap } from '../style';

const Banner = () => {
	const { t } = useTranslation();

	return (
		<BannerWrap className='b-wrap'>
			<p>{t('support_banner', 'By depositing, you are supporting:')}</p>
			<Link external to='https://sdgs.un.org/goals' className='img-link'>
				<img src={BannerImage} alt='sustainable development goals' />
			</Link>
			<Link external to='https://sdgs.un.org/goals' className='link'>
				{t('more_info', 'More info')}
			</Link>
		</BannerWrap>
	);
};

export default Banner;
