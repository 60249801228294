export const color = {
	light: {
		text: '#1A1B29',
		background: '#EFF2F3',
		white: '#FFF',
		coal: '#474954',
		lightCoal: '#75767F',
		ash: '#A3A4AA',
		primary0: '#2C0641',
		primary1: '#430E60',
		primary2: '#693E80',
		primary4: '#F2F5F8',
		primary5: '#F4F1F6',
		secondary1: '#FEDC66',
		secondary2: '#FFD9AE',
		tertiary1: '#F8F4E1',
		darkDirtGray: '#4C4B63',
		dirtGray: '#706F82',
		lightDirtGray: '#9493A1',
		superLightDirt: '#B7B7C1',
		lightMuteGray: '#EFF2F3',
		darkMuteGray: '#E0E0E0',
		border: '#430E60',
		danger: '#FF5C4C',
		icon: '#173C57',
		linkColor: '#FFF',
		orange: '#FDD133',
		purple: '#D9CFDF',
		lightOrange: '#FFF3CC',
	},
	dark: {
		text: '#E2E2E2',
		background: '#000',
		white: '#27262C',
		coal: '#C2C2C2',
		lightCoal: '#75767F',
		ash: '#A3A4AA',
		primary0: '#5f4bd0',
		primary1: '#8875F0',
		primary2: '#C2C2C2',
		primary4: '#4C4B63',
		primary5: '#3D3B40',
		secondary1: '#FEDC66',
		secondary2: '#FFD9AE',
		tertiary1: '#F8F4E1',
		darkDirtGray: '#FEFEFE',
		dirtGray: '#A2A2A2',
		lightDirtGray: '#9493A1',
		superLightDirt: '#B7B7C1',
		lightMuteGray: '#4C4B63',
		darkMuteGray: '#4C4B63',
		border: '#FDD133',
		danger: '#FF5C4C',
		icon: '#fff',
		linkColor: '#27262C',
		orange: '#FDD133',
		purple: '#8875F0',
		lightOrange: '#FFF3CC',
	},
};

export const font = {
	regularPoppins: 'font-family: "Poppins", sans-serif; font-weight: normal;',
	regularNotoSans: 'font-family: "Noto Sans", sans-serif; font-weight: normal;',
	medium: 'font-family: "Poppins", sans-serif; font-weight: 500;',
	semibold: 'font-family: "Poppins", sans-serif; font-weight: 600;',
	boldPoppins: 'font-family: "Poppins", sans-serif; font-weight: bold;',
	boldNotoSans: 'font-family: "Noto Sans", sans-serif; font-weight: bold;',
};

// Media queries
const size = {
	mobileS: '340px',
	mobileM: '375px',
	mobileL: '525px',
	tabletS: '655px',
	tablet: '768px',
	tabletL: '1200px',
	laptopL: '1440px',
	desktop: '2560px',
};

export const device = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobileL: `(max-width: ${size.mobileL})`,
	tabletS: `(max-width: ${size.tabletS})`,
	tablet: `(max-width: ${size.tablet})`,
	tabletL: `(max-width: ${size.tabletL})`,
	laptopL: `(max-width: ${size.laptopL})`,
	desktop: `(max-width: ${size.desktop})`,
	desktopL: `(max-width: ${size.desktop})`,
};
