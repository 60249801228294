import * as React from 'react';

function SvgMoon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={18}
			height={18}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M17.945 11.655a.749.749 0 00-.693-.465h-.005l-.014.002a.742.742 0 00-.235.045c-.01.004-.021.005-.032.01a7.781 7.781 0 01-2.973.582c-4.313 0-7.822-3.509-7.822-7.822 0-1.031.196-2.03.583-2.972.006-.013.008-.027.012-.04A.743.743 0 006.81.757l.002-.009V.747a.711.711 0 00-.014-.133L6.79.586a.737.737 0 00-.039-.127C6.745.444 6.736.43 6.728.415a.744.744 0 00-.99-.34l-.006.002A9.17 9.17 0 002.723 2.09c-3.63 3.635-3.63 9.55 0 13.187A9.3 9.3 0 009.318 18a9.303 9.303 0 008.602-5.722c.003-.004.003-.01.005-.014a.758.758 0 00.051-.14l.004-.01a.745.745 0 00-.034-.46z'
				fill='#fff'
			/>
			<path
				d='M17.945 11.655a.749.749 0 00-.693-.465h-.005l-.014.002a.742.742 0 00-.235.045c-.01.004-.021.005-.032.01a7.781 7.781 0 01-2.973.582c-4.313 0-7.822-3.509-7.822-7.822 0-1.031.196-2.03.583-2.972.006-.013.008-.027.012-.04A.743.743 0 006.81.757l.002-.009V.747a.711.711 0 00-.014-.133L6.79.586a.737.737 0 00-.039-.127C6.745.444 6.736.43 6.728.415a.744.744 0 00-.99-.34l-.006.002A9.17 9.17 0 002.723 2.09c-3.63 3.635-3.63 9.55 0 13.187A9.3 9.3 0 009.318 18a9.303 9.303 0 008.602-5.722c.003-.004.003-.01.005-.014a.758.758 0 00.051-.14l.004-.01a.745.745 0 00-.034-.46zm-3.094 2.56c-3.051 3.047-8.015 3.047-11.066 0-3.047-3.05-3.047-8.014 0-11.064a7.815 7.815 0 011.046-.871 9.386 9.386 0 00-.16 1.727c0 5.141 4.182 9.323 9.322 9.323a9.39 9.39 0 001.728-.16c-.258.368-.545.72-.87 1.046z'
				fill='#693E80'
			/>
		</svg>
	);
}

export default SvgMoon;
