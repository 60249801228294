import { useContext, useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

// ethers libraries
import { utils } from 'ethers';

// Context
import { OnboardContext } from '../context/OnboardContext';

// blockchain handlers
import {
	withdraw,
	deposit,
	approve,
	harvestHandlerUtil,
	harvestAllHandler,
} from './utils';

export const useWithdraw = (poolData: any, amount: any) => {
	const [disabledWithdraw, setDisabledWithdraw] = useState<boolean>(true);
	const { signer, balance, provider } = useContext(OnboardContext);
	const { addToast } = useToasts();

	useEffect(() => {
		(() => {
			if (!amount) {
				setDisabledWithdraw(true);
				return;
			}

			if (!poolData.poolWallet) {
				setDisabledWithdraw(true);

				return;
			}

			// parseEthers returns BigNumber (BN)
			const inputAmount = utils.parseEther(amount);
			const depositedAmount = utils.parseEther(poolData.poolWallet.deposited);

			// gt is > operator for BigNumber
			// https://docs.ethers.io/v5/api/utils/bignumber/
			if (inputAmount.gt(depositedAmount) || inputAmount.isZero()) {
				setDisabledWithdraw(true);
				return;
			}

			setDisabledWithdraw(false);
		})();
	}, [amount, poolData]);

	// // Disable withdraw button
	// const isWithdrawBtnDisabled = () => {
	// 	if (!amount) {
	// 		return true;
	// 	}

	// 	if (!poolData.poolWallet) {
	// 		return true;
	// 	}

	// 	// parseEthers returns BigNumber (BN)
	// 	const inputAmount = utils.parseEther(amount);
	// 	const depositedAmount = utils.parseEther(poolData.poolWallet.deposited);

	// 	// gt is > operator for BigNumber
	// 	// https://docs.ethers.io/v5/api/utils/bignumber/
	// 	if (inputAmount.gt(depositedAmount) || inputAmount.isZero()) {
	// 		return true;
	// 	}
	// 	return false;
	// };

	// Withdraw deposited function
	const withdrawHandler = async () => {
		await withdraw(
			amount,
			poolData.poolWallet.poolId,
			signer,
			addToast,
			balance,
			provider
		);
	};

	return { disabledWithdraw, withdrawHandler };
};

export const useDeposit = (amount?: any, poolData?: any) => {
	// state
	const [disableDeposit, setDisableDeposit] = useState<boolean>(true);
	const [disableEnableBtn, setDisableEnableBtn] = useState<boolean>(true);

	// context
	const { signer, balance, provider } = useContext(OnboardContext);

	// toast notifications
	const { addToast } = useToasts();

	// Disables the deposit button
	useEffect(() => {
		(() => {
			if (!amount) {
				setDisableDeposit(true);
				return;
			}

			if (!poolData.poolWallet) {
				setDisableDeposit(true);
				return;
			}

			// parseEthers returns BigNumber (BN)
			const inputAmount = utils.parseEther(amount);
			const balanceAmount = utils.parseEther(poolData.poolWallet.balance);
			const allowanceAmount = utils.parseEther(poolData.poolWallet.allowance);

			// gte is ≥ operator for BigNumber
			// https://docs.ethers.io/v5/api/utils/bignumber/
			if (inputAmount.gt(balanceAmount)) {
				// console.log('Not enough available balance!');
				setDisableDeposit(true);
				return;
			}

			if (inputAmount.gt(allowanceAmount)) {
				// console.log('Please increase allowance!');
				setDisableDeposit(true);

				return;
			}
			if (inputAmount.isZero()) {
				// console.log('Please enter amount greater than 0 (zero)!');
				setDisableDeposit(true);

				return;
			}
			setDisableDeposit(false);
		})();
	}, [amount, poolData]);

	// Disables the enable button
	useEffect(() => {
		(() => {
			if (!amount || amount === '') {
				setDisableEnableBtn(true);
				return;
			}
			setDisableEnableBtn(false);
		})();
	}, [amount, poolData]);

	//
	const approveHandler = async (contractAddress: any) => {
		approve(amount, contractAddress, signer, addToast, balance, provider);
	};

	const depositHandler = async () => {
		await deposit(
			amount,
			poolData.poolWallet.poolId,
			signer,
			addToast,
			balance,
			provider
		);
	};

	return {
		disableDeposit,
		disableEnableBtn,
		approveHandler,
		depositHandler,
	};
};

export const useHarvest = () => {
	// Toast notifications
	const { addToast } = useToasts();
	// Onboard context
	const { signer, balance, provider } = useContext(OnboardContext);

	const harvestHandler = (poolId: any) => {
		harvestHandlerUtil(poolId, signer, addToast, balance, provider);
	};

	const harvestAll = (data: any) => {
		harvestAllHandler(data, signer, addToast, balance, provider);
	};

	return { harvestHandler, harvestAll };
};
