import React, { useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';

// Query
import { QueryClientProvider, QueryClient } from 'react-query';
import Routes from './routes/Routes';
import ApiContextProvider from './context/ApiContext';
import OnboardContextProvider from './context/OnboardContext';

// General Styles
import GeneralStyles from './shared/styles/GeneralStyles';
import { keepTheme } from './shared/utils/theme';

const queryClient = new QueryClient();

function App() {
	useEffect(() => {
		keepTheme();
	});

	return (
		<QueryClientProvider client={queryClient}>
			<ApiContextProvider>
				<ToastProvider>
					<OnboardContextProvider>
						<GeneralStyles />
						<Routes />
					</OnboardContextProvider>
				</ToastProvider>
			</ApiContextProvider>
		</QueryClientProvider>
	);
}

export default App;
