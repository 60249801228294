import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Helpers
import { formatPriceNumber } from '../../../shared/utils/helpers';

// Blockchain utils
import { walletSelectHandler } from '../../../ethers/utils';
import { OnboardContext } from '../../../context/OnboardContext';
import { useHarvest } from '../../../ethers/hooks';

// Icons
import { NoWallet } from '../../../shared/components/Icons';

// Components
import Divider from '../../../shared/components/Divider';
import Button from '../../../shared/components/Button';

// Styles
import { Items, Item, Numbers, NoWalletWrap } from './style';
import { Flex } from '../../../shared/styles/GeneralStyles';

// Interfaces
import { IApi } from '../../../@types/interfaces';

interface IWallet {
	data: IApi;
}

const Wallet = ({ data }: IWallet) => {
	const { t } = useTranslation();

	const { sums } = data;
	const { harvestAll } = useHarvest();
	const { onboard } = useContext(OnboardContext);

	return (
		<Flex>
			<h4 style={{ marginBottom: '30px' }}>{t('wallet', 'Wallet')}</h4>
			{sums ? (
				<>
					<Items>
						<Item>
							<p>{t('co2_reduced', 'Total deposits')}</p>
							<Numbers className='bold'>
								{formatPriceNumber(sums.totalDeposit)} kg
							</Numbers>
						</Item>
						<Item>
							<p>{t('energy_generated', 'Your SIT balance')}</p>
							<Numbers className='bold'>
								{formatPriceNumber(sums.totalBalance)} kWh
							</Numbers>
						</Item>
						<Item>
							<p>{t('total_deposits', 'Total deposits')}</p>
							<Numbers className='bold'>
								${formatPriceNumber(sums.totalDeposit)}
							</Numbers>
						</Item>
						<Item>
							<p>{t('total_sunny_deposits', 'Total SUNNY deposits')}</p>
							<Numbers>${formatPriceNumber(sums.sitRewards)}</Numbers>
						</Item>
						<Item>
							<p>{t('sit_balance', 'SIT balance')}</p>
							<Numbers>{formatPriceNumber(sums.totalBalance)} SIT</Numbers>
						</Item>
						<Item>
							<p>{t('claimable_rewards', 'Claimable rewards')}</p>
							<Numbers>{formatPriceNumber(sums.sitRewards)} SIT</Numbers>
						</Item>
					</Items>
					<Divider />
					{/* IF sums.claimableFunds is 0 -> disable button */}
					<Button
						onClick={() => harvestAll(data)}
						disabled={sums.poolsToClaim === []}
					>
						{t('claim_all', 'Claim All')} {`(${sums.poolsToClaim.length})`}
					</Button>
				</>
			) : (
				<>
					<NoWalletWrap>
						<NoWallet style={{ marginBottom: '22px' }} />
						<p>
							{t(
								'connect_wallet_message',
								'Your data will appear here once you connect your wallet.'
							)}
						</p>
					</NoWalletWrap>
					<Divider />
					<Button onClick={() => walletSelectHandler(onboard)}>
						{t('connect_wallet_btn', 'Connect Wallet')}
					</Button>
				</>
			)}
		</Flex>
	);
};

export default Wallet;
