import * as React from 'react';

function SvgNoWallet(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={36}
			height={36}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M26.434 27.005a3.132 3.132 0 01-3.129-3.128V18.68a3.132 3.132 0 013.129-3.129h8.152c.05 0 .098.002.147.004v-5.175a2.412 2.412 0 00-2.412-2.413H2.412A2.412 2.412 0 000 10.38v21.797a2.412 2.412 0 002.412 2.412h29.909a2.412 2.412 0 002.412-2.412V27a3.13 3.13 0 01-.147.004h-8.152z'
				fill='#D1D1D4'
			/>
			<path
				d='M34.586 17.267h-8.152c-.781 0-1.414.633-1.414 1.414v5.195c0 .781.633 1.415 1.414 1.415h8.152c.78 0 1.414-.633 1.414-1.415v-5.195c0-.781-.633-1.414-1.414-1.414zm-5.343 6.053a2.041 2.041 0 110-4.083 2.041 2.041 0 010 4.083zM28.12 3.55a3.115 3.115 0 00-3.934-1.981L11.93 5.618h16.874l-.684-2.069z'
				fill='#D1D1D4'
			/>
		</svg>
	);
}

export default SvgNoWallet;
