import * as React from 'react';

function SvgDBook(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={19}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M22.593.154c-2.283.13-6.822.601-9.623 2.316a.64.64 0 00-.303.55v15.16a.66.66 0 00.97.563c2.883-1.45 7.052-1.847 9.113-1.956A1.3 1.3 0 0024 15.51V1.433a1.303 1.303 0 00-1.406-1.28v.001zM11.031 2.47C8.23.755 3.69.284 1.407.154A1.307 1.307 0 000 1.433V15.51a1.299 1.299 0 001.251 1.278c2.062.108 6.233.504 9.116 1.956a.658.658 0 00.967-.561V3.012a.628.628 0 00-.303-.542z'
				fill='#474954'
			/>
		</svg>
	);
}

export default SvgDBook;
