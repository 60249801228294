import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import { DInfoIcon } from '../../../../../shared/components/Icons';

// Styles
import { SelectSpan, SelectOption } from '../../style';
import { DropdownWrap } from './style';

// Interfaces
import { IVault, IVaults } from '../../../../../@types/interfaces';

interface IDeposit {
	data: IVault;
	dData: IVaults;
	hooks: any;
}

const Dropdown = ({ data, dData, hooks }: IDeposit) => {
	const { t } = useTranslation();

	const { setAsset, setAssetChanged } = hooks;

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setAsset(e.target.value);
		setAssetChanged(e.target.value);
	};

	useEffect(() => {
		setAsset(data.wantName);
	}, [data.wantName, setAsset]);

	return (
		<DropdownWrap>
			<p className='title'>{t('vault', 'Vault')}</p>
			<SelectSpan className='d-drop-wrap l-select'>
				<SelectOption
					value={data.wantName}
					onChange={(e) => handleChange(e)}
					className='d-drop'
				>
					{
						// @ts-ignore
						dData.map(
							(drop: any, index: number) =>
								drop.display && (
									<option key={index} value={drop.wantName}>
										{drop.wantName}
									</option>
								)
						)
					}
				</SelectOption>
			</SelectSpan>
			<div className='info'>
				<DInfoIcon />
				<p>
					{t(
						'staking_sunny',
						'By staking an asset, you will receive SI Token as reward.'
					)}
				</p>
			</div>
		</DropdownWrap>
	);
};

export default Dropdown;
