// Filter function used in table/columns.tsx
export const protocolFilter = (rows: any, id: number, filterValue: string) =>
	rows.filter((row: any) => {
		if (filterValue === '') {
			return row.original;
		}
		return (
			row.original.protocol &&
			row.original.protocol.toLowerCase() === filterValue.toLowerCase()
		);
	});

// Filter hook used in Table.tsx
export const useProtocol = () => {
	const handleProtocolFilter = (setFilter: any, protName: string) => {
		setFilter('protocol', protName);
	};

	return {
		handleProtocolFilter,
	};
};
