import * as React from 'react';

function SvgHomeIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={18}
			height={18}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M6.937 0H1.312C.59 0 0 .589 0 1.312v3.375C0 5.411.589 6 1.312 6h5.625c.724 0 1.313-.589 1.313-1.313V1.312C8.25.59 7.661 0 6.937 0zM6.937 7.5H1.312C.59 7.5 0 8.089 0 8.813v7.875C0 17.41.589 18 1.312 18h5.625c.724 0 1.313-.589 1.313-1.312V8.813c0-.724-.589-1.313-1.313-1.313zM16.688 12h-5.625c-.724 0-1.313.589-1.313 1.313v3.375c0 .723.589 1.312 1.313 1.312h5.624c.724 0 1.313-.589 1.313-1.312v-3.375c0-.724-.589-1.313-1.313-1.313zM16.688 0h-5.625C10.338 0 9.75.589 9.75 1.312v7.875c0 .724.589 1.313 1.313 1.313h5.624c.724 0 1.313-.589 1.313-1.313V1.312C18 .59 17.411 0 16.687 0z'
				fill='#B7B7C1'
			/>
		</svg>
	);
}

export default SvgHomeIcon;
