import React from 'react';
import { useTranslation } from 'react-i18next';

// Slider
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

// Helper functions and hooks
import { useDeposit } from '../../../../../ethers/hooks';
import {
	formatPriceNumber,
	useOnInput,
} from '../../../../../shared/utils/helpers';

// Components
import Divider from '../../../../../shared/components/Divider';
import Dropdown from './Dropdown';
import Link from '../../../../../shared/components/Link';

// Styles
import { Flex } from '../../../../../shared/styles/GeneralStyles';
import {
	HeadingWrap,
	InfoWrap,
	MaxWrap,
	MaxInput,
	BtnsWrap,
	DWrap,
	AWrap,
	InfoTitle,
	SliderWrap,
} from './style';

// Interfaces
import { IVault, IVaults } from '../../../../../@types/interfaces';

interface IDeposit {
	data: { poolData: IVault };
	dData: IVaults;
	hooks: any;
}

const Deposit = ({ data, dData, hooks }: IDeposit) => {
	const { t } = useTranslation();

	const { poolData } = data;
	const { setAsset } = hooks;
	const {
		amount,
		tokenMarketPrice,
		inputAmountHandler,
		onSlideDeposit,
		depositSliderPercentage,
		setDepositSliderPercentage,
		setAssetChanged,
	} = useOnInput(poolData);

	const { disableDeposit, disableEnableBtn, approveHandler, depositHandler } =
		useDeposit(amount, poolData);

	const sliderProps = {
		min: 0.0,
		max: 1.0,
		step: 0.01,
		marks: {
			0.0: '0%',
			0.25: '25%',
			0.5: '50%',
			0.75: '75%',
			1.0: '100%',
		},
	};

	return (
		<>
			<Flex className='d-wrap'>
				<HeadingWrap>
					<h5>{t('deposit_assets', 'Deposit Assets')}</h5>
				</HeadingWrap>
				<Dropdown
					dData={dData}
					data={poolData}
					hooks={{ setAsset, setAssetChanged }}
				/>
				<DWrap>
					<div className='deposit'>
						<p>{t('deposited', 'Deposited')}</p>
						<div className='d-wrap'>
							<p>
								{poolData.poolWallet &&
									formatPriceNumber(poolData.poolWallet.deposited)}{' '}
								{poolData.poolWallet?.asset}
							</p>
							<p>{`(~$${poolData?.token_price})`}</p>
						</div>
					</div>
					{/* <tr>
								<td>Balance</td>
								<td>
									{poolData.poolWallet && poolData.poolWallet.balance}Sunny
								</td>
							</tr> */}
				</DWrap>
				<AWrap>
					<p className='title'>{t('amount', 'Amount')}</p>
					<div className='available'>
						<p>{t('available', 'Available')}</p>
						<p>
							{poolData.poolWallet &&
								formatPriceNumber(poolData.poolWallet.balance)}
						</p>
					</div>
				</AWrap>
				<MaxWrap>
					<MaxInput
						type='text'
						placeholder={`0.0 ${poolData.poolWallet?.asset.toUpperCase()}`}
						value={amount}
						onChange={inputAmountHandler}
					/>
					<p className='price'>{`(~$${tokenMarketPrice})`}</p>
				</MaxWrap>
				<SliderWrap>
					<Slider
						className='range-slider'
						value={depositSliderPercentage}
						onChange={(val) => {
							setDepositSliderPercentage(val);
							onSlideDeposit(val);
						}}
						{...sliderProps}
					/>
				</SliderWrap>

				<div style={{ padding: '0 25px' }}>
					<Divider />
				</div>
				<InfoWrap>
					<div className='protocol'>
						<InfoTitle>{t('protocol', 'Protocol')}</InfoTitle>
						<p>{poolData.protocol}</p>
						<p>({t('auto_farm', 'Auto Farm')})</p>
						{!poolData.single_asset ? (
							<Link external to={poolData.create_lp}>
								{t('create_lp', 'Create LP')}
							</Link>
						) : (
							<Link external to={poolData.create_lp}>
								{t('buy', 'Buy')} {poolData.poolWallet?.asset}
							</Link>
						)}
					</div>
					<div className='apy'>
						<InfoTitle>{t('apy', 'APY')}</InfoTitle>
						<p>{poolData.apy_total.toFixed(2)}%</p>
						<p>
							{(poolData?.apy_total / 365).toFixed(2)} % {t('daily', 'Daily')}
						</p>
					</div>
					<div className='fees'>
						<InfoTitle>{t('fees', 'Fees')}</InfoTitle>
						<p>
							{poolData.fee_impact.toFixed(2)}% Social Impact (
							{t('on_profits', 'on Profits')})
						</p>
						<p>
							{poolData.fee_withdrawal.toFixed(2)}%{' '}
							{t('on_withdrawals', 'On Withdrawals')}
						</p>
						<p>
							{poolData.fee_deposit.toFixed(2)}%{' '}
							{t('on_deposits', 'On Deposits')}
						</p>
					</div>
				</InfoWrap>
				<BtnsWrap className='d-wrap'>
					<button
						type='button'
						disabled={disableEnableBtn}
						onClick={() => approveHandler(poolData.want_address)}
					>
						{t('enable', 'Enable')}
					</button>
					<button
						type='button'
						disabled={disableDeposit}
						onClick={depositHandler}
					>
						{t('deposit', 'Enable')}
					</button>
				</BtnsWrap>
			</Flex>
		</>
	);
};

export default Deposit;
