import React, { useContext, useEffect } from 'react';

// Context
import { ApiContext } from '../../../context/ApiContext';

// Components
import Layout from '../../general/Layout';
import Homepage from './Homepage';
import Loading from '../../../shared/components/Loading';

const Index = () => {
	const { data } = useContext(ApiContext);

	useEffect(() => {
		document.title = 'Home';
	}, []);

	return <Layout>{data ? <Homepage data={data} /> : <Loading />}</Layout>;
};

export default Index;
