import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Nav from './Nav';
import Header from './Header';

// Styles
import { Container, Row } from '../../shared/styles/GeneralStyles';

// TS
interface ILayout {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

const Layout = ({ children }: ILayout) => {
	const [openNav, setNav] = useState(false);
	const location = useLocation();

	return (
		<>
			<Header menu={{ openNav, setNav }} />
			<main className={location.pathname.replaceAll('/', '')}>
				<aside className={`aside-main ${openNav}`}>
					<Nav />
				</aside>
				<Container>
					<Row>{children}</Row>
					<div
						className={`overlay ${openNav}`}
						onClick={() => setNav(!openNav)}
						aria-hidden='true'
					/>
				</Container>
			</main>
		</>
	);
};

export default Layout;
