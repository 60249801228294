import styled from 'styled-components';
import { device } from '../../styles/variables';

export const Wrap = styled.div`
	margin: 25px 0;
	height: 1px;
	background-color: var(--darkMuteGray);
	width: 100%;

	&.small {
		margin: 20px 0;
	}

	@media ${device.mobileL} {
		&.small {
			margin: 16px 0;
		}
	}
`;
