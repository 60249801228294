import * as React from 'react';

function SvgLogoSmallChart(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={34}
			height={41}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M16.858 0l-4.092 4.1 4.092 4.099 4.092-4.1L16.858 0z'
				fill='#FDC500'
			/>
			<path
				d='M6.631 22.55a2.895 2.895 0 01-.628-3.16c.145-.35.359-.67.628-.94l6.13-6.152-4.084-4.1-6.138 6.15a8.708 8.708 0 000 12.294 8.679 8.679 0 006.138 2.547 8.667 8.667 0 006.138-2.547l8.187-8.191a2.891 2.891 0 013.153-.63 2.897 2.897 0 01.938 4.729l-6.137 6.15 4.092 4.099 6.152-6.157a8.708 8.708 0 00-2.816-14.186 8.666 8.666 0 00-9.46 1.885l-8.201 8.21a2.894 2.894 0 01-4.092 0zM16.858 32.801l-4.092 4.1L16.858 41l4.092-4.1-4.092-4.099z'
				fill='#430E60'
			/>
		</svg>
	);
}

export default SvgLogoSmallChart;
