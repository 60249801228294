import styled from 'styled-components';
import { font, device } from '../../styles/variables';

export const Wrap = styled.a`
	align-self: center;
	background-color: var(--primary1);
	border-radius: 10px;
	${font.medium};
	font-size: 14px;
	line-height: 21px;
	color: #fefefe;
	padding: 10px 25px;
	transition: all 0.25s;

	&:hover {
		background-color: var(--primary2);
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
		opacity: 1;
		color: var(--linkColor);
	}

	&:active {
		background-color: var(--primary0);
		color: var(--linkColor);
	}

	@media ${device.mobileL} {
		padding: 5px 25px;
	}
`;
