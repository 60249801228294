import * as React from 'react';

function SvgTwitter(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={20}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M24 2.308c-.892.392-1.843.651-2.835.778A4.893 4.893 0 0023.33.365a9.833 9.833 0 01-3.12 1.191 4.919 4.919 0 00-8.511 3.365c0 .39.033.764.114 1.121C7.721 5.845 4.103 3.883 1.67.897a4.954 4.954 0 00-.673 2.487c0 1.704.877 3.214 2.185 4.089A4.859 4.859 0 01.96 6.867v.054a4.943 4.943 0 003.942 4.834c-.4.11-.837.162-1.29.162a4.35 4.35 0 01-.932-.083c.638 1.948 2.447 3.38 4.598 3.427a9.886 9.886 0 01-6.1 2.099c-.404 0-.791-.018-1.178-.068A13.852 13.852 0 007.548 19.5c9.054 0 14.004-7.5 14.004-14.001 0-.217-.008-.427-.018-.636A9.816 9.816 0 0024 2.308z'
				fill='#9493A1'
			/>
		</svg>
	);
}

export default SvgTwitter;
