import * as React from 'react';

function SvgLogoSmall(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={23}
			height={27}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M11.101 0L8.407 2.7 11.1 5.4l2.695-2.7L11.1 0z' fill='#FDC500' />
			<path
				d='M4.367 14.85a1.907 1.907 0 010-2.7L8.404 8.1l-2.69-2.7-4.042 4.05a5.734 5.734 0 001.855 9.337 5.707 5.707 0 006.229-1.241l5.392-5.395a1.902 1.902 0 013.108.62 1.908 1.908 0 01-.414 2.08L13.8 18.9l2.695 2.7 4.051-4.055a5.735 5.735 0 00-1.854-9.343 5.707 5.707 0 00-6.23 1.242l-5.4 5.406a1.906 1.906 0 01-2.695 0zM11.101 21.6l-2.694 2.7L11.1 27l2.695-2.7-2.695-2.7z'
				fill='#430E60'
			/>
		</svg>
	);
}

export default SvgLogoSmall;
