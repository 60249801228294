import * as React from 'react';

function SvgDiscord(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={22}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M14.597 11.4a1.198 1.198 0 01-.269.652 1.288 1.288 0 01-.598.406 1.346 1.346 0 01-.734.028 1.3 1.3 0 01-.63-.36 1.207 1.207 0 01-.322-.63c-.04-.235-.009-.477.092-.695.1-.219.266-.404.475-.535.21-.13.454-.2.704-.2.176.008.348.048.508.119.16.07.303.17.422.294.12.124.212.27.272.427.06.158.088.326.08.494zm-5.871-1.333a1.428 1.428 0 00-.926.422 1.304 1.304 0 00-.376.911c0 .338.135.664.376.911.242.248.573.399.926.422.176-.007.349-.047.508-.118.16-.07.304-.17.423-.295a1.27 1.27 0 00.272-.426c.06-.158.087-.326.08-.494a1.218 1.218 0 00-.078-.495 1.262 1.262 0 00-.272-.428 1.33 1.33 0 00-.424-.295c-.16-.07-.334-.11-.51-.115h.001zM22 2.472V24c-3.167-2.671-2.154-1.787-5.833-5.052l.666 2.22H2.577a2.648 2.648 0 01-1.825-.727A2.41 2.41 0 010 18.696V2.472A2.41 2.41 0 01.752.727 2.647 2.647 0 012.577 0h16.846a2.647 2.647 0 011.825.727A2.41 2.41 0 0122 2.472zm-3.583 11.376a15.483 15.483 0 00-1.81-6.996 6.396 6.396 0 00-3.533-1.26l-.176.192a8.52 8.52 0 013.13 1.524 11.076 11.076 0 00-4.654-1.213c-1.628-.06-3.25.235-4.74.865-.464.204-.74.348-.74.348a8.644 8.644 0 013.308-1.575l-.123-.141a6.395 6.395 0 00-3.532 1.26 15.482 15.482 0 00-1.813 6.996c.433.58 1.01 1.05 1.678 1.369a4.739 4.739 0 002.156.455s.465-.54.842-.996a3.924 3.924 0 01-2.2-1.416c.185.123.49.284.516.3a9.554 9.554 0 007.845.42c.508-.183.993-.42 1.445-.708a3.985 3.985 0 01-2.275 1.428c.377.456.83.972.83.972a4.779 4.779 0 002.16-.457 4.546 4.546 0 001.686-1.367z'
				fill='#9493A1'
			/>
		</svg>
	);
}

export default SvgDiscord;
