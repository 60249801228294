import { useContext } from 'react';

// Context
import { VaultsContext } from '../../context/VaultsContext';

// Filter function used in table/columns.tsx
export const balanceFilter = (rows: any, id: number, filterValue: boolean) =>
	rows.filter((row: any) => {
		if (filterValue === true) {
			return row.original.poolWallet && row.original.poolWallet.balance > 0;
		}
		return row.original;
	});

// Filter hook used in Table.tsx
export const useZeroBalance = () => {
	const { filters, setFilters } = useContext(VaultsContext);

	const handleZeroFilter = (setFilter: any) => {
		setFilters({
			...filters,
			zero: !filters?.zero,
		});
		setFilter('poolWallet.balance', !filters?.zero);
	};

	return {
		handleZeroFilter,
	};
};
