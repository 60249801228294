import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { VaultsContext } from '../../context/VaultsContext';

import { useAssets } from './useAssets';

// Styles
import { SelectSpan, SelectOption } from '../../style';

const Index = ({ setFilter }: any) => {
	const { t } = useTranslation();

	const { filters, setFilters } = useContext(VaultsContext);
	const { handleAssetsFilter } = useAssets();

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setFilters({
			...filters,
			assets: e.target.value,
		});
		handleAssetsFilter(setFilter, e.target.value);
	};

	return (
		<SelectSpan className='a-select'>
			<SelectOption value={filters?.assets} onChange={(e) => handleChange(e)}>
				<option value='' disabled hidden>
					{t('assets', 'Assets')}
				</option>
				<option value=''>{t('all', 'All')}</option>
				<option value='sunny'>Sunny</option>
				{/* <option value='sushi'>Sushi</option>
				<option value='wbnb'>Wbnb</option> */}
				<option value='eth'>Eth</option>
				{/* <option value='link'>Link</option>
				<option value='cake'>Cake</option>
				<option value='bdo'>Bdo</option>
				<option value='kebab'>Kebab</option>
				<option value='usd'>Usd</option>
				<option value='dot'>Dot</option> */}
				<option value='btc'>Btc</option>
				{/* <option value='ust'>Ust</option>
				<option value='egg'>Egg</option>
				<option value='viking'>Viking</option> */}
			</SelectOption>
		</SelectSpan>
	);
};

export default Index;
