import React from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import { ApyWrap } from '../style';

// Interfaces
import { IVault } from '../../../../@types/interfaces';

interface IApy {
	data: IVault;
}

const APYCalculator = ({ data }: IApy) => {
	const { t } = useTranslation();

	const { apr_farm: aprFarm, apy_total: apiTotal } = data;

	return (
		<ApyWrap>
			<p className='title'>{t('apy_calculator', 'APY Calculator')}</p>
			<div className='item-wrap'>
				<div className='item'>
					<p className='header'>{t('timeframe', 'Timeframe')}</p>
					<p>1 {t('day', 'day')}</p>
					<p>7 {t('day', 'day')}</p>
					<p>30 {t('days', 'days')}</p>
					<p>365 {t('days', 'days')}</p>
				</div>
				<div className='item'>
					<p className='header'>{t('farm_apr', 'Farm APR')}</p>
					<p>{(aprFarm / 365).toFixed(2)}%</p>
					<p>{((aprFarm / 365) * 7).toFixed(2)}%</p>
					<p>{((aprFarm / 365) * 30).toFixed(2)}%</p>
					<p>{aprFarm.toFixed(2)}%</p>
				</div>
				<div className='item'>
					<p className='header'>{t('compund_apy', 'Compund APY')}</p>
					<p>{(aprFarm / 365).toFixed(2)}%</p>
					<p>{((aprFarm / 365) * 7).toFixed(2)}%</p>
					<p>{((aprFarm / 365) * 30).toFixed(2)}%</p>
					<p>{aprFarm.toFixed(2)}%</p>
				</div>
				<div className='item'>
					<p className='header'>{t('si_apr', 'SI APR')}</p>
					<p>{(apiTotal / 365).toFixed(2)}%</p>
					<p>{((apiTotal / 365) * 7).toFixed(2)}%</p>
					<p>{((apiTotal / 365) * 30).toFixed(2)}%</p>
					<p>{apiTotal.toFixed(2)}%</p>
				</div>
			</div>
			<p className='text'>
				{t(
					'note',
					'	Note: APY represents earning interest based on the deposit as well as the past interest.'
				)}
			</p>
		</ApyWrap>
	);
};

export default APYCalculator;
