/* eslint no-underscore-dangle: 0 */
/* eslint no-console:"off" */

// Ethers connection to blockchain
import { ethers, utils } from 'ethers';
import getSigner from './signer';

// enum
import { NetworkNames, ContractAddress } from './enum';
import { MasterChefABI, underlyingTokenABI, sushiTokenABI } from './tokenABIs';

// Initializes contract
export const contractInit = async (
	address: string,
	contractInterface: ethers.ContractInterface,
	provider: ethers.providers.Provider | null | undefined
): Promise<ethers.Contract> =>
	new ethers.Contract(address, contractInterface, getSigner(provider));

// Get Earnings
export const getEarnings = async (
	contract: ethers.Contract,
	poolId: number,
	address: string
): Promise<any> =>
	contract.pendingSushi(poolId, address).then((item: any) => {
		const formattedValue = utils.formatEther(item._hex);
		return { earningsBN: item, amount: formattedValue };
	});

// Get Balance
export const getBalance = async (
	contract: ethers.Contract,
	address: string
): Promise<string> =>
	contract.balanceOf(address).then((item: any) => utils.formatEther(item._hex));

// Get Allowance
export const getAllowance = async (
	contract: ethers.Contract,
	address: string,
	contractAddress: string
): Promise<string> =>
	contract
		.allowance(address, contractAddress)
		.then((item: any) => utils.formatEther(item._hex));

// Get Deposited amount
export const getDeposited = async (
	contract: ethers.Contract,
	poolId: number,
	address: string
): Promise<string> =>
	contract
		.userInfo(poolId, address)
		.then((item: any) => utils.formatEther(item.amount._hex));

// On wallet select
export const walletSelectHandler = async (onboard: any) => {
	if (!onboard) {
		throw new Error('No onboard found!');
	}

	if (await onboard.walletSelect()) {
		await onboard.walletCheck();
	}
};

// Get blockchain data
export const getDataFromBlockchain = async (
	walletAddress: any,
	data: any,
	provider: any
) => {
	// Master contract
	const MasterChef = await contractInit(
		ContractAddress.MasterChef,
		MasterChefABI,
		provider
	);

	// SIT TOKEN BALANCE
	const SIToken = await contractInit(
		ContractAddress.SIToken,
		sushiTokenABI,
		provider
	);

	// Get SIT token balance
	const sitBalance = await getBalance(SIToken, walletAddress);

	// Declare variables for sums
	let depositSum = 0;
	let earningSum = 0;

	// Returns deposited,
	const info = await data.si_vaults.vaults.map(async (pool: any) => {
		// Contract declaration for erc20 token
		const UnderlyingToken = await contractInit(
			pool.want_address,
			sushiTokenABI,
			provider
		);

		// GET DEPOSITED
		const deposited = await getDeposited(
			MasterChef,
			pool.pool_id,
			walletAddress
		);

		// GET EARNINGS
		const earningsObj = await getEarnings(
			MasterChef,
			pool.pool_id,
			walletAddress
		);

		const earnings = earningsObj.amount;

		// BALANCE
		const balance = await getBalance(UnderlyingToken, walletAddress);

		// GET ALLOWANCE
		const allowance = await getAllowance(
			UnderlyingToken,
			walletAddress,
			ContractAddress.MasterChef
		);

		// Calculates sums
		depositSum += parseFloat(deposited);
		earningSum += parseFloat(earnings);

		return {
			id: pool.id,
			asset: pool.wantName,
			poolId: pool.pool_id,
			deposited,
			siEarnings: earnings,
			balance,
			allowance,
			// address: pool.want_address,
		};
	});

	let awaitInfo: any;
	try {
		awaitInfo = await Promise.all(info);
	} catch (err) {
		console.warn(err);
	}

	const earnablePools = data.si_vaults.vaults.map((pool: any) => pool.pool_id);

	const claimablePools = Array.from(new Set(earnablePools)).map(
		async (poolId: any) => {
			// GET EARNINGS
			const earningsObj = await getEarnings(MasterChef, poolId, walletAddress);
			if (!earningsObj.earningsBN.isZero()) {
				return poolId;
			}
			return null;
		}
	);

	let poolsToClaim: any;
	try {
		const arr = await Promise.all(claimablePools);
		// filter out null values
		poolsToClaim = arr.filter((poolId: any) => poolId !== null);
	} catch (err) {
		console.warn(err);
	}

	return {
		wallet: {
			sums: {
				totalDeposit: depositSum,
				totalBalance: sitBalance,
				sitRewards: earningSum,
				poolsToClaim,
			},
		},
		poolWallets: awaitInfo,
	};
};

// Returns network name
export const networkName = (id: number | string) => {
	switch (id) {
		case 1:
			return NetworkNames.Mainnet;
		case 3:
			return NetworkNames.Ropsten;
		case 4:
			return NetworkNames.Rinkeby;
		case 5:
			return NetworkNames.Goerli;
		case 42:
			return NetworkNames.Kovan;
		case 97:
			return NetworkNames.BinanceTestNet;
		case 'localhost':
			return NetworkNames.Localhost;
		default:
			return NetworkNames.Unknown;
	}
};

// Use this function after transaction has been sent to get the state of transaction
const getTransactionInfo = async (transaction: any, addToast: any) => {
	// If the transaction has a hash, it is submitted to the blockchain
	if (transaction.hash) {
		addToast('Transaction Sent!', {
			appearance: 'info',
			autoDismiss: true,
		});
		// waiting for transaction results
		const result = await transaction.wait();

		// 0 -> FAIL
		if (result.status === 0) {
			addToast('Transaction Failed!', {
				appearance: 'warning',
				autoDismiss: true,
			});
		}
		// 1-> SUCCESS
		if (result.status === 1) {
			addToast('Transaction Successful!', {
				appearance: 'success',
				autoDismiss: true,
			});
		}
	}
};

export const checkToCoverGasCosts = async (balance: any, provider: any) => {
	const balanceEther = utils.formatEther(balance);
	const balanceEtherBN = utils.parseEther(balanceEther);
	const gasPrice = await provider.getGasPrice();

	if (!gasPrice.gt(balanceEtherBN)) {
		return true;
	}
	return false;
};

// APPROVE FUNCTION
export const approve = async (
	amount: any,
	address: any,
	signer: any,
	addToast: any,
	balance?: any,
	provider?: any
) => {
	if (!checkToCoverGasCosts(balance, provider)) {
		return;
	}
	// ERC20 CONTRACT DECLARATION
	const erc20Contract = new ethers.Contract(
		address,
		underlyingTokenABI,
		signer
	);

	// APPROVES CONTRACT TO SPEND TOKENS
	let transaction: any;
	try {
		transaction = await erc20Contract.approve(
			ContractAddress.MasterChef,
			utils.parseEther(amount)
		);

		if (transaction) {
			getTransactionInfo(transaction, addToast);
		}
	} catch (err) {
		console.log('Error in Approval!', err);
	}
};

// DEPOSIT FUNCTION
export const deposit = async (
	amount: any,
	poolId: any,
	signer: any,
	addToast: any,
	balance?: any,
	provider?: any
) => {
	if (!checkToCoverGasCosts(balance, provider)) {
		return;
	}
	// ERC20 CONTRACT DECLARATION
	const masterChef = new ethers.Contract(
		ContractAddress.MasterChef,
		MasterChefABI,
		signer
	);

	let transaction: any;
	try {
		transaction = await masterChef.deposit(poolId, utils.parseEther(amount));
		if (transaction) {
			getTransactionInfo(transaction, addToast);
		}
	} catch (err) {
		console.log('Error in deposit!', err);
	}
};

// Withdraw deposited amount function
export const withdraw = async (
	amount: any,
	poolId: any,
	signer: any,
	addToast: any,
	balance?: any,
	provider?: any
) => {
	if (!checkToCoverGasCosts(balance, provider)) {
		return;
	}
	// ERC20 contract approve
	const masterChef = new ethers.Contract(
		ContractAddress.MasterChef,
		MasterChefABI,
		signer
	);

	// transaction to withdraw deposited

	let transaction: any;
	try {
		transaction = await masterChef.withdraw(poolId, utils.parseEther(amount));
		if (transaction) {
			getTransactionInfo(transaction, addToast);
		}
	} catch (err) {
		console.log('Error in Harvest!', err);
	}
};

// Harvest Handler
export const harvestHandlerUtil = async (
	poolId: any,
	signer: any,
	addToast: any,
	balance?: any,
	provider?: any
) => {
	if (!checkToCoverGasCosts(balance, provider)) {
		return;
	}
	// MASTER CONTRACT DECLARATION
	const masterChef = new ethers.Contract(
		ContractAddress.MasterChef,
		MasterChefABI,
		signer
	);

	// HARVEST FUNCTION
	// First param is pool id -> integer
	// Second param is the amount
	// to harvest the amount is 0 -> integer
	let transaction: any;
	try {
		transaction = await masterChef.deposit(Number(poolId), 0);
		if (transaction) {
			getTransactionInfo(transaction, addToast);
		}
	} catch (err) {
		if (err.code === 4001) {
			console.log('Transaction rejected!', err);
		} else {
			console.log('Error in Harvest!', err);
		}
	}
};

// HARVEST ALL BUTTON
export const harvestAllHandler = async (
	data: any,
	signer: any,
	addToast: any,
	balance?: any,
	provider?: any
) => {
	if (!checkToCoverGasCosts(balance, provider)) {
		return;
	}
	// MASTER CONTRACT DECLARATION
	const masterChef = new ethers.Contract(
		ContractAddress.MasterChef,
		MasterChefABI,
		signer
	);

	if (!masterChef) {
		return;
	}

	data.sums.poolsToClaim.map(async (poolId: any) => {
		// HARVEST FUNCTION
		// 			// First param is pool id -> integer
		// 			// Second param is amount
		// 			// to harvest all amount is 0 -> integer
		let transaction: any;
		try {
			transaction = await masterChef.deposit(Number(poolId), 0);
			if (transaction) {
				// for notifications
				await getTransactionInfo(transaction, addToast);
			}
		} catch (err) {
			console.log('Error in Harvest!', err);
		}
	});
};

// SWITCH NETWORK TO BSC TESTNET
export const switchToBSCTestnet = async (provider: any) => {
	try {
		await provider.provider.request({
			method: 'wallet_addEthereumChain',
			params: [
				{
					chainId: '0x61',
					chainName: 'Binance Smart Chain Testnet',
					nativeCurrency: {
						name: 'Binance Coin',
						symbol: 'BNB',
						decimals: 18,
					},
					rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
					blockExplorerUrls: ['https://testnet.bscscan.com/'],
				},
			],
		});
	} catch (error) {
		console.log(error);
	}
};

// SWITCH NETWORK TO BSC MAINNET
export const switchToBSCMainnet = async (provider: any) => {
	try {
		await provider.provider.request({
			method: 'wallet_addEthereumChain',
			params: [
				{
					chainId: '0x38',
					chainName: 'Binance Smart Chain Mainnet',
					nativeCurrency: {
						name: 'Binance Coin',
						symbol: 'BNB',
						decimals: 18,
					},
					rpcUrls: ['https://bsc-dataseed.binance.org/'],
					blockExplorerUrls: ['https://bscscan.com/'],
				},
			],
		});
	} catch (error) {
		console.log(error);
	}
};
