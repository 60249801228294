import React from 'react';
import { NavLink } from 'react-router-dom';

// Styles
import { Wrap } from './style';

export interface ILink {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
	to: string;
	external?: boolean;
	style?: React.CSSProperties;
	btn?: boolean;
	className?: string;
	aria?: string;
}

const Link = ({ children, to, external, style, btn, className, aria }: ILink) =>
	!external ? (
		<NavLink
			exact
			to={to}
			style={style}
			className={className}
			aria-label={aria || 'Link'}
		>
			{children}
		</NavLink>
	) : (
		<>
			{btn ? (
				<Wrap
					href={to}
					target='_blank'
					rel='noopener noreferrer'
					style={style}
					className={className}
					aria-label={aria || 'Link'}
				>
					{children}
				</Wrap>
			) : (
				<a
					href={to}
					target='_blank'
					rel='noopener noreferrer'
					style={style}
					className={className}
					aria-label={aria || 'Link'}
				>
					{children}
				</a>
			)}
		</>
	);

export default Link;
