// Filter function used in table/columns.tsx
export const assetsFilter = (rows: any, id: number, filterValue: string) =>
	rows.filter((row: any) => {
		if (filterValue === '') {
			return row.original;
		}
		return row.original.wantName
			.toLowerCase()
			.includes(filterValue.toLowerCase());
	});

// Filter hook used in Table.tsx
export const useAssets = () => {
	const handleAssetsFilter = (setFilter: any, wantName: string) => {
		setFilter('wantName', wantName);
	};

	return {
		handleAssetsFilter,
	};
};
