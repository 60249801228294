import Onboard from 'bnc-onboard';
import { NetworkID } from './enum';

// AFTER MAINNET DEPLOYMENT UPDATE rpcURL for trust wallet
// and delete infura key & add rpcURL for tokenpocket

// Onboard Initialization
// AFTER MAINNET DEPLOYMENT UPDATE rpcURL for trust wallet
// and delete infura key & add rpcURL for tokenpocket

export const initOnboard = (subscriptions: any): any =>
	Onboard({
		hideBranding: false,
		networkId: NetworkID.Selected,
		subscriptions,
		walletSelect: {
			wallets: [
				{ walletName: 'metamask' },
				{ walletName: 'tokenpocket' },
				{
					walletName: 'walletConnect',
					infuraKey: 'dc638becd4544dd7a6dd3ac4e8dad17e',
				},
				{
					walletName: 'trust',
					rpcUrl:
						'https://ropsten.infura.io/v3/dc638becd4544dd7a6dd3ac4e8dad17e',
				},
			],
		},
	});

// Binance wallet
// DOESN'T WORK AT ALL
// const Binance = {
// 	name: 'Binance',
// 	svg: binanceLogo,
// 	wallet: async (helpers: any) => {
// 		const { createModernProviderInterface } = helpers;

// 		const instance = new BscConnector({ supportedChainIds: [56] });
// 		const provider = await instance.getProvider();
// 		await instance.deactivate();

// 		return (
// 			provider && {
// 				provider: provider,
// 				interface: {
// 					...createModernProviderInterface(provider),
// 					name: 'Binance',
// 					connect: await provider.requestAccounts(),
// 					disconnect: () =>
// 						provider.on('close', () =>
// 							console.log("Handling 'close' event with payload")
// 						),
// 				},
// 			}
// 		);
// 	},
// 	link: 'https://www.binance.com/',
// 	installMessage: (wallets: any) => {
// 		const { currentWallet, selectedWallet } = wallets;
// 		if (currentWallet) {
// 			return `You have ${currentWallet} installed already but if you would prefer to use ${selectedWallet} instead, then click below to install.`;
// 		}

// 		return `You will need to install ${selectedWallet} to continue. Click below to install.`;
// 	},
// 	desktop: true,
// };
