import React, { createContext, useState, useEffect } from 'react';

// Api
import { useQuery } from 'react-query';
import { fetchMainData } from '../config/api';

// Helpers
import { apiVaultsOrder } from '../shared/utils/helpers';

// Interfaces
interface ContextProps {
	data: any;
	status: string;
	setWalletInfo: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export const ApiContext = createContext<Partial<ContextProps>>({});

const ApiContextProvider = ({ children }: ProviderProps) => {
	const [walletInfo, setWalletInfo] = useState<any>();
	const { data: apiData } = useQuery('mainData', fetchMainData, {
		refetchInterval: 300000,
	});
	const [data, setData] = useState();

	useEffect(() => {
		if (apiData && !walletInfo) {
			// Reorder vaults by API rules
			const orderedVaultsdata = apiVaultsOrder(
				apiData.si_vaults.vaults,
				apiData.si_vaults.display_order
			);
			setData({ ...apiData, si_vaults: { vaults: orderedVaultsdata } });
		} else if (walletInfo) {
			let mergedData = apiData;

			// Reorder vaults by API rules + wallet
			const orderedVaultsdata = apiVaultsOrder(
				apiData.si_vaults.vaults,
				apiData.si_vaults.display_order
			);

			// Reorder wallet data to match reordered vaults
			const orderedWalletData = apiVaultsOrder(
				walletInfo.poolWallets,
				apiData.si_vaults.display_order
			);

			// Merge wallet with API data
			mergedData = {
				...apiData,
				...walletInfo.wallet,
				walletLoaded: true,
				si_vaults: { vaults: orderedVaultsdata },
			};

			// Inject wallet in every vault
			for (let i = 0; i < mergedData.si_vaults.vaults.length; i += 1) {
				mergedData.si_vaults.vaults[i] = {
					...mergedData.si_vaults.vaults[i],
					poolWallet: orderedWalletData[i],
				};
			}

			setData(mergedData);
		}
	}, [apiData, walletInfo]);

	return (
		<ApiContext.Provider value={{ data, setWalletInfo }}>
			{children}
		</ApiContext.Provider>
	);
};

export default ApiContextProvider;
