import styled from 'styled-components';

export const InfoWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	background-color: var(--white);
	padding: 25px 20px;

	.info-item {
		min-width: 300px;
		margin-bottom: 10px;

		.title {
			margin-bottom: 4px;
			font-size: 14px;
			color: var(--coal);
		}

		p {
			font-size: 12px;
			color: var(--dirtGray);
		}
	}
`;
