import * as React from 'react';

function SvgFilterIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={14}
			height={15}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M13.346.942H.654a.66.66 0 00-.604.404.649.649 0 00.142.71L5.249 7.08v5.598a.65.65 0 00.28.534l2.189 1.521a.66.66 0 00.937-.196.65.65 0 00.096-.338V7.08l5.058-5.025a.652.652 0 00-.463-1.113z'
				fill='#430E60'
			/>
		</svg>
	);
}

export default SvgFilterIcon;
