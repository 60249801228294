import * as React from 'react';

function SvgDTelegram(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={20}
			height={17}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M19.948 1.548l-3.02 14.426c-.228 1.018-.822 1.272-1.666.791l-4.599-3.434-2.217 2.161a1.159 1.159 0 01-.924.457l.33-4.747L16.376 3.4c.371-.335-.08-.521-.576-.186L5.26 9.938.723 8.498c-.987-.312-1.005-1 .205-1.48L18.672.091c.822-.313 1.54.185 1.27 1.457h.006z'
				fill='#474954'
			/>
		</svg>
	);
}

export default SvgDTelegram;
