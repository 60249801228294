/* eslint no-bitwise: 0 */

import { useState, useEffect } from 'react';

// ethers libraries
import { utils, BigNumber } from 'ethers';

// Interfaces
import { IVaults, IVault, IPrices } from '../../@types/interfaces';

interface IVaultOrder {
	allowance: string;
	asset: string;
	balance: string;
	deposited: string;
	id: number;
	poolId: number;
	siEarnings: string;
}

// Sort array by API rule [array of indexes]
export const apiVaultsOrder = (
	dataForOrder: IVaults,
	orderBy: Array<number>
) => {
	const array = dataForOrder;
	const sortArray = orderBy;
	// @ts-ignore
	const arrayMap = array.reduce(
		(accumulator: IVaultOrder, currentValue: IVault) => ({
			...accumulator,
			[currentValue.id]: currentValue,
		}),
		{}
	);
	return sortArray.map((key: number) => arrayMap[key]);
};

// Merge token prices with corresponding vault
export const mergeTokenPrices = (prices: IPrices, data: IVaults) => {
	const mergedArr = <any>[];
	// @ts-ignore
	data.map((d: IVault) => {
		if (d.wantName in prices) {
			mergedArr.push({ ...d, token_price: prices[d.wantName] });
		} else if (d.wantName.toUpperCase() in prices) {
			mergedArr.push({ ...d, token_price: prices[d.wantName.toUpperCase()] });
		}

		return null;
	});
	return mergedArr;
};

// Format prices https://en.wikipedia.org/wiki/International_System_of_Units
const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E'];

export const formatPriceNumber = (number: string | number, format?: number) => {
	// what tier? (determines SI symbol)
	const tier = (Math.log10(Math.abs(Number(number))) / 3) | 0;

	// if zero, we don't need a suffix
	if (tier === 0) return Number(number).toFixed(2);

	// get suffix and determine scale
	const suffix = SI_SYMBOL[tier] || '';
	// eslint-disable-next-line no-restricted-properties
	const scale = Math.pow(10, tier * 3);

	// scale the number
	const scaled = Number(number) / scale;

	// format number and add suffix
	return `${scaled.toFixed(format === undefined ? 2 : format)} ${suffix}`;
};

// Click outside handler
export function useOnClickOutside(
	parentRef: any,
	childRef: any,
	openMenu: boolean,
	handler: any
) {
	useEffect(() => {
		const listener = (event: TouchEvent | MouseEvent) => {
			if (!openMenu) {
				return;
			}

			// Do nothing if clicking ref's element or descendent elements
			if (
				!parentRef.current ||
				parentRef.current.contains(event.target) ||
				!childRef.current ||
				childRef.current.contains(event.target)
			) {
				return;
			}

			handler(event);
		};
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [parentRef, childRef, openMenu, handler]);
}

// gets pool data by FILTER
export const useGetPoolData = (data: any, dData: any) => {
	const { want_address: address, pool_id: poolId, wantName } = dData;
	const [asset, setAsset] = useState<string>(wantName);
	const [poolData, setPoolData] = useState<any>();
	const [poolDataError, setPoolDataError] = useState<any>(false);

	useEffect(() => {
		const getData = () => {
			const poolInfo = data.find((pool: IVault) => pool.wantName === asset);
			setPoolData(poolInfo);
		};
		if (!data) {
			setPoolDataError(true);
			return;
		}
		if (data) {
			getData();
		}
	}, [address, data, poolId, asset]);

	return { poolData, poolDataError, setAsset };
};

// Change amount on user input
export const useOnInput = (poolData: any) => {
	const [amount, setAmount] = useState<string>('0.0');
	const [assetChanged, setAssetChanged] = useState<string>('');

	const [tokenMarketPrice, setTokenMarketPrice] = useState<string>('0.0');
	const [depositSliderPercentage, setDepositSliderPercentage] =
		useState<number>(0);
	const [withdrawSliderPercentage, setWithdrawSliderPercentage] =
		useState<number>(0);

	useEffect(() => {
		setAmount('0.0');
		setWithdrawSliderPercentage(0);
	}, [assetChanged]);

	const inputAmountHandler = (e: any) => {
		const inputValue = e.target.value;
		if (inputValue.match('^[0-9]*[.]?[0-9]{0,2}$') !== null) {
			setAmount(inputValue);
			setDepositSliderPercentage(inputValue / poolData.poolWallet.balance);
			setWithdrawSliderPercentage(inputValue / poolData.poolWallet.deposited);
		}
	};

	const totalTokenCalculator = (percent: any, referentValue: any) => {
		// refValue: either poolWallet.deposited or poolWallet.balance

		// if (!deposited.isZero()) { -> may be unnecessary
		// formula: (deposited * event percentage) / 100
		// event percentage should be a string i.e. 25% is "25"
		// everything is a big number since tokens have up to 18 decimal places
		// float/strings with decimals can't be converted to a BigNumber
		const stringValue = (percent * 100).toFixed();
		const eventNumber = BigNumber.from(stringValue);
		const hundred = BigNumber.from('100');
		const multiplied = referentValue.mul(eventNumber);

		const resultBN = multiplied.div(hundred);

		/* eslint no-underscore-dangle: 0 */
		const totalTokenAmount = utils.formatEther(resultBN._hex);

		if (totalTokenAmount) {
			setAmount(Number(totalTokenAmount).toFixed(2));
		}
	};

	// Changes token amount on slider
	const onSlideWithdraw = (percent: any) => {
		if (!poolData) {
			return;
		}

		const referentValue = BigNumber.from(
			utils.parseEther(poolData.poolWallet.deposited)
		);

		if (referentValue) {
			totalTokenCalculator(percent, referentValue);
		}
	};
	// Changes token amount on slider
	const onSlideDeposit = (percent: any) => {
		if (!poolData) {
			return;
		}
		const referentValue = BigNumber.from(
			utils.parseEther(poolData.poolWallet.balance)
		);
		if (referentValue) {
			totalTokenCalculator(percent, referentValue);
		}
	};

	useEffect(() => {
		// Calculate market price
		const calculateMarketPrice = () => {
			if (!poolData || !amount) {
				return;
			}
			const marketPriceNumber = Number(amount) * Number(poolData.token_price);
			setTokenMarketPrice(marketPriceNumber.toFixed(2));
		};

		calculateMarketPrice();
	}, [amount, poolData]);
	return {
		amount,
		tokenMarketPrice,
		depositSliderPercentage,
		withdrawSliderPercentage,
		setAssetChanged,
		setDepositSliderPercentage,
		setWithdrawSliderPercentage,
		inputAmountHandler,
		onSlideWithdraw,
		onSlideDeposit,
	};
};
