import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Components
import Homepage from '../containers/pages/Homepage';
import Vaults from '../containers/pages/Vaults';
// import Deposit from '../containers/pages/Deposit';
// import Withdraw from '../containers/pages/Withdraw';

const Routes = () => (
	<Router>
		<Switch>
			<Route exact path='/' component={Homepage} />
			<Route exact path='/social-impact-vaults/' component={Vaults} />
			{/* <Route path='/deposit/:address/:poolId' component={Deposit} /> */}
			{/* <Route path='/withdraw/:address/:poolId' component={Withdraw} /> */}
		</Switch>
	</Router>
);

export default Routes;
