import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Api
import { useQuery } from 'react-query';
import { fetchTokenPrices } from '../../../config/api';

// Theme
import { setTheme } from '../../../shared/utils/theme';

// Context
import { ApiContext } from '../../../context/ApiContext';

// Components
import Link from '../../../shared/components/Link';
import Divider from '../../../shared/components/Divider';

// Icons
import {
	Telegram,
	Discord,
	Twitter,
	LogoSmall,
	LangIcon,
} from '../../../shared/components/Icons';

import SvgMoon from '../../../shared/components/Icons/Moon';

// Styles
import {
	Social,
	FooterWrap,
	Price,
	BotWrap,
	SelectSpan,
	SelectOption,
} from '../style';

const Footer = () => {
	const { data } = useContext(ApiContext);

	// TODO : convert to context
	const { data: marketPrices } = useQuery('marketPrices', fetchTokenPrices, {
		refetchInterval: 300000,
	});

	const { i18n } = useTranslation();
	const [lang, setLang] = useState('English');

	const changeLanguage = (ln: string, name: string) => {
		setLang(name);
		i18n.changeLanguage(ln);
	};

	const toggleTheme = () => {
		if (localStorage.getItem('theme') === 'dark') {
			setTheme('light');
		} else {
			setTheme('dark');
		}
	};

	return (
		<FooterWrap>
			<Divider />
			<Price className='f-price'>
				<Link to={data && data.general.buy_sit} external>
					<LogoSmall style={{ marginRight: '6px' }} />
					<p>${marketPrices && marketPrices.market_prices.SIT}</p>
				</Link>
			</Price>

			<Social className='f-social'>
				<Link
					to='https://t.co/JVt930Xc5o?amp=1'
					external
					style={{ marginRight: '13px' }}
					aria='Telegram'
				>
					<Telegram />
				</Link>
				<Link
					to='https://discord.com/invite/HxhVncqsJX'
					external
					style={{ marginRight: '13px' }}
					aria='Discord'
				>
					<Discord />
				</Link>
				<Link to='https://twitter.com/SINetworkHQ' external aria='Twitter'>
					<Twitter />
				</Link>
			</Social>
			<BotWrap>
				<div className='theme'>
					<button
						aria-label='Switch'
						onClick={toggleTheme}
						type='button'
						className='switch'
					>
						<div className='circle' />
					</button>
					<SvgMoon />
				</div>
				<SelectSpan className='l-select'>
					<SelectOption>
						<LangIcon />
						<p>{lang.substring(0, 2)}</p>
					</SelectOption>
					<div className='popup-wrap'>
						<button
							type='button'
							onClick={() => changeLanguage('en', 'English')}
						>
							English
						</button>
						<button
							type='button'
							onClick={() => changeLanguage('de', 'Deutsch')}
						>
							Deutsch
						</button>
					</div>
				</SelectSpan>
			</BotWrap>
		</FooterWrap>
	);
};

export default Footer;
