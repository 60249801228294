import * as React from 'react';

function SvgDDiscord(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={18}
			height={20}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M11.943 9.5a1.008 1.008 0 01-.22.543 1.053 1.053 0 01-.49.339 1.083 1.083 0 01-.6.023 1.06 1.06 0 01-.515-.3.998.998 0 01-.188-1.104c.082-.182.217-.337.388-.446.171-.108.371-.166.576-.166a1.121 1.121 0 01.761.344 1.06 1.06 0 01.288.767zM7.139 8.389c-.289.02-.56.145-.757.352a1.097 1.097 0 00-.307.759c0 .282.11.553.307.76.198.206.468.331.757.351a1.12 1.12 0 00.762-.344 1.06 1.06 0 00.288-.767 1.032 1.032 0 00-.286-.77 1.088 1.088 0 00-.765-.341h.001zM18 2.06V20c-2.591-2.226-1.762-1.49-4.773-4.21l.546 1.85H2.109a2.145 2.145 0 01-1.493-.606A2.027 2.027 0 010 15.58V2.06A2.027 2.027 0 01.616.606 2.145 2.145 0 012.109 0H15.89c.561.001 1.098.22 1.493.606.396.386.617.909.616 1.454zm-2.931 9.48a13.09 13.09 0 00-1.482-5.83 5.173 5.173 0 00-2.89-1.05l-.144.16c.937.242 1.81.675 2.561 1.27a8.957 8.957 0 00-7.686-.29c-.38.17-.606.29-.606.29a7.018 7.018 0 012.707-1.312l-.1-.118a5.173 5.173 0 00-2.89 1.05 13.09 13.09 0 00-1.484 5.83c.355.484.826.876 1.373 1.14a3.819 3.819 0 001.764.38s.38-.45.69-.83a3.205 3.205 0 01-1.8-1.18c.15.103.4.237.42.25a7.69 7.69 0 006.418.35c.417-.153.814-.35 1.184-.59a3.254 3.254 0 01-1.862 1.19c.309.38.68.81.68.81a3.85 3.85 0 001.767-.38 3.735 3.735 0 001.38-1.14z'
				fill='#474954'
			/>
		</svg>
	);
}

export default SvgDDiscord;
