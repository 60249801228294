import React, { createContext, useState } from 'react';

// Interfaces
interface ContextProps {
	detailsIsOpen: {
		active: number;
	};
	openDetails: any;
	filters: {
		staked: boolean;
		zero: boolean;
		assets: string;
		protocol: string;
		tabs: string;
	};
	setFilters: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export const VaultsContext = createContext<Partial<ContextProps>>({});

const VaultsContextProvider = ({ children }: ProviderProps) => {
	const [detailsIsOpen, setIsOpen] = useState({
		active: -1,
	});

	const [filters, setFilters] = useState({
		staked: false,
		zero: false,
		assets: '',
		protocol: '',
		tabs: 'all',
	});

	function openDetails(itemNum: number) {
		const current = detailsIsOpen.active === itemNum ? -1 : itemNum;
		setIsOpen({ active: current });
	}

	return (
		<VaultsContext.Provider
			value={{ detailsIsOpen, openDetails, filters, setFilters }}
		>
			{children}
		</VaultsContext.Provider>
	);
};

export default VaultsContextProvider;
