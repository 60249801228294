import * as React from 'react';

function SvgDetailsBtnIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={26}
			height={26}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M1 13c0-2.847.007-4.094.249-5.101a9 9 0 016.65-6.65C8.906 1.007 10.153 1 13 1s4.094.007 5.101.249a9 9 0 016.65 6.65C24.993 8.906 25 10.153 25 13s-.007 4.094-.249 5.101a9 9 0 01-6.65 6.65C17.094 24.993 15.847 25 13 25s-4.094-.007-5.101-.249a9 9 0 01-6.65-6.65C1.007 17.094 1 15.847 1 13z'
				fill='transparent'
			/>
			<path
				d='M7 11l6 5 6-5'
				stroke='#2C0641'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M1 13c0-2.847.007-4.094.249-5.101a9 9 0 016.65-6.65C8.906 1.007 10.153 1 13 1s4.094.007 5.101.249a9 9 0 016.65 6.65C24.993 8.906 25 10.153 25 13s-.007 4.094-.249 5.101a9 9 0 01-6.65 6.65C17.094 24.993 15.847 25 13 25s-4.094-.007-5.101-.249a9 9 0 01-6.65-6.65C1.007 17.094 1 15.847 1 13z'
				stroke='#2C0641'
				strokeWidth={2}
			/>
		</svg>
	);
}

export default SvgDetailsBtnIcon;
