import React from 'react';

// Styles
import { ConnectBtn, DisconnectBtn } from '../style';

interface OnboardProps {
	functionHandler: any;
	buttonText: string;
	connected: boolean;
}

const Onboard: React.FC<OnboardProps> = ({
	functionHandler,
	buttonText,
	connected,
}: OnboardProps): any =>
	!connected ? (
		<ConnectBtn className='o-btn' onClick={functionHandler}>
			{buttonText}
		</ConnectBtn>
	) : (
		<DisconnectBtn className='o-btn connected' onClick={functionHandler}>
			{buttonText}
		</DisconnectBtn>
	);

export default Onboard;
