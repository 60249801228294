import * as React from 'react';

function SvgCloseIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={19}
			height={19}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.983.86A1.5 1.5 0 00.86 2.983l6.445 6.445L.86 15.87a1.5 1.5 0 102.122 2.122l6.444-6.445 6.445 6.445a1.5 1.5 0 102.121-2.122L11.55 9.427l6.444-6.445A1.5 1.5 0 0015.872.861L9.427 7.306 2.983.86z'
				fill='#430E60'
			/>
		</svg>
	);
}

export default SvgCloseIcon;
