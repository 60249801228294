import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCategory } from './useCategory';

const Index = ({ setFilter }: any) => {
	const { t } = useTranslation();
	const { handleCatFilter, active } = useCategory();

	return (
		<div className='cat-filter'>
			<button
				type='button'
				onClick={() => handleCatFilter(setFilter, 'all')}
				className={active === 'all' ? 'active' : ''}
			>
				{t('all', 'All')}
			</button>
			<button
				type='button'
				onClick={() => handleCatFilter(setFilter, 'lending')}
				className={active === 'lending' ? 'active' : ''}
			>
				{t('lending', 'Lending')}
			</button>
			<button
				type='button'
				onClick={() => handleCatFilter(setFilter, 'farming')}
				className={active === 'farming' ? 'active' : ''}
			>
				{t('farming', 'Farming')}
			</button>
			<button
				type='button'
				onClick={() => handleCatFilter(setFilter, 'staking')}
				className={active === 'staking' ? 'active' : ''}
			>
				Social Impact
			</button>
		</div>
	);
};

export default Index;
