import styled from 'styled-components';
import { font } from '../../styles/variables';

export const ModalWrap = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	.top-close {
		position: absolute;
		right: 0;
		top: 0;
	}

	.bot-close {
		padding: 14px 57px;
		font-size: 14px;
		${font.medium}
		color: var(--darkDirtGray);
		border: 2px solid var(--orange);
		border-radius: 10px;
	}
`;
