import styled from 'styled-components';

export const LoadWrap = styled.div`
	width: 100vw;
	height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LogoWrap = styled.div`
	position: relative;
	width: 84px;
	height: 104px;
	display: flex;
	flex-direction: column;

	svg {
		width: 100%;
		height: 100%;
	}

	@keyframes changeBottom {
		from {
			bottom: -30%;
		}

		to {
			bottom: 100%;
		}
	}

	.l-overlay {
		position: absolute;
		width: 86px;
		height: 150px;
		left: 0;
		bottom: 0;
		background: linear-gradient(
			180deg,
			rgba(196, 196, 196, 0) 0%,
			var(--background) 0.01%,
			rgba(196, 196, 196, 0) 100%
		);
		animation-duration: 1.5s;
		animation-name: changeBottom;
		animation-iteration-count: infinite;
		animation-direction: alternate;
	}
`;
