import React from 'react';
import { Wrap } from './style';

const ToolTip = ({
	icon,
	children,
	right,
	top,
	bottom,
	left,
	transform,
}: // pointerBot,
any) => (
	<Wrap>
		{icon}
		{/* {!pointerBot ? (
			<div className='pointer' />
		) : (
			<div className='pointer-bot' />
		)} */}
		<div className='reveal' style={{ right, top, bottom, left, transform }}>
			{children}
		</div>
	</Wrap>
);

export default ToolTip;
