import React from 'react';

import { useGetPoolData } from '../../../../../shared/utils/helpers';

// Components
import Withdraw from './Withdraw';

// Interfaces
import { IVaults } from '../../../../../@types/interfaces';

interface IDeposit {
	data: IVaults;
	dData: {
		pool_id: number;
		want_address: string;
		wantName: string;
	};
}

const Index = ({ data, dData }: IDeposit) => {
	const { poolData, setAsset } = useGetPoolData(data, dData);
	return (
		<>
			{poolData ? (
				<Withdraw dData={data} data={{ poolData }} hooks={{ setAsset }} />
			) : (
				<p>Loading</p>
			)}
		</>
	);
};

export default Index;
