import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { VaultsContext } from '../context/VaultsContext';

// Filter functions
import { depositedFilter } from '../filters/StakedOnly/useStaked';
import { balanceFilter } from '../filters/ZeroBalance/useZeroBalance';
import { categoryFilter } from '../filters/Tabs/useCategory';
import { assetsFilter } from '../filters/Assets/useAssets';
import { protocolFilter } from '../filters/Protocol/useProtocol';

// Helpers
import { formatPriceNumber } from '../../../../shared/utils/helpers';
import { useMobleDetect } from '../../../../shared/utils/hooks/useMobileDetect';

// Components
import ModalC from '../../../../shared/components/Modal';
import APYCalculator from '../components/APYCalculator';

// Icons
import ApiCalculatorIcon from '../../../../shared/components/Icons/ApiCalculatorIcon';
import {
	DetailsBtnIcon,
	DetailsBtnIconOpen,
} from '../../../../shared/components/Icons';

// Interfaces
import { IVault } from '../../../../@types/interfaces';

const useColumns = (forceRerender: any) => {
	const { t } = useTranslation();
	const { isMobile } = useMobleDetect();
	const { detailsIsOpen, openDetails } = useContext(VaultsContext);

	return {
		vaultsColumns: React.useMemo(
			() => [
				{
					Header: 'Vaults',
					id: 'vaults',
					columns: [
						{
							Header: t('assets', 'Assets'),
							accessor: 'wantName',
							filter: assetsFilter,
						},
						{
							Header: t('protocol', 'Protocol'),
							accessor: 'protocol',
							filter: protocolFilter,
						},
						{
							Header: t('apy', 'APY / daily'),
							accessor: (data: IVault) => (
								<div
									className='wrapper'
									style={{ display: 'flex', alignItems: 'center' }}
								>
									{!isMobile ? (
										<>
											<div className='apy'>
												<p>{data.apy_total.toFixed(2)}%</p>
												<p>{(Number(data.apy_total) / 365).toFixed(2)}%</p>
											</div>
											<ModalC
												btnChild={<ApiCalculatorIcon />}
												modalBody={<APYCalculator data={data} />}
												doubleClose
											/>
										</>
									) : (
										<div className='apy'>
											<div>
												<p className='title'>APY</p>
												<p className='a-value'>{data.apy_total}%</p>
											</div>
											<ModalC
												btnChild={<ApiCalculatorIcon />}
												modalBody={<APYCalculator data={data} />}
												doubleClose
											/>
											<div>
												<p className='title'>Daily</p>
												<p className='d-value'>
													{(Number(data.apy_total) / 365).toFixed(2)}%
												</p>
											</div>
										</div>
									)}
								</div>
							),
						},
						{
							Header: t('deposited', 'Deposited'),
							id: 'poolWallet.deposited',
							accessor: (data: IVault) => (
								<div className='wrapper'>
									<div className='deposited'>
										{forceRerender && data.poolWallet?.deposited ? (
											<div>
												<p>
													{formatPriceNumber(data.poolWallet.deposited)}{' '}
													{data.poolWallet.asset}
												</p>
												<p>
													$
													{formatPriceNumber(
														Number(data.poolWallet.balance) *
															Number(data.token_price)
													)}
												</p>
											</div>
										) : (
											'-'
										)}
									</div>
								</div>
							),
							filter: depositedFilter,
						},
						{
							Header: t('tvl', 'TVL'),
							accessor: (data: IVault) => (
								<div className='wrapper'>
									<div className='tvl'>
										{isMobile && <p className='title'>TVL</p>}
										<p> ${formatPriceNumber(data.tvl)}</p>
									</div>
								</div>
							),
						},
						{
							Header: t('details', 'Details'),
							accessor: (data: IVault) => (
								<div className='wrapper'>
									<button type='button' onClick={() => openDetails(data.id)}>
										{data.id === detailsIsOpen?.active ? (
											<DetailsBtnIconOpen className='i-open' />
										) : (
											<DetailsBtnIcon />
										)}
									</button>
								</div>
							),
						},
						// Needs to be defined because of tabs/category filters
						{
							Header: 'Category',
							accessor: 'categoryName',
							filter: categoryFilter,
						},
						{
							Header: 'Balance',
							id: 'poolWallet.balance',
							accessor: () => <div className='wrapper' />,
							filter: balanceFilter,
						},
						{
							Header: 'Info',
							accessor: () => <div className='wrapper' />,
						},
					],
				},
			],
			[t, forceRerender, openDetails, detailsIsOpen, isMobile]

			// [t, harvestHandler, signer]
		),
	};
};

export default useColumns;
