import React from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import { InfoWrap } from './style';

const InfoToolTip = () => {
	const { t } = useTranslation();

	return (
		<InfoWrap>
			<div className='info-item'>
				<p className='title'>{t('asset', 'Asset')}</p>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam.
				</p>
			</div>
			<div className='info-item'>
				<p className='title'>{t('protocol', 'Protocol')}</p>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam.
				</p>
			</div>
			<div className='info-item'>
				<p className='title'>{t('apy', 'APY')}</p>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam.
				</p>
			</div>
			<div className='info-item'>
				<p className='title'>{t('deposited', 'Deposited')}</p>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam.
				</p>
			</div>
			<div className='info-item'>
				<p className='title'>{t('tvl', 'TVL')}</p>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam.
				</p>
			</div>
		</InfoWrap>
	);
};

export default InfoToolTip;
