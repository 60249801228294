import styled, { createGlobalStyle } from 'styled-components';
import { color, device, font } from './variables';

// Icons
import BottomArrow from '../../assets/icons/arrow-bottom-white.svg';
import SearchIcon from '../../assets/icons/search-white.svg';

export const Container = styled.div`
	width: 100%;
	padding: 0 14px;
	margin-top: 16px;
	flex: 1;
	margin-left: 256px;
	position: relative;

	.overlay {
		position: absolute;
		height: 100%;
		top: 0;
		z-index: 2;
		background-color: rgba(0, 0, 0, 0.2);
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: opacity 0.25s;

		&.true {
			pointer-events: auto;
			opacity: 1;
			visibility: visible;
		}
	}

	@media (min-width: 1200px) {
		padding: 0;
	}

	@media ${device.tabletL} {
		margin-left: 0;
		margin-top: 0;
		padding: 0 12px;
	}

	@media ${device.mobileL} {
		padding: 0;
	}
`;

export const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 1140px;
	margin: 0 auto;
	margin-top: 75px;

	@media (max-width: 1411px) {
		max-width: 1000px;
	}

	@media (max-width: 1290px) {
		max-width: 900px;
	}

	@media ${device.tabletL} {
		max-width: 100%;
		margin-top: 69px;
	}
`;

interface ICol {
	width?: string;
}

export const Col = styled.div<ICol>`
	flex: 0 0 ${(props) => (props.width ? props.width : '100%')};
	width: ${(props) => (props.width ? props.width : '100%')};
	max-width: 100%;
	position: relative;
	padding-right: 14px;
	padding-left: 14px;

	h2 {
		font-size: 22px;
	}

	@media ${device.tabletL} {
		/* padding: 0; */
	}
`;

export const Flex = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100%;

	@media ${device.mobileL} {
		height: auto;

		&.d-wrap {
			padding: 75px 20px 32px 20px;
			box-sizing: border-box;
		}
	}

	@media ${device.mobileM} {
		&.d-wrap {
			padding: 75px 0 0 0;
		}
	}
`;

export default createGlobalStyle`
	body[data-theme='light'] {
		--text: ${color.light.text};
		--background: ${color.light.background};
		--white: ${color.light.white};
		--coal: ${color.light.coal};
		--lightCoal:${color.light.lightCoal};
		--ash: ${color.light.ash};
		--primary0: ${color.light.primary0};
		--primary1: ${color.light.primary1};
		--primary2: ${color.light.primary2};
		--primary4: ${color.light.primary4};
		--primary5: ${color.light.primary5};
		--secondary1: ${color.light.secondary1};
		--secondary2: ${color.light.secondary2};
		--tertiary1: ${color.light.tertiary1};
		--darkDirtGray: ${color.light.darkDirtGray};
		--dirtGray: ${color.light.dirtGray};
		--lightDirtGray: ${color.light.lightDirtGray};
		--superLightDirt: ${color.light.superLightDirt};
		--lightMuteGray: ${color.light.lightMuteGray};
		--darkMuteGray: ${color.light.darkMuteGray};
		--border: ${color.light.border};
		--danger: ${color.light.danger};
		--icon: ${color.light.icon};
		--linkColor: ${color.light.linkColor};
		--orange: ${color.light.orange};
		--purple: ${color.light.purple};
		--lightOrange: ${color.light.lightOrange};
	}

	body[data-theme='dark'] {
		--text: ${color.dark.text};
		--background: ${color.dark.background};
		--white: ${color.dark.white};
		--coal: ${color.dark.coal};
		--lightCoal:${color.dark.lightCoal};
		--ash: ${color.dark.ash};
		--primary0: ${color.dark.primary0};
		--primary1: ${color.dark.primary1};
		--primary2: ${color.dark.primary2};
		--primary4: ${color.dark.primary4};
		--primary5: ${color.dark.primary5};
		--secondary1: ${color.dark.secondary1};
		--secondary2: ${color.dark.secondary2};
		--tertiary1: ${color.dark.tertiary1};
		--darkDirtGray: ${color.dark.darkDirtGray};
		--dirtGray: ${color.dark.dirtGray};
		--lightDirtGray: ${color.dark.lightDirtGray};
		--superLightDirt: ${color.dark.superLightDirt};
		--lightMuteGray: ${color.dark.lightMuteGray};
		--darkMuteGray: ${color.dark.darkMuteGray};
		--border: ${color.dark.border};
		--danger: ${color.dark.danger};
		--icon: ${color.dark.icon};
		--linkColor: ${color.dark.linkColor};
		--orange: ${color.dark.orange};
		--purple: ${color.dark.purple};
		--lightOrange: ${color.light.lightOrange};
	}

	html, body, #root {
		height: 100%;
		min-height: 100%;
		margin: 0;
		overflow: hidden;

		&.ReactModal__Body--open {
			.ReactModalPortal > * {
				opacity: 1
			}
		}
	}

	body {
		color:  var(--text);
		-webkit-tap-highlight-color: transparent;
		font-size: 100%;
		${font.regularPoppins};
		background-color: var(--background);
	}

	#root {
		overflow-y:auto;
		

		main {
			display: flex;
			flex: 1;
			padding-bottom:100px;

			@media ${device.tabletL} {
				padding-bottom:16px;
			}
		}
	}


	*, *:after, *:before, input[type="search"] {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	a,button {
		display: inline-block;
		text-decoration: none;
		color: inherit;
		transition: opacity .25s;
		font-size: inherit;
		cursor: pointer;

		&:hover {
			opacity: .7;
		}
	}

	.disabled {
		pointer-events: none;
		cursor:unset;
		opacity: .5;
	}

	ul {
		list-style: none;
	}

	ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
		padding: 0;
		margin: 0;
	}

	button {
		background: none;
		border: none;
		${font.medium};
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		${font.semibold};
	}

	h1 {
		font-size: 52px;
		line-height: 78px;
	}

	h2 {
		font-size: 42px;
		line-height: 41px;
	}

	h3 {
		font-size: 32px;
		line-height: 37px;
		margin-bottom: 24px;
	}

	h4 {
		font-size: 22px;
		line-height: 30px;
	}

	h5 {
		font-size: 18px;
		line-height: 24px;
	}

	p {
		font-size: 16px;
		line-height: 21px;
		${font.regularNotoSans};
		color: var(--dirtGray);
	}

	a {
		display:block;
		font-size: 14px;
		line-height: 21px;
		${font.medium};
	}

	
	select,
	option {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}


	.aside-main {
		height: 100vh;
		max-width: 265px;
		padding-top: 75px;
		position: fixed;
		top: 0;
		left: 0;
		transition: left .25s ease-out;

		@media ${device.tabletL} {
			padding-top:53px;
			left: -100%;
			z-index:3;
			box-shadow: 0px 4px 8px rgb(0 0 0 / 4%);

			&.true {
				left:0;
			}

			.m-drop {
				display:block;
				position:relative;
				top:unset;
				right:unset;
				background-color:transparent;
				box-shadow:none;
				width: 100%;
			}
		}

		
	}

	.bn-onboard-custom {
		z-index: 1;
	}

	.a-wrapper {
		display: flex;
	}

	.plus,
	.minus {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 26px;
		height: 26px;
		border-radius: 10px;
		margin-right: 8px;
	}

	.nav-link {
		width: 100%;
		opacity: 1;
	}

	/* Workaround for IE11 focus highlighting for select elements */
	select::-ms-value {
		background: none;
		color: #42413d;
	}

	[role="button"], button, input, select, textarea {
		outline: none;
		&:focus {
			outline: none;
		}
		&:disabled {
			opacity: .5;
		}
	}

	[role="button"], button, textarea {
		appearance: none;
	}

	select:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #000;
	}

	select::-ms-expand {
		display: none;
	}

	


	.ReactModal__Overlay {
		cursor:pointer
	}

	.ReactModalPortal {

		.ReactModal__Overlay {
			z-index: 9999;
			background-color: rgba(0, 0, 0, 0.4) !important;
		}

		.ReactModal__Content {
			box-shadow: 0px 3px 6px #0000000F;
			/* border: 1px solid #DADCE0 !important; */
			border: 0 !important;
			border-radius: 8px !important;
			cursor: initial;
			background-color:var(--white) !important;

			h1 {
				margin-bottom: 32px;
			}

			h2 {
				margin-bottom: 16px;
				font-size: 20px;
				line-height: 26px;
			}

			p {
				margin-bottom: 32px;

			}

			@media ${device.tabletL} {
				transform: translate(-50%, -50%) scale(0.87) !important;
			}

			@media (max-height: 850px) {
				transform: translate(-50%, -50%) scale(0.87) !important;
			}

			@media (min-height: 1020px) {
				transform: translate(-50%, -50%) scale(1) !important;
			}

			@media ${device.mobileL} {
				padding:24px !important;
				transform: translate(-50%, -50%) scale(0.83) !important;

				h1 {
					font-size:22px;
				}
			}

			@media ${device.mobileM} {
				transform: translate(-50%, -50%) scale(0.75) !important;
			}

			@media ${device.mobileS} {
				transform: translate(-50%, -50%) scale(0.63) !important;
			}
		}

		& > * {
			opacity: 0;
		}

		@media ${device.mobileL} {
			.ReactModal__Content.m-modal {
				position: absolute;
				top: 50% !important;
				left: 50% !important;
				right: auto !important;
				bottom: auto !important;
				margin-right: -50% !important;
				transform: translate(-50%, -50%) !important;
				padding: 0 !important;
				background-color:white;
				box-sizing:border-box;
				height:100%;
				width:100%;
				overflow-y:scroll;
				border-radius: 0 !important;

				.top-close {
					top: 14px;
					right: 20px;
				}
			}
		}
	}

	.overlay {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0, .6);
		z-index: 999;
		cursor: pointer;
		transition: opacity 200ms ease-in-out;

		&.ReactModal__Overlay--after-open {
				opacity: 1;
		}

		&.ReactModal__Overlay--before-close {
				opacity: 0;
		}
	}

	body, select {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html {
		touch-action: manipulation;
	}

	select {
		cursor: pointer;
	}

	.social-impact-vaults {
		height:100%;
		padding-bottom:0 !important;

		@media ${device.tabletL} {
			${Row} {
				margin-top: 53px;
			}

			${Container} {
				padding: 0;
				height:100%;
			}
		}
	}

	// Dark mode forced styles
	body[data-theme='dark'] {

		// General
		.bold {
			color: var(--text);
		}

		.o-btn {
			color: var(--darkDirtGray);

			&:hover {
				color: var(--linkColor);
			}
		}

		.logo {
			path:nth-of-type(2) {
				fill: var(--primary1);
			}
		}

		.logo svg {
			path {
				fill: var(--primary1);

				&:nth-of-type(2) {
					fill: rgb(255, 195, 0);
				}
			}
		}

		.icon-wrap.true svg path {
			fill: var(--text);
		}

		.info-wrap .info .calculator,.cell:nth-of-type(3) button {
			svg path {
				fill: var(--primary1);
			}
		}

		.calculator button,
		.a-wrap a {
			color: var(--primary1) !important;
		}

		.cell:nth-of-type(6) button {
			svg path {
				stroke: var(--primary1);
			}
		}

		.dc {
			background-color: var(--primary4);

			.divider {
				background-color: var(--coal);
			}
		}

		.aside-main .dc {
			background-color: transparent;

			.divider {
				background-color: var(--darkMuteGray);
			}
		}

		.b-wrap a {
			background-color:transparent;
		}

		.d-head, .open {
			background-color: var(--darkMuteGray);
		}

		.i-open {
			path {
				fill: transparent;

				&:nth-of-type(1) {
					fill: var(--primary1);;
				}

				&:nth-of-type(2) {
					stroke: var(--text) !important;
					
				}
			}
		}

		@media(max-width: 525px) {
			.cell:nth-of-type(5) button {
				svg path {
					stroke: var(--primary1);
				}
			}
		}

		.a-select::before,.p-select::before ,.l-select::before  {
			background: url(${BottomArrow}) no-repeat center center;
		}

		.search::before {
			background: url(${SearchIcon}) no-repeat center center;
		}

		.f-price {
			svg path {
			fill: var(--text);
			}

			p {
				color:var(--text);
			}
		}

		.f-social {
			a svg path {
				fill : var(--text);
			}
		}

		.switch .circle {
			left: calc(100% - 22px);
		}

		.theme {
			svg path {
				fill: var(--primary2) !important;
			}
		}

		.l-select {
			p,
			.popup-wrap button {
			color: var(--text);
			}
		}

		.top-close svg path {
			fill: var(--text) !important;
		}

		.info {
			
			svg {
				circle {
					stroke: var(--text);
				}

				path {
					fill: var(--text);
				}
			}
		}

		.active {
			color: var(--primary1);
		}

		.cat-filter button.active {
			color: var(--text);
		}

		.title a {
			color: var(--text);
		}

		button.c-button, 
		.d-wrap button {
			color: var(--darkDirtGray) !important;
		}

		.info-tooltip svg circle,
		.nav-items a.active svg path {
			fill: var(--primary1);
		}

		// Nav
		.nav-items {
			a {
				color: var(--coal);

				&.active {
					color: var(--primary1);
					border-color: var(--primary1);
				}
			}
		}

		.nav-items li:active a{
			color: var(--text);

			svg path {
				fill: var(--text);
			}
		}

		.m-super {
			color: var(--primary1) !important;
		}

		input[type="checkbox" i],
		select,
		.search {
			border-color : var(--coal);
		}

		.search {
			color: var(--coal);

			input::placeholder {
				color: var(--coal);
			}
		}

		.b-wrap {
			background-color:var(--primary5);

			p {
				color: var(--text);
			}
		}

		.apy, .tvl, .deposited {
			p {
				color: var(--coal) !important;
			}
		}

		table .row,
		.a-wrap {
			color: var(--coal) !important;
		}

		.personal, .fees {
			p {
				color: var(--dirtGray);
			}

			.wrapper {
				.item .token,
				.impact {
					color: var(--text);
				}
			}
		}

		.ReactModalPortal {
			 .info {
			border-color: var(--text);

				p {
					color: var(--text);
				}
			}

			input[type="text" i] {
				border-color: var(--text);
			}

			.range-slider .rc-slider-step .rc-slider-dot {
				border-color: #E0E0E0;
				background:  #E0E0E0;
			}

			div > p {
				color: var(--text) !important;
			}

			.protocol, .tvl, .apy, .deposited, .fees{
				p {
					color: var(--dirtGray) !important;

					&:first-of-type {
						color: var(--text) !important;
					}
				}
			}
		}
	}


	@media ${device.mobileL} {
		section.svelte-rntogh {
			transform:scale(0.9);
		}
	}

`;
