import React, { useEffect, useContext, useState } from 'react';

// Ethers connection to blockchain
import { ethers } from 'ethers';

// API context
import { ApiContext } from '../../../context/ApiContext';
import { OnboardContext } from '../../../context/OnboardContext';

import Onboard from './Onboard';

import getSigner from '../../../ethers/signer';

// BC util functions
import {
	getDataFromBlockchain,
	switchToBSCTestnet,
	switchToBSCMainnet,
} from '../../../ethers/utils';

// Initializes onboard
import { initOnboard } from '../../../ethers/services';

// enum
import { NetworkID } from '../../../ethers/enum';

// On wallet select
export const walletSelectHandler = async (onboard: any) => {
	if (!onboard) {
		throw new Error('No onboard found!');
	}

	if (await onboard.walletSelect()) {
		await onboard.walletCheck();
	}
};

const Index = () => {
	const [newBlock, setNewBlock] = useState<any>();
	// Api
	const { data, setWalletInfo } = useContext(ApiContext);
	const {
		wallet,
		setWallet,
		walletAddress,
		setWalletAddress,
		network,
		setNetwork,
		setBalance,
		onboard,
		setOnboard,
		provider,
		setProvider,
		setSigner,
		onboardConnected,
		setOnboardConnected,
	} = useContext(OnboardContext);

	// Initialize Onboard
	useEffect(() => {
		const onboardSettings = initOnboard({
			address: setWalletAddress,
			network: setNetwork,
			balance: setBalance,
			wallet: async (walletInterface: any) => {
				if (walletInterface.provider) {
					// creates ethers provider
					const ethersProvider = new ethers.providers.Web3Provider(
						walletInterface.provider,
						'any'
					);

					// Handles network change
					ethersProvider.on('network', (newNetwork, oldNetwork) => {
						if (oldNetwork) {
							window.location.reload();
						}
					});

					setWallet(walletInterface);
					setProvider(ethersProvider);
					setSigner(getSigner(ethersProvider));
					setOnboardConnected(true);
					window.localStorage.setItem('selectedWallet', walletInterface.name);
				} else {
					setProvider(null);
				}
			},
		});
		setOnboard(onboardSettings);
	}, [
		setWallet,
		setProvider,
		setWalletAddress,
		setNetwork,
		setOnboard,
		setBalance,
		setSigner,
		setOnboardConnected,
	]);

	// listens to new mined block
	if (provider) {
		provider.on('block', (block: any) => {
			setNewBlock(block);
		});
	}

	// Load previously selected wallet
	useEffect(() => {
		const previouslySelectedWallet =
			window.localStorage.getItem('selectedWallet');
		if (previouslySelectedWallet && onboard) {
			onboard.walletSelect(previouslySelectedWallet);
		}
		setOnboardConnected(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		onboard,
		provider,
		setWalletInfo,
		walletAddress,
		network,
		wallet,
		onboardConnected,
		setOnboardConnected,
	]);

	// Load data from the blockchain
	useEffect(() => {
		if (
			onboard &&
			network === NetworkID.Selected &&
			data &&
			provider &&
			walletAddress
		) {
			(async () => {
				const blockchainData = await getDataFromBlockchain(
					walletAddress,
					data,
					provider
				);

				setWalletInfo(blockchainData);
			})();
		} else {
			setWalletInfo(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		network,
		walletAddress,
		provider,
		onboard,
		newBlock,
		wallet,
		onboardConnected,
		setWalletInfo,
		data?.general,
	]);

	// THIS USE EFFECT IS FOR SWITCHING BLOCKCHAIN NETWORKS
	// IT CURRENTLY ONLY SUPPORTS NETWORKS THAT ARE NOT DEFAULT IN METAMASK SUCH AS (Binance smart chain...)
	useEffect(() => {
		if (provider) {
			(async () => {
				// @ts-ignore
				if (NetworkID.Selected === 97) {
					await switchToBSCTestnet(provider);
				}
				// @ts-ignore
				if (NetworkID.Selected === 56) {
					await switchToBSCMainnet(provider);
				}
			})();
		}
	}, [provider]);

	// resets wallets and reloads the page
	const walletResetHandler = async () => {
		if (!onboard) {
			throw new Error('No onboard found!');
		}
		setOnboardConnected(false);
		setWalletInfo();
		onboard.walletReset();
		window.localStorage.removeItem('selectedWallet');
	};

	return !wallet || !walletAddress ? (
		<Onboard
			functionHandler={() => walletSelectHandler(onboard)}
			buttonText='Connect Wallet'
			connected={false}
		/>
	) : (
		<Onboard
			functionHandler={() => walletResetHandler()}
			// show only the first four and the last 2 characters of the address
			buttonText={`${walletAddress.substring(
				0,
				4
			)} ... ${walletAddress.substring(36, 42)}`}
			connected
		/>
	);
};

export default Index;
