import React from 'react';

/* eslint @typescript-eslint/ban-types: 0 */

// Styles
import { Wrap } from './style';

// Interfaces
export interface IDivider {
	children?: JSX.Element | JSX.Element[] | React.ReactNode;
	small?: Boolean;
	yellowline?: Boolean;
}

const Divider = ({ children, small, yellowline }: IDivider) => (
	<Wrap
		style={{
			backgroundColor: yellowline && '#FEDC66',
		}}
		className={small && 'small'}
	>
		{children}
	</Wrap>
);

export default Divider;
