import styled from 'styled-components';
import { device, font } from '../../../../../shared/styles/variables';

export const HeadingWrap = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 21px;

	@media ${device.mobileL} {
		position: fixed;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
		padding: 14px 20px;
		top: 0;
		left: 0;
		width: 100%;
		pointer-events: none;

		h5 {
			width: 100%;
			text-align: left;
		}
	}
`;

export const MaxInput = styled.input`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 100%;
	padding: 0 14px;
	height: 50px;
	${font.regularNotoSans};
	font-size: 14px;
	line-height: 25px;
	border: 2px solid var(--darkMuteGray);
	border-radius: 10px;
	background-color: transparent;
	color: var(--dirtGray);

	::placeholder {
		color: var(--dirtGray);
	}
`;

export const MaxWrap = styled.div`
	display: flex;
	margin-bottom: 18px;
	position: relative;
	padding: 0 25px;

	p.price {
		margin-bottom: 0;
		position: absolute;
		top: 15px;
		right: 45px;
		color: var(--ash);
	}

	button {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		${font.regularNotoSans};
		font-weight: 500;
		font-size: 18px;
		color: var(--primary1);
	}

	@media ${device.mobileL} {
		padding: 0;
	}

	@media ${device.mobileM} {
		padding: 0 20px;
	}
`;

export const BtnsWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 25px;

	button {
		padding: 14px 50px;
		font-size: 14px;
		border-radius: 10px;

		&:nth-of-type(1) {
			border: 2px solid var(--secondary1);
			background-color: transparent;
			z-index: 1;
			margin-right: 20px;
			color: var(--text);
		}

		&:nth-of-type(2) {
			border: 2px solid transparent;
			background-color: var(--primary1);
			/* background-color: rgba(37, 82, 115, 0.3); */
			color: var(--white);
		}
	}

	@media ${device.mobileL} {
		justify-content: space-between;

		button {
			padding: 6px 50px;

			&:nth-of-type(1) {
				margin-right: 0;
			}

			&:nth-of-type(2) {
				margin-left: 0;
			}
		}
	}

	@media ${device.mobileM} {
		padding: 0 20px;

		button {
			padding: 6px 40px;
		}
	}
`;

export const DWrap = styled.div`
	margin-bottom: 40px;
	padding: 0 25px;

	div > p {
		margin-bottom: 0 !important;
	}

	.deposit {
		display: flex;
		justify-content: space-between;

		.d-wrap {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			p {
				color: var(--coal);
				text-transform: uppercase;

				&:nth-of-type(2) {
					color: var(--ash);
				}
			}
		}
	}

	@media ${device.mobileL} {
		padding: 0;
	}

	@media ${device.mobileM} {
		padding: 0 20px;
	}
`;

export const AWrap = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 0 25px;

	p {
		margin-bottom: 0 !important;
		color: var(--coal);

		&:nth-of-type(1) {
			margin-right: 8px;
			color: var(--dirtGray);
		}

		&.title {
			text-transform: uppercase;
			color: var(--lightCoal);
			font-size: 12px;
			font-weight: 700;
		}
	}

	.available {
		display: flex;
	}

	@media ${device.mobileL} {
		padding: 0;
	}

	@media ${device.mobileM} {
		padding: 0 20px;
	}
`;

export const SliderWrap = styled.div`
	margin-top: 27px;
	margin-bottom: 18px;
	padding: 0 30px 0 25px;

	.range-slider {
		.rc-slider-rail,
		.rc-slider-track,
		.rc-slider-step {
			height: 6px;
		}

		span {
			color: var(--dirtGray);
		}

		.rc-slider-mark-text-active {
			color: var(--primary1);
		}

		.rc-slider-track {
			background-color: var(--primary1);
		}

		.rc-slider-handle {
			border: 2px solid var(--primary1);
			box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
		}

		.rc-slider-step {
			.rc-slider-dot {
				width: 16px;
				height: 16px;
				bottom: -5px;
				margin-left: -8px;
				border: 2px solid var(--darkMuteGray);
				background: var(--darkMuteGray);

				&:first-of-type {
					display: none;
				}

				&.rc-slider-dot-active {
					border: 2px solid var(--primary1);
					background: var(--primary1);
				}
			}
		}

		.rc-slider-mark {
			font-size: 16px;
			color: var(--dirtGray);

			span {
				&:first-of-type {
					left: 10px !important;
				}

				&:last-of-type {
					left: calc(100% - 10px) !important;
				}
			}
		}
	}

	.rc-slider-handle {
		width: 18px;
		height: 18px;
		margin-top: -6px;
	}

	@media ${device.mobileL} {
		padding: 0 10px 0 5px;

		& + div {
			padding: 0 !important;
		}
	}

	@media ${device.mobileM} {
		padding: 0 30px 0 25px;
	}
`;

export const InfoTitle = styled.p`
	${font.regularNotoSans};
	font-size: 16px;
	line-height: 22px;
	color: var(--text);
	margin-bottom: 6px;
`;

export const DropdownWrap = styled.div`
	padding: 0 25px;

	.d-drop-wrap {
		&:before {
			top: 9px;
			right: 30px;
		}
	}

	.d-drop {
		text-transform: uppercase;
		padding: 0 24px;
		height: 50px;
		font-size: 14px;
	}

	p.title {
		margin-bottom: 10px !important;
		text-transform: uppercase;
		color: var(--lightCoal);
		font-size: 12px;
		font-weight: 700;
	}

	.info {
		margin-top: 20px;
		margin-bottom: 27px;
		padding: 20px 15px;
		border: 2px solid var(--primary1);
		border-radius: 10px;
		font-size: 13px;
		color: var(--primary1);
		background-color: var(--primary5);
		display: flex;
		align-items: center;

		svg {
			margin-right: 10px;
		}

		p {
			margin-bottom: 0 !important;
			font: inherit;
			color: inherit;
			margin: 0;
		}
	}

	@media ${device.mobileL} {
		padding: 0;

		.info {
			svg {
				width: 10%;
			}
		}
	}

	@media ${device.mobileM} {
		padding: 0 20px;

		.info {
			svg {
				width: 14%;
			}
		}
	}

	@media ${device.mobileS} {
		.info {
			svg {
				width: 20%;
			}
		}
	}
`;

export const InfoWrap = styled.div`
	display: flex;
	margin-bottom: 50px;
	padding: 0 25px;

	div {
		margin-right: 44px;

		a {
			color: var(--primary1);
			text-decoration: underline;
		}

		&:last-of-type {
			margin-right: 0;
		}

		p {
			margin-bottom: 6px !important;
			color: var(--lightDirtGray);

			&:nth-of-type(1),
			&:nth-of-type(2) {
				color: var(--text);
			}
		}
	}

	@media ${device.mobileL} {
		margin-bottom: 30px;
		padding: 0;
		flex-direction: column;

		div {
			margin-bottom: 20px;
			margin-right: 0;

			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				display: inline-flex;
			}
		}
	}

	@media ${device.mobileM} {
		padding: 0 20px;
	}
`;
