import * as React from 'react';

function SvgSortArrowIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={10}
			height={6}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M5.756 5.127a1 1 0 01-1.512 0L1.237 1.655A1 1 0 011.993 0h6.014a1 1 0 01.756 1.655L5.756 5.127z'
				fill='#474954'
			/>
		</svg>
	);
}

export default SvgSortArrowIcon;
