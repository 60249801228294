import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	useSortBy,
	useTable,
	useFilters,
	usePagination,
	useGlobalFilter,
	useAsyncDebounce,
} from 'react-table';

// Context
import { VaultsContext } from '../context/VaultsContext';

// Helpers
import { useMobleDetect } from '../../../../shared/utils/hooks/useMobileDetect';

// Icons
import {
	TokenIcon,
	InfoIcon,
	WarningIcon,
	LpTokenIcon,
	HeartIcon,
	FilterIcon,
	SortArrowIcon,
} from '../../../../shared/components/Icons';

// Components
import Banner from '../components/Banner';
import Details from '../components/Details';
import InfoToolTip from '../components/InfoToolTip';
import Button from '../../../../shared/components/Button';
import ToolTip from '../../../../shared/components/ToolTip';

// Filters
import StakedOnly from '../filters/StakedOnly';
import ZeroBalance from '../filters/ZeroBalance';
import Tabs from '../filters/Tabs';
import Assets from '../filters/Assets';
import Protocol from '../filters/Protocol';
import { useStaked } from '../filters/StakedOnly/useStaked';
import { useZeroBalance } from '../filters/ZeroBalance/useZeroBalance';

// Styles
import {
	VCol,
	TopWrap,
	TLeft,
	MiddleWrap,
	MFilter,
	SearchSpan,
	SearchInput,
	DetailsWrap,
	DHeader,
	TableWrap,
	HeartWrap,
} from '../style';
import Link from '../../../../shared/components/Link';

import { IVaults } from '../../../../@types/interfaces';
// Interfaces
interface ITable {
	columns: Array<{
		Header: string;
		columns: Array<{
			Header: string;
			accessor: string | JSX.Element | JSX.Element[] | any;
		}>;
	}>;
	data: IVaults | any;
}

// Define a search UI
function GlobalFilter({ globalFilter, setGlobalFilter }: any) {
	const [value, setValue] = useState(globalFilter);

	// https://react-table.tanstack.com/docs/faq#how-can-i-debounce-rapid-table-state-changes
	const onChange = useAsyncDebounce((newValue) => {
		setGlobalFilter(newValue || undefined);
	}, 400);

	return (
		<SearchSpan className='search'>
			<SearchInput
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder='Search'
				style={{
					border: '0',
				}}
			/>
		</SearchSpan>
	);
}

const Table = ({ columns, data }: ITable) => {
	const { t } = useTranslation();
	const { isTablet } = useMobleDetect();
	const [mFilters, setMFilters] = useState(false);

	// Filter hooks
	const { handleStakedFilter } = useStaked();
	const { handleZeroFilter } = useZeroBalance();
	const { detailsIsOpen, setFilters } = useContext(VaultsContext);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canNextPage,
		setPageSize,
		state: { pageSize, globalFilter },
		preGlobalFilteredRows,
		setGlobalFilter,
		setFilter,
		setAllFilters,
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	return (
		<VCol>
			{(!isTablet || mFilters === true) && (
				<TopWrap>
					{isTablet && (
						<p
							className='clear'
							onClick={() => {
								setFilters({
									staked: false,
									zero: false,
									assets: '',
									protocol: '',
									tabs: 'all',
								});
								setAllFilters([]);
							}}
							aria-hidden='true'
						>
							Clear filters
						</p>
					)}
					<TLeft>
						<StakedOnly onClick={() => handleStakedFilter(setFilter)} />
						<ZeroBalance onClick={() => handleZeroFilter(setFilter)} />
						<Assets setFilter={setFilter} />
						<Protocol setFilter={setFilter} />
					</TLeft>
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						// @ts-ignore
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					{isTablet && <Tabs setFilter={setFilter} />}
				</TopWrap>
			)}

			<MiddleWrap>
				{!isTablet && <Tabs setFilter={setFilter} />}
				<Banner />
				{isTablet && (
					<MFilter>
						<p>Filters</p>
						<div
							className={`icon-wrap ${mFilters}`}
							onClick={() => setMFilters(!mFilters)}
							aria-hidden='true'
						>
							<FilterIcon />
						</div>
					</MFilter>
				)}
			</MiddleWrap>

			<div {...getTableProps()}>
				<div>
					{headerGroups.map((headerGroup: any, i: number) => (
						<DHeader
							{...headerGroup.getHeaderGroupProps()}
							key={i}
							className='d-head'
						>
							{headerGroup.headers.map((column: any, index: number) =>
								// Render everything but Catergory, Info, and Balance Header
								{
									// Disable Deposited header for Mobile / Tablet
									let preventHDeposit;
									if (isTablet) {
										preventHDeposit = 'Deposited';
									} else {
										preventHDeposit = '';
									}

									if (
										column.Header !== 'Category' &&
										column.Header !== 'Info' &&
										column.Header !== 'Balance' &&
										column.Header !== preventHDeposit
									) {
										return (
											<div
												{...column.getSortByToggleProps()}
												className='row'
												key={index}
											>
												{column.render('Header')}
												<span>
													{
														// eslint-disable-next-line no-nested-ternary
														column.isSorted ? (
															column.isSortedDesc ? (
																<SortArrowIcon />
															) : (
																<SortArrowIcon className='reverse' />
															)
														) : (
															''
														)
													}
												</span>
											</div>
										);
									}

									// Custom Info column
									if (column.Header === 'Info') {
										return (
											<div className='row info-tooltip' key={index}>
												<ToolTip icon={<InfoIcon />} right='-10px' top='30px'>
													<InfoToolTip />
												</ToolTip>
											</div>
										);
									}

									return null;
								}
							)}
						</DHeader>
					))}
				</div>
				<TableWrap {...getTableBodyProps()}>
					{page.map((row: any, i: number) => {
						prepareRow(row);

						return (
							<React.Fragment key={i}>
								<div
									{...row.getRowProps()}
									className={`row row-${row.original.display}`}
								>
									{row.cells.map((cell: any, index: number) => {
										const {
											impact,
											core,
											single_asset: singleAsset,
											display,
											create_lp: createLp,
											info_show: infoShow,
											error_show: errorShow,
										} = cell.row.original;

										// console.log(cell.row.original.info_show);
										// console.log(cell.row.original.error_show);

										// Prevent deposit category to display on Mobile/Tablet
										let preventDeposit;
										if (isTablet) {
											preventDeposit = 3;
										} else {
											preventDeposit = null;
										}

										// Prevent category,balance cells to display (6,7 index)
										return (
											index !== 6 &&
											index !== 7 &&
											index !== preventDeposit && (
												<React.Fragment key={index}>
													{display && (
														<>
															{cell.column.Header === 'Assets' ? (
																<div
																	{...cell.getCellProps()}
																	className={
																		core ? 'c-border cell' : 'r-border cell'
																	}
																>
																	<div className='assets'>
																		{impact ? (
																			<ToolTip
																				icon={
																					<HeartIcon
																						style={{ marginRight: '10px' }}
																					/>
																				}
																				left='50%'
																				transform=' translateX(-50%)'
																				bottom='30px'
																				pointerBot
																			>
																				<HeartWrap>
																					{t(
																						'dep_info',
																						'By depositing in Social Impact Vaults, you are supporting clean energy and emerging markets.'
																					)}
																				</HeartWrap>
																			</ToolTip>
																		) : (
																			<div className='h-spacer' />
																		)}
																		{!singleAsset ? (
																			<>
																				<LpTokenIcon
																					style={{ marginRight: '14px' }}
																				/>
																				<div className='a-wrap'>
																					{cell.render('Cell')}
																					{!infoShow && !errorShow && (
																						<Link external to={createLp}>
																							{t('create_lp', 'Create LP')}
																						</Link>
																					)}
																					{infoShow && (
																						<div className='dis'>
																							<ToolTip
																								icon={
																									<>
																										<p>
																											{t(
																												'discontinued',
																												'Discontinued'
																											)}
																										</p>
																										<WarningIcon />
																									</>
																								}
																							>
																								<HeartWrap>error?</HeartWrap>
																							</ToolTip>
																						</div>
																					)}
																					{!infoShow && errorShow && (
																						<div className='error'>
																							<ToolTip
																								icon={
																									<>
																										<p>
																											{t(
																												'unavailable',
																												'Unavailable'
																											)}
																										</p>
																										<WarningIcon />
																									</>
																								}
																							>
																								error?
																							</ToolTip>
																						</div>
																					)}
																				</div>
																			</>
																		) : (
																			<>
																				<TokenIcon
																					style={{
																						marginRight: '14px',
																						minWidth: '36px',
																					}}
																				/>
																				<div className='a-wrap'>
																					{cell.render('Cell')}
																					{!infoShow && !errorShow && (
																						<Link external to={createLp}>
																							{t('buy_token', 'Buy Token')}
																						</Link>
																					)}
																					{infoShow && (
																						<div className='dis'>
																							<ToolTip
																								icon={
																									<>
																										<p>
																											{t(
																												'discontinued',
																												'Discontinued'
																											)}
																										</p>
																										<WarningIcon />
																									</>
																								}
																								left='50%'
																								transform=' translateX(-50%)'
																								bottom='30px'
																								pointerBot
																							>
																								<HeartWrap>
																									{t(
																										'dis_info',
																										'This vault has been discontinued. You still have the option to withdraw and claim your funds, without any time limits.'
																									)}
																								</HeartWrap>
																							</ToolTip>
																						</div>
																					)}
																					{!infoShow && errorShow && (
																						<div className='error'>
																							<ToolTip
																								icon={
																									<>
																										<p>
																											{t(
																												'unavailable',
																												'Unavailable'
																											)}
																										</p>
																										<WarningIcon />
																									</>
																								}
																							>
																								<HeartWrap>
																									{t(
																										'error_vault',
																										'This vault is unavailable due to an unexpected error. Please withdraw and claim your funds as soon as possible.'
																									)}
																								</HeartWrap>
																							</ToolTip>
																						</div>
																					)}
																				</div>
																			</>
																		)}
																	</div>
																</div>
															) : (
																<div {...cell.getCellProps()} className='cell'>
																	{cell.render('Cell')}
																</div>
															)}
														</>
													)}
												</React.Fragment>
											)
										);
									})}
								</div>
								<DetailsWrap
									className={`${
										row.original.id === detailsIsOpen?.active ? 'open' : ''
									}`}
								>
									<Details data={data} dData={row.original} />
								</DetailsWrap>
							</React.Fragment>
						);
					})}
				</TableWrap>
			</div>
			{canNextPage && (
				<Button
					secondary
					onClick={() => setPageSize(pageSize + 10)}
					className='load-more'
				>
					{t('load_more', 'Load More')}
				</Button>
			)}
		</VCol>
	);
};
Table.whyDidYouRender = true;

export default Table;
