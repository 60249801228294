import React, { useContext } from 'react';

// Api
import { useQuery } from 'react-query';
import { fetchTokenPrices } from '../../../../config/api';

// Context
import { ApiContext } from '../../../../context/ApiContext';

// Components
import Layout from '../../../general/Layout';
import Table from './Table';
import Loading from '../../../../shared/components/Loading';

// Table Columns
import useColumns from './columns';
import { mergeTokenPrices } from '../../../../shared/utils/helpers';

const Index = () => {
	const { data: apiData } = useContext(ApiContext);
	const { data: marketPrices } = useQuery('marketPrices', fetchTokenPrices, {
		refetchInterval: 300000,
	});

	// Merge main table data with token prices
	const data = React.useMemo(
		() => ({
			...apiData,
			si_vaults: {
				vaults:
					marketPrices && apiData
						? mergeTokenPrices(
								marketPrices.market_prices,
								apiData.si_vaults.vaults
						  )
						: null,
			},
		}),
		[apiData, marketPrices]
	);

	// Create vault columns
	const { vaultsColumns } = useColumns(apiData && data && apiData.walletLoaded);

	return (
		<Layout>
			{data && marketPrices && data.si_vaults.vaults ? (
				<Table columns={vaultsColumns} data={data.si_vaults.vaults} />
			) : (
				<Loading />
			)}
		</Layout>
	);
};

export default Index;
