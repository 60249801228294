import * as React from 'react';

function SvgSupervisorsIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={14}
			height={14}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M13.511 11.7a4.906 4.906 0 00-4.214-3.301c-.643.68-1.44 1.113-2.341 1.113-.902 0-1.699-.432-2.341-1.113a4.907 4.907 0 00-4.216 3.3l-.348 1.025A.967.967 0 00.965 14h11.98a.966.966 0 00.915-1.276L13.51 11.7z'
				fill='#9493A1'
			/>
			<path
				d='M3.832 3.843c0 2.298 1.399 4.764 3.124 4.764s3.124-2.466 3.124-4.764c0-4.764-6.248-4.773-6.248 0z'
				fill='#9493A1'
			/>
		</svg>
	);
}

export default SvgSupervisorsIcon;
