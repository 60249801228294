import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { VaultsContext } from '../../context/VaultsContext';

// Styles
import { AlignCenter, Checkbox, LabelTextSmall } from '../../style';

const Index = ({ onClick }: any) => {
	const { t } = useTranslation();
	const { filters } = useContext(VaultsContext);

	return (
		<AlignCenter>
			<Checkbox
				type='checkbox'
				id='staked'
				name='staked'
				onChange={onClick}
				checked={filters?.staked}
			/>
			<LabelTextSmall htmlFor='staked'>
				{t('staked_only', 'Staked only')}
			</LabelTextSmall>
		</AlignCenter>
	);
};

export default Index;
