import * as React from 'react';

function SvgSivIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={18}
			height={18}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M4.219 8.191a4.219 4.219 0 100 8.438 4.219 4.219 0 000-8.438zm0 5.801c-.873 0-1.582-.71-1.582-1.582 0-.872.71-1.582 1.582-1.582.872 0 1.582.71 1.582 1.582 0 .873-.71 1.582-1.582 1.582z'
				fill='#9493A1'
			/>
			<path
				d='M4.219 11.883a.528.528 0 10.001 1.056.528.528 0 00-.001-1.056zM13.781 4.5c0-.29.237-.527.528-.527V2.918c-.873 0-1.582.71-1.582 1.582v1.582h1.054V4.5zM11.184 11.355a4.221 4.221 0 013.652-2.109c.768 0 1.489.206 2.11.566v-.566c0-1.163-.947-2.11-2.11-2.11H7.713a6.291 6.291 0 00-3.494-1.054A6.338 6.338 0 00.192 7.529l.671.813A5.281 5.281 0 014.22 7.137a5.283 5.283 0 015.167 4.218h1.798zM10.75 12.41H9.493v1.582h1.158a4.231 4.231 0 01.1-1.582z'
				fill='#9493A1'
			/>
			<path
				d='M14.836 10.3a3.164 3.164 0 100 6.329 3.164 3.164 0 000-6.328zm0 3.692a.527.527 0 110-1.054.527.527 0 010 1.054zM2.637 5.2a7.372 7.372 0 015.381.882h2.054l-.969-3.691h.917V1.336H1.582V2.39h1.055v2.808z'
				fill='#9493A1'
			/>
		</svg>
	);
}

export default SvgSivIcon;
