import React from 'react';

// Styles
import { LoadWrap, LogoWrap } from './style';

const index = () => (
	<LoadWrap>
		<LogoWrap>
			<svg
				width='86'
				height='105'
				viewBox='0 0 86 105'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M42.965 0L32.5359 10.4482L42.965 20.8963L53.3938 10.4482L42.965 0Z'
					fill='#FDC500'
				/>
				<path
					d='M16.9005 57.4738C16.2146 56.7885 15.6705 55.9742 15.2992 55.0778C14.9279 54.1814 14.7368 53.2203 14.7368 52.2497C14.7368 51.2792 14.9279 50.3183 15.2992 49.4219C15.6705 48.5255 16.2146 47.7112 16.9005 47.0256L32.5259 31.3444L22.115 20.8963L6.47149 36.5685C2.32756 40.726 0 46.3609 0 52.2362C0 58.1115 2.32756 63.7467 6.47149 67.9039C8.52583 69.962 10.9647 71.5947 13.6488 72.7085C16.3329 73.8223 19.2097 74.3957 22.115 74.3957C25.0202 74.3957 27.897 73.8223 30.5813 72.7085C33.2653 71.5947 35.7042 69.962 37.7584 67.9039L58.6255 47.0256C59.3096 46.3387 60.1224 45.7936 61.0172 45.4215C61.9118 45.0496 62.8712 44.8582 63.8399 44.8582C64.8087 44.8582 65.7678 45.0496 66.6626 45.4215C67.5575 45.7936 68.3703 46.3387 69.0544 47.0256C69.7402 47.7112 70.2844 48.5255 70.6557 49.4219C71.0271 50.3183 71.218 51.2792 71.218 52.2497C71.218 53.2203 71.0271 54.1814 70.6557 55.0778C70.2844 55.9742 69.7402 56.7885 69.0544 57.4738L53.4109 73.1461L63.8399 83.5943L79.5196 67.9039C83.6689 63.7459 86 58.1069 86 52.2273C86 46.3474 83.6689 40.7084 79.5196 36.5504C77.4651 34.4923 75.0263 32.8596 72.3422 31.7458C69.6581 30.632 66.7814 30.0588 63.8761 30.0588C60.9709 30.0588 58.0939 30.632 55.4098 31.7458C52.7258 32.8596 50.2869 34.4923 48.2326 36.5504L27.3294 57.4738C25.9454 58.8573 24.07 59.6341 22.115 59.6341C20.1599 59.6341 18.2846 58.8573 16.9005 57.4738Z'
					fill='var(--primary1)'
				/>
				<path
					d='M42.965 83.6012L32.5359 94.0491L42.965 104.497L53.3938 94.0491L42.965 83.6012Z'
					fill='var(--primary1)'
				/>
			</svg>
			<div className='l-overlay' />
		</LogoWrap>
	</LoadWrap>
);

export default index;
