import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Link from '../../../shared/components/Link';

// Icons
import {
	DBook,
	DDiscord,
	DGithub,
	DLinkedin,
	DTelegram,
	DTwitter,
} from '../../../shared/components/Icons';

// Styles
import { DContent } from '../style';

// Interfaces
interface IDrop {
	menu?: boolean;
	mRef?: {
		current: HTMLDivElement | null;
	};
}

const DropdownContent = ({ menu, mRef }: IDrop) => {
	const { t } = useTranslation();

	return (
		<DContent className={menu ? 'active dc' : 'm-drop dc'} ref={mRef}>
			<p className='title'>{t('support', 'Community & Support')}</p>
			<div className='item'>
				<Link external to='https://discord.com/invite/HxhVncqsJX'>
					<DDiscord />
					{t('discord', 'Discord')}
				</Link>
			</div>
			<div className='item'>
				<Link external to='https://t.co/JVt930Xc5o?amp=1'>
					<DTelegram />
					{t('telegram', 'Telegram')}
				</Link>
			</div>
			<div className='item'>
				<Link external to='https://twitter.com/SINetworkHQ'>
					<DTwitter />
					{t('twitter', 'Twitter')}
				</Link>
			</div>
			<div className='item'>
				<Link
					external
					to='https://www.linkedin.com/company/socialimpactnetwork/'
				>
					<DLinkedin />
					{t('linkedin', 'Linkedin')}
				</Link>
			</div>
			<div className='divider' />
			<p className='title'> {t('developers', 'Developers')}</p>
			<div className='dev-icons'>
				<Link to='/' className='disabled'>
					<DGithub style={{ marginRight: '30px' }} />
				</Link>
				<Link external to='https://docs.social-impact.network/'>
					<DBook />
				</Link>
			</div>
			<div className='divider' />
			<Link to='/privacy' className='title disabled b-title'>
				{t('privacy', 'Privacy Policy')}
			</Link>
			<Link to='/terms' className='title disabled b-title'>
				{t('terms', 'Terms of Use')}
			</Link>
		</DContent>
	);
};

export default DropdownContent;
