import * as React from 'react';

function SvgDTwitter(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={20}
			height={16}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M17.945 3.986c.012.175.012.35.012.525C17.957 9.848 13.833 16 6.295 16A11.711 11.711 0 010 14.187c.329.035.66.052.99.05a8.29 8.29 0 005.089-1.726 4.147 4.147 0 01-2.371-.795 4.048 4.048 0 01-1.462-2.004c.257.04.515.06.775.061a4.397 4.397 0 001.078-.138 4.11 4.11 0 01-2.362-1.402 4.001 4.001 0 01-.925-2.56v-.05a4.176 4.176 0 001.853.513 4.044 4.044 0 01-1.72-2.451A3.992 3.992 0 011.395.737a11.62 11.62 0 003.771 2.997A11.79 11.79 0 009.85 4.961a4.494 4.494 0 01-.102-.925 4 4 0 01.712-2.269A4.092 4.092 0 0112.342.283a4.161 4.161 0 012.403-.186 4.119 4.119 0 012.095 1.176 8.145 8.145 0 002.602-.975 4.038 4.038 0 01-1.802 2.225A8.316 8.316 0 0020 1.897a8.741 8.741 0 01-2.055 2.088z'
				fill='#474954'
			/>
		</svg>
	);
}

export default SvgDTwitter;
