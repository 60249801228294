import React from 'react';
import { useTranslation } from 'react-i18next';

// Helpers
import { useMobleDetect } from '../../../shared/utils/hooks/useMobileDetect';

// Components
import Link from '../../../shared/components/Link';
import Footer from '../Footer';
import DropdownContent from '../Header/DropdownContent';

// Icons
import {
	CoordinatorsIcon,
	TreasuryIcon,
	GovernanceIcon,
	SivIcon,
	SunnyIcon,
	HomeIcon,
	SupervisorsIcon,
	ListDotIcon,
} from '../../../shared/components/Icons';

// Styles
import { NWrap, NContainer, NItems, NSingleItem } from '../style';

const Nav = () => {
	const { t } = useTranslation();
	const { isTablet } = useMobleDetect();

	return (
		<NWrap>
			<NContainer>
				<NItems className='nav-items'>
					<NSingleItem>
						<Link to='/' style={{ width: '100%', opacity: '1' }}>
							<HomeIcon />
							{t('home', 'Home')}
						</Link>
					</NSingleItem>
					<NSingleItem className='disabled'>
						<Link
							to='/sunny'
							style={{ width: '100%', opacity: '1' }}
							className='disabled'
						>
							<SunnyIcon />
							{t('sunny_pool', 'Sunny Pool')}
						</Link>
					</NSingleItem>
					<NSingleItem className='sub-nav disabled'>
						<Link
							to='/projects'
							style={{ width: '100%', opacity: '1' }}
							className='disabled'
						>
							<ListDotIcon />
							{t('projects', 'Projects')}
						</Link>
					</NSingleItem>
					<NSingleItem className='sub-nav disabled'>
						<Link
							to='/liq'
							style={{ width: '100%', opacity: '1' }}
							className='disabled'
						>
							<ListDotIcon />
							{t('liquidity_reserve', 'Liquidity Reserve')}
						</Link>
					</NSingleItem>
					<NSingleItem>
						<Link
							to='/social-impact-vaults/'
							style={{ width: '100%', opacity: '1' }}
						>
							<SivIcon />
							{t('siv', 'Social Impact Vaults')}
						</Link>
					</NSingleItem>
					<NSingleItem className='disabled'>
						<Link
							to='/governance'
							style={{ width: '100%', opacity: '1' }}
							className='disabled'
						>
							<GovernanceIcon />
							{t('governance', 'Governance')}
						</Link>
					</NSingleItem>
					<NSingleItem className='disabled'>
						<Link
							to='/treasury'
							style={{ width: '100%', opacity: '1' }}
							className='disabled'
						>
							<TreasuryIcon />
							{t('treasury', 'Treasury')}
						</Link>
					</NSingleItem>
					<NSingleItem className='disabled'>
						<Link
							to='/supervisors'
							style={{ width: '100%', opacity: '1' }}
							className='disabled'
						>
							<SupervisorsIcon />
							{t('supervisors', 'Supervisors')}
						</Link>
					</NSingleItem>
					<NSingleItem className='disabled'>
						<Link
							to='/coordinators'
							style={{ width: '100%', opacity: '1' }}
							className='disabled'
						>
							<CoordinatorsIcon />
							{t('project_coordinators', 'Project Coordinators')}
						</Link>
					</NSingleItem>
				</NItems>

				{isTablet && (
					<>
						<div className='drop-divider' />
						<DropdownContent />
					</>
				)}
			</NContainer>
			<Footer />
		</NWrap>
	);
};

export default Nav;
