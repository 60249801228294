/* eslint no-unneeded-ternary:"off" */

import React, { useState } from 'react';
import Modal from 'react-modal';
import { CloseIcon } from '../Icons';
import { ModalWrap } from './style';

// Modal Styles
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '25px',
	},
};

Modal.setAppElement('#root');

const ModalC = ({
	btnChild,
	modalBody,
	doubleClose,
	disabled,
	className,
}: any) => {
	const [modalIsOpen, setIsOpen] = useState(false);

	function openModal() {
		setIsOpen(true);
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = '#f00';
	}

	function closeModal() {
		setIsOpen(false);
	}

	return (
		<div>
			<button type='button' onClick={openModal} disabled={disabled}>
				{btnChild}
			</button>
			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel='Modal'
				className={className}
			>
				<ModalWrap>
					<button type='button' onClick={closeModal} className='top-close'>
						<CloseIcon />
					</button>
					{modalBody}
					{doubleClose && (
						<button type='button' onClick={closeModal} className='bot-close'>
							Close
						</button>
					)}
				</ModalWrap>
			</Modal>
		</div>
	);
};

export default ModalC;
