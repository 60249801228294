import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { VaultsContext } from '../../context/VaultsContext';

import { useProtocol } from './useProtocol';

// Styles
import { SelectSpan, SelectOption } from '../../style';

const Index = ({ setFilter }: any) => {
	const { t } = useTranslation();

	const { filters, setFilters } = useContext(VaultsContext);
	const { handleProtocolFilter } = useProtocol();

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setFilters({ ...filters, protocol: e.target.value });
		handleProtocolFilter(setFilter, e.target.value);
	};

	return (
		<SelectSpan className='p-select'>
			<SelectOption value={filters?.protocol} onChange={(e) => handleChange(e)}>
				<option value='' disabled hidden>
					{t('protocol', 'Protocol')}
				</option>
				<option value=''>{t('all', 'All')}</option>
				<option value='Social Impact Network'>Social Impact Network</option>
				<option value='Belt'>Belt</option>
				<option value='PancakeSwap'>Pancake Swap</option>

				{/* <option value='venus'>Venus</option>
				<option value='auto'>Auto</option>
				<option value='pcs'>PCS</option>
				<option value='bDollar'>bDollar</option>
				<option value='goose'>Goose</option>
				<option value='disabled'>Disabled</option> */}
			</SelectOption>
		</SelectSpan>
	);
};

export default Index;
