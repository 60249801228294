import * as React from 'react';

function SvgApiCalculatorIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={20}
			height={20}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1 6.125C1 5.504 1.504 5 2.125 5h15.75a1.125 1.125 0 010 2.25H2.125A1.125 1.125 0 011 6.125zm0 8C1 13.504 1.504 13 2.125 13h11.25a1.125 1.125 0 010 2.25H2.125A1.125 1.125 0 011 14.125zM2.125 9a1.125 1.125 0 000 2.25h7.5a1.125 1.125 0 000-2.25h-7.5z'
				fill='#693e80'
			/>
		</svg>
	);
}

export default SvgApiCalculatorIcon;
