import * as React from 'react';

function SvgDGithub(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M12.002 0c-2.85 0-5.607 1.039-7.777 2.932a12.385 12.385 0 00-4.07 7.402 12.567 12.567 0 001.578 8.34 12.06 12.06 0 006.473 5.305c.6.115.82-.265.82-.592 0-.293-.01-1.067-.015-2.092-3.338.742-4.042-1.65-4.042-1.65a3.254 3.254 0 00-1.333-1.8c-1.087-.763.084-.748.084-.748.38.054.743.196 1.062.416.318.22.584.511.775.852.163.304.382.572.647.788a2.495 2.495 0 002.849.237 2.652 2.652 0 01.76-1.645c-2.667-.308-5.467-1.367-5.467-6.084A4.816 4.816 0 015.58 8.359a4.478 4.478 0 01.105-3.257s1.005-.33 3.3 1.262a11.043 11.043 0 016 0c2.282-1.59 3.282-1.26 3.282-1.26a4.603 4.603 0 01.12 3.256 4.836 4.836 0 011.23 3.302c0 4.727-2.805 5.769-5.474 6.07.284.3.504.657.643 1.05.14.392.197.81.167 1.227 0 1.647-.015 2.97-.015 3.37 0 .322.21.707.825.584a12.042 12.042 0 006.49-5.286 12.55 12.55 0 001.594-8.34 12.37 12.37 0 00-4.066-7.407A11.81 11.81 0 0012.002 0z'
				fill='#474954'
			/>
		</svg>
	);
}

export default SvgDGithub;
