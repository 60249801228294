import React, { useState, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';

// Helpers
import { formatPriceNumber } from '../../../shared/utils/helpers';

// Components
import Link from '../../../shared/components/Link';
import Divider from '../../../shared/components/Divider';

// Styles
import { BeFlex, SLeft, Container, Numbers, ChartWrap } from './style';

// Interfaces
import { IGeneral } from '../../../@types/interfaces';
import { Flex } from '../../../shared/styles/GeneralStyles';
import { LogoSmallChart } from '../../../shared/components/Icons';

interface IStat {
	data: IGeneral;
}

type Props = {
	data: ComponentProps<typeof PieChart>['data'];
};

const Statistics = ({ data }: IStat) => {
	const { t } = useTranslation();

	// Tooltip for pie chart
	const [hovered, setHovered] = useState<number | null>(null);
	function makeTooltipContent(entry: Props['data'][0]) {
		return `${entry.tooltip} ${entry.value}%`;
	}

	const {
		co2_reduced,
		energy_generated,
		tvl,
		treasury_valuation,
		sit_holder,
		buy_sit,
		treasury_chart,
	} = data;

	const pieArr: Array<{
		title: string;
		value: number;
		color: string;
		tooltip: string;
	}> = [];

	const pieColors = ['#fddc66', '#fee799', '#fdd032', '#fef3cc'];

	// @ts-ignore
	treasury_chart?.tokens.map((el: any, index: number) => {
		pieArr.push({
			title: el.token,
			value: +Number(el.percentage).toFixed(),
			color: pieColors[index],
			tooltip: el.token,
		});
		return null;
	});

	return (
		<Flex>
			<h4 style={{ marginBottom: '30px' }}>{t('statistics', 'Statistics')}</h4>
			<Container>
				<SLeft>
					<BeFlex>
						<p>{t('co2_reduced', 'CO₂ Reduced')}</p>
						<Numbers className='bold'>
							{formatPriceNumber(co2_reduced)} kg
						</Numbers>
					</BeFlex>
					<BeFlex>
						<p>{t('energy_generated', 'Energy Generated')}</p>
						<Numbers className='bold'>
							{formatPriceNumber(energy_generated)} kWh
						</Numbers>
					</BeFlex>
					<BeFlex>
						<p>{t('tvl', 'TVL')}</p>
						<Numbers className='bold'>${formatPriceNumber(tvl)}</Numbers>
					</BeFlex>
					<BeFlex>
						<p>{t('treasury_value', 'Treasury Value')}</p>
						<Numbers>${formatPriceNumber(treasury_valuation)}</Numbers>
					</BeFlex>
					<BeFlex>
						<p>{t('si_token_holder', 'SI Token Holder')}</p>
						<Numbers>{formatPriceNumber(sit_holder, 0)}</Numbers>
					</BeFlex>
				</SLeft>
				<ChartWrap data-tip='' data-for='chart'>
					<PieChart
						data={pieArr}
						animate
						animationDuration={500}
						animationEasing='ease-out'
						lengthAngle={360}
						lineWidth={50}
						paddingAngle={3}
						radius={40}
						viewBoxSize={[100, 100]}
						label={(el) => `${el.dataEntry.value}%`}
						labelPosition={75}
						labelStyle={{
							fontSize: '7px',
							fontColor: '#1A1B29',
							// @ts-ignore
							fontWeight: '400',
						}}
						onMouseOver={(_, index) => {
							setHovered(index);
						}}
						onMouseOut={() => {
							setHovered(null);
						}}
					/>
					<LogoSmallChart className='logo' />
					<ReactTooltip
						id='chart'
						backgroundColor='var(--primary1)'
						getContent={() =>
							typeof hovered === 'number'
								? makeTooltipContent(pieArr[hovered])
								: null
						}
					/>
					<Link className='disabled' to='/'>
						{t('treasury_allocation', 'Treasury allocation')}
					</Link>
				</ChartWrap>
			</Container>
			<Divider />
			<Link to={buy_sit} external btn>
				{t('buy_si_token', 'Buy SI Token')}
			</Link>
		</Flex>
	);
};

export default Statistics;
