import { useContext } from 'react';

// Context
import { VaultsContext } from '../../context/VaultsContext';

// Filter function used in table/columns.tsx
export const depositedFilter = (rows: any, id: number, filterValue: boolean) =>
	rows.filter((row: any) => {
		if (filterValue === true) {
			return row.original.poolWallet && row.original.poolWallet.deposited > 0;
		}
		return row.original;
	});

// Filter hook used in Table.tsx
export const useStaked = () => {
	const { filters, setFilters } = useContext(VaultsContext);

	const handleStakedFilter = (setFilter: any) => {
		setFilters({
			...filters,
			staked: !filters?.staked,
		});
		setFilter('poolWallet.deposited', !filters?.staked);
	};

	return {
		handleStakedFilter,
	};
};
