import React, { useRef, useState } from 'react';

// BC
import Onboard from '../Onboard';

// Helpers
import { useOnClickOutside } from '../../../shared/utils/helpers';
import { useMobleDetect } from '../../../shared/utils/hooks/useMobileDetect';

// Components
import Link from '../../../shared/components/Link';
import Logo from '../../../shared/components/Logo';
import DropdownContent from './DropdownContent';

// Icons
import { Dot } from '../../../shared/components/Icons';

// Styles
import { Header, Dropdown, HeaderBtns } from '../style';

// Interfaces
interface IHeader {
	menu: { openNav: boolean; setNav: any };
}

const Index = ({ menu }: IHeader) => {
	const { isTablet } = useMobleDetect();

	const { openNav, setNav } = menu;

	// Dots menu
	const parentRef = useRef<HTMLDivElement>(null);
	const childRef = useRef<HTMLDivElement>(null);
	const [openMenu, setOpen] = useState(false);

	const onOpenMenu = () => {
		setOpen(!openMenu);
	};

	useOnClickOutside(parentRef, childRef, openMenu, () => setOpen(false));

	return (
		<Header>
			{!isTablet ? (
				<Link to='/' className='logo' aria='Logo'>
					<Logo />
				</Link>
			) : (
				<div className='m-wrap'>
					<button
						className='burger'
						type='button'
						onClick={() => setNav(!openNav)}
					>
						<span />
						<span />
						<span />
					</button>
					{document.title !== 'Home' && <p>{document.title}</p>}
				</div>
			)}

			<HeaderBtns>
				<Onboard />
				{!isTablet && (
					<>
						<Dropdown onClick={() => onOpenMenu()} ref={parentRef}>
							<Dot style={{ marginRight: '3px' }} />
							<Dot style={{ marginRight: '3px' }} />
							<Dot />
						</Dropdown>
						<DropdownContent menu={openMenu} mRef={childRef} />
					</>
				)}
			</HeaderBtns>
		</Header>
	);
};
Index.whyDidYouRender = true;

export default Index;
