import * as React from 'react';

function SvgLpTokenIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={36}
			height={30}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle cx={12} cy={15} r={12} fill='#FDC500' />
			<circle cx={24} cy={15} r={11.5} fill='#430E60' />
		</svg>
	);
}

export default SvgLpTokenIcon;
