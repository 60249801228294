import React, { useEffect } from 'react';
import VaultsContextProvider from './context/VaultsContext';

import Table from './table';

const Index = () => {
	useEffect(() => {
		document.title = 'Social Impact Vaults';
	}, []);

	return (
		<VaultsContextProvider>
			<Table />
		</VaultsContextProvider>
	);
};
export default Index;
