import * as React from 'react';

function SvgTreasuryIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={18}
			height={18}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g clipPath='url(#treasury-icon_svg__clip0)' fill='#9493A1'>
				<path d='M12.974 7.767h3.305v5.837h-3.305V7.767zM7.347 7.767h3.306v5.837H7.347V7.767zM16.912 14.66H1.088c-.582 0-1.055.472-1.055 1.054v1.231C.033 17.527.506 18 1.088 18h15.824c.582 0 1.055-.473 1.055-1.055v-1.23c0-.583-.473-1.056-1.055-1.056zM17.306 3.312L9.392.076a1.05 1.05 0 00-.788.001L.694 3.312a1.05 1.05 0 00-.661.979v1.366c0 .581.473 1.055 1.055 1.055h15.824c.582 0 1.055-.474 1.055-1.055V4.29c0-.434-.259-.817-.66-.98zm-7.779.622a.528.528 0 01-1.054 0V2.808a.528.528 0 011.054 0v1.126zM1.72 7.767h3.306v5.837H1.721V7.767z' />
			</g>
			<defs>
				<clipPath id='treasury-icon_svg__clip0'>
					<path fill='#fff' d='M0 0h18v18H0z' />
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgTreasuryIcon;
