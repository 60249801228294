import * as React from 'react';

function SvgHeartIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g clipPath='url(#heart-icon_svg__clip0)'>
				<path fill='transparent' d='M4.8 5.6h14.4v12.8H4.8z' />
				<path
					d='M13.277 7.554l.009-.009.008-.01a2.823 2.823 0 011.881-.922 2.805 2.805 0 011.979.625c.307.272.558.605.737.979a3.152 3.152 0 01-.54 3.511l-.001.002-5.354 5.613-5.35-5.614a3.113 3.113 0 01-.652-1.057 3.163 3.163 0 01.115-2.455c.179-.374.43-.707.736-.98a2.805 2.805 0 011.978-.624c.717.053 1.39.382 1.882.924l.008.01.01.009.553.58.724.757.723-.758.554-.58z'
					stroke='#FF5C4C'
					strokeWidth={2}
				/>
			</g>
			<defs>
				<clipPath id='heart-icon_svg__clip0'>
					<path
						fill='#fff'
						transform='translate(4.8 5.6)'
						d='M0 0h14.4v12.8H0z'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgHeartIcon;
