import * as React from 'react';

function SvgDot(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={4}
			height={5}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle cx={2} cy={2.5} r={2} fill='#1A1B29' />
		</svg>
	);
}

export default SvgDot;
