import * as React from 'react';

function SvgWarningIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={22}
			height={22}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle cx={11} cy={11} r={7.333} fill='#FDC500' />
			<path
				d='M11.84 12.599h-1.667l-.35-6.678h2.366l-.35 6.678zm-2.045 2.338c0-.43.117-.728.35-.896a1.37 1.37 0 01.854-.266c.327 0 .607.088.84.266.234.168.35.466.35.896 0 .41-.116.71-.35.896a1.347 1.347 0 01-.84.266 1.37 1.37 0 01-.854-.266c-.233-.187-.35-.486-.35-.896z'
				fill='#1A1B29'
			/>
		</svg>
	);
}

export default SvgWarningIcon;
