import * as React from 'react';

function SvgCoordinatorsIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={14}
			height={18}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M12.7 2.1h-1v2.5c0 .6-.5 1.1-1.1 1.1H3.2c-.6 0-1-.5-1-1.1V2.1H1.1C.5 2.1 0 2.6 0 3.2V17c0 .5.5 1 1.1 1h11.6c.6 0 1.1-.5 1.1-1.1V3.2c0-.6-.5-1.1-1.1-1.1zm-6.6 11L3.6 15l-1.4-1.4c-.2-.2-.2-.5 0-.8.2-.2.5-.2.8 0l.7.7 1.8-1.3c.2-.2.6-.1.8.1.1.3.1.7-.2.8zm0-4.2l-2.5 1.9-1.3-1.4c-.2-.2-.2-.5 0-.7.2-.2.5-.2.8 0l.7.7L5.5 8c.2-.2.6-.1.8.1.1.3.1.6-.2.8zm5 4.9H7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.2c.3 0 .5.2.5.5 0 .2-.2.5-.5.5zm0-4.3H7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.2c.3 0 .5.2.5.5s-.2.5-.5.5z'
				fill='#9493A1'
			/>
			<path
				d='M10.6 2.1v1.6c0 .6-.5 1-1.1 1H4.2c-.6 0-1.1-.5-1.1-1V2.1c0-.6.5-1.1 1.1-1.1h1.2c.2-.6.8-1 1.5-1s1.3.4 1.5 1.1h1.1c.6 0 1.1.4 1.1 1z'
				fill='#9493A1'
			/>
		</svg>
	);
}

export default SvgCoordinatorsIcon;
