import styled from 'styled-components';
import { color, device, font } from '../../shared/styles/variables';

export const NWrap = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--white);

	@media ${device.tabletL} {
		.drop-divider {
			width: calc(100% - 48px);
			margin: 12px auto;
			border-top: 1px solid var(--darkMuteGray);
			box-sizing: border-box;
		}
	}
`;

export const NContainer = styled.div`
	overflow-x: auto;
`;

export const NItems = styled.ul`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-top: 70px;

	@media ${device.tabletL} {
		padding-top: 12px;

		.divider {
			width: 100%;
			border: 1px solid black;
		}
	}
`;

export const NSingleItem = styled.li`
	min-width: 265px;
	cursor: pointer;
	transition: all 0.25s ease-out;

	a {
		padding: 12px 0 12px 22px;
		display: flex;
		align-items: center;
		font-size: 14px;

		svg {
			margin-right: 12px;
		}

		&.active {
			border-left: 3px solid var(--primary2);
			padding-left: 19px;

			svg path {
				fill: var(--primary2);
			}
		}

		&.disabled {
			opacity: 0.5 !important;
		}
	}

	&:hover {
		background-color: var(--primary4);
		color: var(--primary1);
	}

	&:active {
		background-color: var(--primary1);
		color: var(--white);

		a {
			color: var(--white);

			svg path {
				fill: var(--white);
			}
		}
	}

	&.disabled {
		background-color: transparent;
		color: var(--text);
		cursor: unset;
	}

	&.sub-nav {
		a {
			padding-left: 50px;
		}
	}
`;

// Header
export const Header = styled.div`
	width: 100%;
	padding: 17px 35px 17px 22px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--white);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
	position: fixed;
	z-index: 4;

	.logo {
		display: flex;
	}

	.m-wrap {
		display: flex;
		align-items: center;

		.burger {
			padding: 5px 10px 5px 0;

			span {
				width: 24px;
				height: 4px;
				background-color: var(--primary1);
				border-radius: 10px;
				display: block;
				margin-bottom: 3px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		p {
			font-size: 14px;
			line-height: 20px;
			color: var(--text);
			${font.regularPoppins};
		}
	}

	@media ${device.tabletL} {
		padding: 11px 12px;
	}

	@media ${device.mobileS} {
		.m-wrap p {
			font-size: 12px;
		}
	}
`;

export const HeaderBtns = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`;

export const Dropdown = styled.div`
	margin-left: 16px;
	display: flex;
	align-items: center;
	border: 2px solid var(--secondary1);
	border-radius: 10px;
	padding: 11px;
	box-sizing: border-box;
	cursor: pointer;
	transition: all 100ms ease-out;

	svg circle {
		fill: var(--icon);
	}

	&:hover {
		background-color: var(--primary1);
		border: 2px solid transparent;

		svg circle {
			fill: var(--white);
		}
	}
`;

export const DContent = styled.div`
	position: absolute;
	top: 40px;
	right: 0;
	background-color: var(--white);
	box-shadow: 0px 17px 40px rgba(0, 0, 0, 0.108243);
	border-radius: 10px;
	padding: 15px 0 27px 0;
	width: 252px;
	cursor: initial;
	display: none;

	&.active {
		display: block;
	}

	p,
	a {
		font-size: 14px;
		color: var(--text);
		font-weight: 400;
		padding: 0 22px;
	}

	.title {
		margin-bottom: 18px;
	}

	.divider {
		height: 1px;
		background-color: var(--darkMuteGray);
		margin: 0 22px;
		margin-bottom: 20px;
		margin-top: 5px;
	}

	.item {
		svg {
			margin-right: 18px;

			path {
				fill: var(--icon);
			}
		}

		p {
			color: var(--darkDirtGray);
		}

		a {
			display: flex;
			align-items: center;
			padding: 13px 22px;
			width: 100%;

			&:hover {
				background-color: var(--white);
				color: var(--primary2);

				svg path {
					fill: var(--primary2);
				}
			}
		}
	}

	.dev-icons {
		display: flex;
		align-items: center;
		margin-top: 7px;
		margin-bottom: 17px;
		padding: 0 22px;

		a {
			padding: 5px 0 0 0;

			svg path {
				fill: var(--icon);
			}

			&:hover {
				svg path {
					fill: var(--primary2);
				}
			}
		}
	}

	.b-title {
		padding: 13px 22px;
		margin-bottom: 0;
	}
`;

export const ConnectBtn = styled.button`
	background-color: var(--primary1);
	color: ${color.light.tertiary1};
	padding: 5px 15px;
	height: 31px;
	border-radius: 10px;
	transition: all 100ms ease-out;
	font-size: 14px;

	&:hover {
		opacity: 1;
		background-color: var(--primary2);
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	}

	@media ${device.mobileS} {
		overflow: hidden;
		padding: 5px;
	}
`;

export const DisconnectBtn = styled.button`
	background-color: var(--primary1);
	color: var(--white);
	padding: 5px 20px;
	border-radius: 10px;
	font-size: 14px;
	height: 31px;

	&.connected {
		background-color: var(--purple);
		color: var(--text);
	}

	@media ${device.mobileM} {
		width: 96px;
		height: 30px;
		overflow: hidden;
	}
`;

// Footer
export const FooterWrap = styled.div`
	padding: 0 22px;
	padding-bottom: 32px;
	display: flex;
	flex-wrap: wrap;

	div:first-of-type {
		margin-top: 0;
	}

	@media (max-width: 525px) {
		padding-bottom: 72px;
	}
`;

export const Price = styled.div`
	display: flex;
	align-items: center;
	width: 50%;

	a {
		display: flex;
		align-items: center;
	}

	svg {
		margin-right: 2px;
	}

	p {
		font-size: 18px;
		${font.semibold}
		color:var(--primary1);
	}
`;

export const Social = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 50%;

	a {
		display: flex;
		align-items: center;

		svg path {
			fill: var(--primary2);
		}
	}
`;

export const BotWrap = styled.div`
	margin-top: 36px;
	display: flex;
	justify-content: space-between;
	width: 100%;

	.theme {
		display: flex;
		align-items: center;
		width: 50%;

		button {
			background-color: var(--primary2);
			width: 47px;
			height: 24px;
			border-radius: 20px;
			position: relative;

			.circle {
				width: 18px;
				height: 18px;
				border-radius: 100%;
				background-color: var(--white);
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 5px;
				transition: 0.3s;
			}

			&.dark .circle {
				left: calc(100% - 22px);
			}
		}

		svg {
			margin-left: 8px;

			path {
				fill: var(--primary2);

				&:nth-of-type(1) {
					fill: transparent;
				}
			}
		}
	}
`;

export const SelectSpan = styled.span`
	cursor: pointer;
	display: flex;
	margin-right: -20px;
	position: relative;

	.popup-wrap {
		position: absolute;
		display: none;
		flex-direction: column;
		transform: translate(-50%, 0px);
		left: 60%;
		bottom: 100%;
		background-color: var(--white);
		box-shadow: rgb(25 19 38 / 10%) 0px 2px 12px -8px,
			rgb(25 19 38 / 5%) 0px 1px 1px;
		padding: 16px;
		max-height: 400px;
		overflow-y: auto;
		z-index: 10;
		border-radius: 4px;

		button {
			align-items: center;
			border: 0px;
			border-radius: 16px;
			cursor: pointer;
			display: inline-flex;
			font-family: inherit;
			font-size: 14px;
			font-weight: 600;
			justify-content: center;
			line-height: 1;
			opacity: 1;
			outline: 0px;
			transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
			background-color: transparent;
			color: var(--primary1);
			box-shadow: none;
			padding: 10px 8px;
			border-radius: 8px;
		}
	}

	svg path {
		fill: var(--primary2);
	}

	&:hover {
		.popup-wrap {
			display: flex;
		}
	}
`;

export const SelectOption = styled.div`
	width: 100%;
	padding: 6px 25px 6px 14px;
	background-color: transparent;
	border: 0;
	border-radius: 10px;
	${font.regularPoppins};
	font-size: 14px;
	line-height: 20px;
	color: var(--text);
	text-transform: uppercase;
	display: flex;
	align-items: center;

	svg {
		margin-right: 8px;
	}

	p {
		${font.medium}
		font-size:14px;
	}
`;
