import React from 'react';

// Components
import Wallet from './Wallet';
import Statistics from './Statistics';
import SVaults from './SVaults';
import SunnyPool from './SunnyPool';
import Governance from './Governance';

// Styles
import { Card, Wrap, BWrap, MWrap, Vaults, Gov, Sunny } from './style';

// Interfaces
import { IApi } from '../../../@types/interfaces';

interface IHome {
	data: IApi;
}

const Homepage = ({ data }: IHome) => (
	<>
		<Card>
			<Wrap>
				<Statistics data={data.general} />
			</Wrap>
		</Card>
		<Card>
			<Wrap>
				<Wallet data={data} />
			</Wrap>
		</Card>

		<Sunny>
			<MWrap>
				<SunnyPool data={data.si_pools} />
			</MWrap>
		</Sunny>

		<Vaults>
			<BWrap>
				<SVaults data={data.si_vaults} />
			</BWrap>
		</Vaults>

		<Gov>
			<Wrap>
				<Governance data={data.si_governance} />
			</Wrap>
		</Gov>
	</>
);

export default Homepage;
